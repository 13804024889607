import * as React from 'react';

import { PageSection } from '@oysterjs/ui/Page/section';
import { Select } from '@oysterjs/ui/Form/select';
import { getMerchantReferrals, getMerchantTransactions } from '@oysterjs/core/api/merchant';
import { Loadable } from '@oysterjs/ui/Loadable';
import { PolicyTableValues, TransactionType } from '@oysterjs/types';
import { Table } from '@oysterjs/ui/Table';

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const ReferralItem = (props: { referral: PolicyTableValues }) => {
  const item = props.referral.InsuredItems[0];
  return (
    <tr>
      <td>{new Intl.DateTimeFormat('en-US').format(new Date(props.referral.CreatedAt))}</td>
      <td>{item.Name}</td>
      <td>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'usd',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(item.Price.Amount)}
      </td>
    </tr>
  );
};

const AsyncMerchantReferrals = (props: { startDate: Date; endDate: Date }) => (
  <Loadable
    request={Promise.all([
      getMerchantReferrals(props.startDate, props.endDate),
      getMerchantTransactions(props.startDate, props.endDate)
    ])}
  >
    {([referrals, transactions]) => {
      const payouts = transactions.Transactions?.filter((t) => t.Type === TransactionType.credit);
      if (referrals.Referrals.length == 0)
        return (
          <PageSection
            title="Recent Activity"
            description="There were no referrals during this month."
          />
        );
      else
        return (
          <>
            <PageSection title="Recent Activity">
              <Table style={{ marginTop: '0px' }}>
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Item</td>
                    <td>Value</td>
                  </tr>
                </thead>
                <tbody>
                  {referrals.Referrals.map((referral) => (
                    <ReferralItem referral={referral} />
                  ))}
                </tbody>
              </Table>
              <Table style={{ marginTop: '0px' }}>
                <thead>
                  <tr>
                    <td>Metric</td>
                    <td>Value</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Number of Referrals</td>
                    <td>{referrals.Referrals.length}</td>
                  </tr>
                  <tr>
                    <td>Total Referral Fee</td>
                    <td>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'usd',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(referrals.Referrals.length * 25)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </PageSection>
            {payouts && payouts.length > 0 && (
              <PageSection title="Payout Details">
                <Table style={{ marginTop: '0px' }}>
                  <thead>
                    <tr>
                      <td>Date</td>
                      <td>Transaction ID</td>
                      <td>Amount</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {payouts.map((payout) => (
                      <tr>
                        <td>{new Date(payout.CreatedAt).toLocaleDateString()}</td>
                        <td>{payout.ProcessorID || 'TBD'}</td>
                        <td>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: payout.Currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(payout.Amount)}
                        </td>
                        <td>{payout.State}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </PageSection>
            )}
          </>
        );
    }}
  </Loadable>
);
const getMonthsDate = (today: Date) =>
  [0, 1, 2, 3, 4, 5, 6].map((diff) => ({
    start: Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - diff, 1, 0, 0, 0, 0),
    end: Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1 - diff, 1, 0, 0, 0, 0)
  }));

const getMonthName = (date: Date): string => monthList[date.getUTCMonth()];

export const ReferralsPage = () => {
  const monthOptions = getMonthsDate(new Date()).map(({ start, end }, i) => ({
    index: i.toString(),
    title: `${getMonthName(new Date(start))} ${new Date(start).getUTCFullYear()}`,
    start,
    end
  }));

  const [month, setMonth] = React.useState(monthOptions[0]);

  return (
    <>
      <Select
        style={{ maxWidth: '200px' }}
        options={monthOptions.map((opt) => ({ value: opt.index, displayValue: opt.title }))}
        onChange={(value) => {
          setMonth(monthOptions[parseInt(value)]);
        }}
        value={month.index}
      />
      <AsyncMerchantReferrals startDate={new Date(month.start)} endDate={new Date(month.end)} />
    </>
  );
};
