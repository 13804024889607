import React from 'react';
import { ButtonLink } from '@oysterjs/ui/Button';
import { Switch } from '@oysterjs/ui/Form/switch';
import { VerticalLayout } from '@oysterjs/ui/VerticalLayout';
import config from '@oysterjs/core/config';
import {
  MerchantIntegration,
  MerchantIntegrationStatus,
  WooCommerceIntegrationSettings
} from '@oysterjs/types';
import { ChannelSetting, ChannelSettingsWrapper, Step } from './settings';
import { Banner } from '@oysterjs/ui/Banner';
import { IoArrowForward, IoLockClosed, IoOpenOutline } from 'react-icons/io5';
import { ErrorDetail, ErrorDetails } from '@oysterjs/ui/ErrorBoundary';
import { TextInput } from '@oysterjs/ui/Form/text';

export const WooCommerceSetup = (props: {
  apiKey: string;
  integration?: MerchantIntegration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  const [validInstallUrl, setValidInstallUrl] = React.useState('');
  const [storeUrl, setStoreUrl] = React.useState('');

  const updateInstallUrl = (url: string) => {
    setStoreUrl(url);

    try {
      const parsedUrl = new URL(url.trim());

      if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
        throw new Error();
      }

      if (!/^[a-zA-Z][^\s]+\.[^.\s]+$/i.test(parsedUrl.hostname.trim())) {
        throw new Error();
      }

      parsedUrl.hash = '';
      parsedUrl.password = '';
      parsedUrl.pathname = '';
      parsedUrl.search = '';
      parsedUrl.username = '';

      const installUrl = new URL(config().backendBaseUrl.integrate + '/woocommerce/authorize');
      installUrl.search = new URLSearchParams({ store_url: parsedUrl.toString() }).toString();

      setValidInstallUrl(installUrl.toString());
    } catch (e) {
      setValidInstallUrl('');
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    updateInstallUrl(params.get('store_url') || '');
  }, [window.location.search]);

  if (!props.integration || props.integration.Status === MerchantIntegrationStatus.pending) {
    return (
      <>
        <p>
          To start offering insurance for your products in your WooCommerce store, you need to
          authorize Oyster to access your store and install the Oyster WooCommerce plugin.
        </p>
        <div style={{ padding: '20px 0px 0px 0px' }}>
          <VerticalLayout invertedIcon>
            <Step title="Login to your store and initiate the installation">
              Ensure that you are logged into the Lightspeed store you'd like to install the Oyster
              app in. Then, enter your store URL here and click the button to authorize Oyster.
              <div style={{ display: 'flex', gap: '10px', padding: '20px 0px' }}>
                <div style={{ flex: '1 1 0' }}>
                  <TextInput
                    placeholder="https://your-store-url.com"
                    value={storeUrl}
                    onChange={(e) => updateInstallUrl(e.currentTarget.value)}
                    style={{ maxWidth: '100%' }}
                  />
                </div>
                <div style={{ minWidth: '120px' }}>
                  <ButtonLink
                    leftIcon={<IoLockClosed />}
                    icon={<IoArrowForward />}
                    primary
                    disabled={!validInstallUrl}
                    href={validInstallUrl}
                  >
                    Authorize
                  </ButtonLink>
                </div>
              </div>
            </Step>
            <Step title="Grant access to Oyster">
              Oyster will ask for specific permissions to read product and order data. We will use
              this information to determine the insurance eligibility of products and customers and
              send customers who opted in their insurance information.
            </Step>
            <Step title="Finish setting up your integration" last>
              Follow the instructions to install the Oyster WooCommerce plugin so that your
              customers can add insurance when they check out.
            </Step>
          </VerticalLayout>
        </div>
      </>
    );
  }

  return (
    <>
      {props.integration.Status === MerchantIntegrationStatus.inactive && (
        <Banner
          style={{ margin: '0px 0px 16px 0px' }}
          title="This integration doesn't seem live"
          description="Please complete the setup instructions to go live with this integration."
          bannerAction={
            <ButtonLink
              target="_blank"
              href="https://withoyster.notion.site/WooCommerce-Integration-3ac4baec5755435bb11ecda1be20c8ce"
              leftIcon={<IoOpenOutline />}
              primary
            >
              Continue Setup
            </ButtonLink>
          }
        />
      )}
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <ErrorDetails errorData={props.apiKey} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="API Key" description={props.apiKey} />
          </ErrorDetails>
          <ErrorDetails errorData={props.integration.ID} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="Integration ID" description={props.integration.ID} />
          </ErrorDetails>
        </div>
        <ChannelSettingsWrapper
          integration={props.integration}
          onUpdateIntegration={props.onUpdateIntegration}
        >
          {(settings: WooCommerceIntegrationSettings, loading, onUpdateSettings) => (
            <>
              <ChannelSetting
                title="Enable Product Page Widget"
                description="Configures whether the Oyster widget is visible on the product description page. This requires you to have added the Oyster plugin to your WooCommerce store."
                rightContent={
                  <Switch
                    enabled={settings.ProductPageWidgetEnabled}
                    loading={loading}
                    onChange={() =>
                      onUpdateSettings({
                        ...settings,
                        ProductPageWidgetEnabled: !settings.ProductPageWidgetEnabled
                      })
                    }
                  />
                }
              />
            </>
          )}
        </ChannelSettingsWrapper>
      </div>
    </>
  );
};
