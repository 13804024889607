import * as React from 'react';
import { WrappedError } from '@oysterjs/core/errors';
import { MerchantIntegration, MerchantIntegrationSettings } from '@oysterjs/types';
import { IoCheckmarkSharp, IoAlertCircleSharp } from 'react-icons/io5';
import { VerticalLayoutItem } from '@oysterjs/ui/VerticalLayout';

const BetaLabel = () => (
  <div
    style={{
      display: 'inline',
      background: '#0EA5E9bb',
      padding: '4px 8px',
      fontSize: '0.65em',
      color: 'white',
      margin: '0px 12px',
      borderRadius: '8px'
    }}
  >
    Beta
  </div>
);

export const AdditionalSetup = (props: { setupUrl: string }) => (
  <div
    style={{
      borderLeft: '4px solid #ffbca9',
      background: '#fff3f0',
      color: '#605c59',
      padding: '10px',
      width: '100%',
      fontSize: '0.8em'
    }}
  >
    This option requires additional setup.{' '}
    <a href={props.setupUrl} target="_blank">
      Click here
    </a>{' '}
    for instructions.
  </div>
);

export const ChannelSetting: React.FunctionComponent<{
  title: string;
  description: string | JSX.Element;
  indented?: boolean;
  beta?: boolean;
  rightContent?: JSX.Element;
  footer?: JSX.Element;
}> = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      boxSizing: 'border-box',
      padding: props.indented ? '0px 0px 0px 24px' : undefined
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
      <div style={{ maxWidth: '80%' }}>
        <h3
          style={{
            fontWeight: 500,
            fontSize: '1em',
            margin: '0',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {props.title}
          {props.beta && <BetaLabel />}
        </h3>
        <p style={{ fontSize: '0.9em', color: '#666666', margin: '5px 0px 0px 0px' }}>
          {props.description}
        </p>
        {props.footer && <div style={{ margin: '5px 0px 0px 0px' }}>{props.footer}</div>}
      </div>
      {props.rightContent && <div>{props.rightContent}</div>}
    </div>
    <div style={{ maxWidth: '80%' }}>{props.children}</div>
  </div>
);

export const ChannelSettingsWrapper = <T extends MerchantIntegrationSettings>(props: {
  integration: MerchantIntegration;
  children: (
    settings: T,
    loading: boolean,
    onUpdateSettings: (settings: T) => void
  ) => React.ReactNode;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const onUpdate = async (settings: MerchantIntegrationSettings) => {
    const integration = { ...props.integration, Settings: settings };

    setLoading(true);
    setUpdated(false);
    setError(undefined);

    try {
      await props.onUpdateIntegration(integration);
      setUpdated(true);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2>Settings</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {props.children(props.integration.Settings as T, loading, onUpdate)}
      </div>
      {(updated || error) && (
        <div style={{ padding: '10px 0px 0px 0px', fontSize: '0.8em' }}>
          {updated && (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                color: '#999999'
              }}
            >
              <IoCheckmarkSharp />
              <div>Saved!</div>
            </div>
          )}
          {error && (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                color: '#d1344b'
              }}
            >
              <IoAlertCircleSharp />
              <div>{error}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const Step = (props: React.PropsWithChildren<{ title: string; last?: boolean }>) => (
  <VerticalLayoutItem icon={<></>} last={props.last}>
    <h3 style={{ margin: 0, height: '36px', lineHeight: '36px', fontWeight: 500 }}>
      {props.title}
    </h3>
    <p style={{ margin: 0, color: '#666666', fontSize: '0.9em' }}>{props.children}</p>
  </VerticalLayoutItem>
);
