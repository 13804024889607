import * as React from 'react';
import { CSSProperties } from 'styled-components';

import {
  Merchant,
  MerchantAppType,
  MerchantRentalConfiguration,
  MerchantUser,
  RentalPolicyState
} from '@oysterjs/types';
import { IoChevronForwardOutline, IoCalendarOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router';
import { CardGallery, SelectableOptionCard } from '@oysterjs/ui/Card';
import { Badge } from '@oysterjs/ui/Badge';
import { PageSection, PageTitle } from '@oysterjs/ui/Page/section';
import { getMerchantAccount, getMerchantRentalConfiguration } from '@oysterjs/core/api/merchant';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { MerchantRentalSetup } from './rental';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { RentalPageContainer } from '../rental/components';

export enum AppStatus {
  unavailable,
  uninstalled,
  pending,
  installed,
  disabled
}

interface App {
  type: MerchantAppType;
  appStatus(): AppStatus;
  label: string;
  description: string;
  image: JSX.Element;
}

const RentalInsuranceApp = (configuration?: MerchantRentalConfiguration): App => {
  return {
    type: MerchantAppType.rental,
    appStatus() {
      if (!configuration) {
        return AppStatus.uninstalled;
      }

      switch (configuration.Details.State) {
        case RentalPolicyState.submitted:
        case RentalPolicyState.registered:
          return AppStatus.pending;
        case RentalPolicyState.approved:
        case RentalPolicyState.approved_blanket:
          return AppStatus.installed;
        default:
          return AppStatus.uninstalled;
      }
    },

    label: 'Rental Insurance',
    description: 'Provide theft and damage waivers to customers for rentals.',
    image: <IoCalendarOutline style={{ color: '#000000' }} />
  };
};

const getAppPath = (app: App) => {
  switch (app.type) {
    case MerchantAppType.rental:
      if (app.appStatus() === AppStatus.installed) {
        return '/rental';
      } else {
        return '/apps/rental';
      }
    default:
      return '/apps';
  }
};

const getAppBadge = (app?: App): JSX.Element => {
  switch (app?.appStatus()) {
    case AppStatus.installed:
      return <Badge color={'#baf7bb'} label={'Installed'} />;
    case AppStatus.uninstalled:
      return <Badge color={'#e6e6e6'} label={'Not installed'} />;
    case AppStatus.pending:
      return <Badge color={'#ffa72494'} label={'Processing'} />;
    default:
      return <Badge color={'#e91e63a6'} label={'Unavailable'} />;
  }
};

const AppCard = (props: { app: App; style?: CSSProperties; onClick?: () => void }) => (
  <SelectableOptionCard
    title={props.app.label}
    badge={getAppBadge(props.app)}
    titleAction={<IoChevronForwardOutline />}
    description={props.app.description}
    image={props.app.image}
    onClick={props.onClick}
    style={{ maxWidth: '360px' }}
  />
);

const AppsListPage = () => {
  const history = useHistory();
  const location = useLocation();

  const [merchant, setMerchant] = React.useState<Merchant>();
  const [merchantUser, setMerchantUser] = React.useState<MerchantUser>();
  const [rentalConfiguration, setRentalConfiguration] =
    React.useState<MerchantRentalConfiguration>();
  const [appsList, setAppsList] = React.useState<App[]>([]);

  const onClick = (app: App) => {
    history.push(getAppPath(app));
  };

  // Configuration fetching logic
  const onFetchConfigurationData = async () => {
    const merchant = await getMerchantAccount();
    setMerchant(merchant.Merchant);
    setMerchantUser(merchant.MerchantUser);

    // Rental app processing
    const config = await getMerchantRentalConfiguration();
    setRentalConfiguration(config.Configuration);

    const index = appsList.findIndex((app) => app.type === MerchantAppType.rental);
    let updatedAppsList = appsList;
    if (index !== -1) {
      updatedAppsList[index] = RentalInsuranceApp(config.Configuration);
    } else {
      updatedAppsList = [...updatedAppsList, RentalInsuranceApp(config.Configuration)];
    }

    setAppsList(updatedAppsList);
  };

  React.useEffect(() => {
    onFetchConfigurationData();
  }, []);

  return (
    <>
      {/* Section for apps that are installed */}
      <CardGallery style={{ padding: '0', margin: '0' }}>
        {appsList
          .filter((app) => app.appStatus() === AppStatus.installed)
          .map((app) => (
            <AppCard key={app.type.toString()} onClick={() => onClick(app)} app={app} />
          ))}
      </CardGallery>

      {/* Section to install any available apps */}
      {!!appsList.find(
        (app) => app.appStatus() === AppStatus.pending || app.appStatus() === AppStatus.uninstalled
      ) && (
        <PageSection title="Install new apps">
          <CardGallery style={{ padding: '0', margin: '0' }}>
            {appsList
              .filter(
                (app) =>
                  app.appStatus() === AppStatus.uninstalled ||
                  app.appStatus() === AppStatus.pending ||
                  app.appStatus() === AppStatus.disabled
              )
              .map((app) => (
                <AppCard key={app.type.toString()} onClick={() => onClick(app)} app={app} />
              ))}
          </CardGallery>
        </PageSection>
      )}

      <SlideOut
        showing={location.pathname.endsWith('/rental')}
        onClose={() => history.replace('/apps')}
        onBack={() => history.replace('/apps')}
      >
        <RentalPageContainer
          title="Rental Insurance"
          description="Provide theft and damage waivers to customers for rentals"
          badge={getAppBadge(appsList.find((app) => app.type === MerchantAppType.rental))}
        >
          <ErrorBoundary forceMobile>
            <MerchantRentalSetup
              merchant={merchant}
              merchantUser={merchantUser}
              configuration={rentalConfiguration}
              onConfigurationUpdated={onFetchConfigurationData}
              onContinueToRental={() => history.replace('/rental')}
            ></MerchantRentalSetup>
          </ErrorBoundary>
        </RentalPageContainer>
      </SlideOut>
    </>
  );
};

export const MerchantAppsPage = () => {
  return (
    <>
      <PageTitle
        title="Your Apps"
        description="Set up and use additional functionality offered through the partners dashboard"
      >
        <AppsListPage />
      </PageTitle>
    </>
  );
};
