import * as React from 'react';
import styled from 'styled-components';
import {
  CertificateCoverage,
  CertificateDetails,
  InsuranceType
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { PageSection } from '@oysterjs/ui/Page';
import {
  IoArrowForward,
  IoCheckmarkOutline,
  IoCheckmarkSharp,
  IoCopyOutline,
  IoOpenOutline
} from 'react-icons/io5';
import { getDisplayInsuranceType } from '@oysterjs/types/merchant/graphql/map';
import { ButtonContainer, ButtonLink } from '@oysterjs/ui/Button';

export const CertificateSectionContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: row;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const CertificateRightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px 0px 0px 30px;
  flex: 1 0 224px;

  @media (max-width: 650px) {
    border-top: 1px solid #cccccc;
    padding: 30px 0px 0px 0px;
  }
`;

export const CertificateLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
`;

export const CertificateRightSectionTitle = styled.div`
  text-transform: uppercase;
  color: #999999;
  font-weight: 600;
  font-size: 0.8em;
`;

const unescapeSafe = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const CertificateEntity = (props: { entity: string; name: string; address: string }) => (
  <div>
    <CertificateRightSectionTitle>{props.entity}</CertificateRightSectionTitle>
    <p style={{ margin: '4px 0px' }}>{unescapeSafe(props.name)}</p>
    <p style={{ margin: '4px 0px' }}>
      {props.address
        .trim()
        .split('\n')
        .map((l) => (
          <React.Fragment key={l}>
            {unescapeSafe(l)}
            <br />
          </React.Fragment>
        ))}
    </p>
  </div>
);

const CertificatePolicy = (props: { coverage: CertificateCoverage }) => (
  <div>
    <h1 style={{ margin: '0px', padding: '0', fontSize: '1.3em', fontWeight: 500 }}>
      {getDisplayInsuranceType(props.coverage.type)}
    </h1>
    <h2 style={{ margin: '4px 0px', fontSize: '0.9em', fontWeight: 400 }}>
      {unescapeSafe(props.coverage.insurer.name)}
    </h2>
    <h2 style={{ marginTop: '4px', fontSize: '0.9em', fontWeight: 400 }}>
      {props.coverage.policyNumber}
    </h2>
    <ul
      style={{
        padding: '0px 0px',
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
      }}
    >
      <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
        <IoCheckmarkSharp style={{ color: '#0EA5E9', flex: '1 0 20px' }} />
        <div style={{ flex: '1 0 100%' }}>
          Policy is effective from{' '}
          {new Date(props.coverage.effectiveDate.replace('Z', '')).toLocaleDateString()} to{' '}
          {new Date(props.coverage.expirationDate.replace('Z', '')).toLocaleDateString()}
        </div>
      </li>
      {props.coverage.hasWaiverOfSubrogation && (
        <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
          <IoCheckmarkSharp style={{ color: '#0EA5E9', flex: '1 0 20px' }} />
          <div style={{ flex: '1 0 100%' }}>Policy has waiver of subrogation</div>
        </li>
      )}
      {props.coverage.holderIsAdditionalInsured && (
        <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
          <IoCheckmarkSharp style={{ color: '#0EA5E9', flex: '1 0 20px' }} />
          <div style={{ flex: '1 0 100%' }}>
            Policy lists certificate holder as an additional insured
          </div>
        </li>
      )}
      {props.coverage.type !== InsuranceType.WorkersCompensation &&
        props.coverage.limits.map((limit) => (
          <li key={limit.name} style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
            <IoCheckmarkSharp style={{ color: '#0EA5E9', flex: '1 0 20px' }} />
            <div style={{ flex: '1 0 100%' }}>
              {limit.name} Limit:{' '}
              {new Intl.NumberFormat(undefined, {
                currency: 'USD',
                style: 'currency',
                maximumFractionDigits: 0
              }).format(limit.limit)}
            </div>
          </li>
        ))}
    </ul>
  </div>
);

const CertificateSection = (props: { certificate: CertificateDetails }) => (
  <CertificateSectionContainer>
    <CertificateLeftSection>
      {props.certificate.coverages.map((coverage) => (
        <CertificatePolicy key={coverage.type} coverage={coverage} />
      ))}
    </CertificateLeftSection>
    <CertificateRightSection>
      <CertificateEntity
        entity="Insured"
        name={props.certificate.insured.name}
        address={props.certificate.insured.address}
      />
      <CertificateEntity
        entity="Certificate Holder"
        name={props.certificate.certificateHolder.name}
        address={props.certificate.certificateHolder.address}
      />
      {props.certificate.descriptionOfOperations && (
        <CertificateEntity
          entity="Description of Operations"
          name={props.certificate.descriptionOfOperations}
          address=""
        />
      )}
    </CertificateRightSection>
  </CertificateSectionContainer>
);

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const Action = styled.button`
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  padding: 8px 16px;
  border-radius: 30px;
  user-select: none;

  font-size: 0.7em;
  font-weight: 500;

  justify-content: center;

  color: #333333;
  background: #eaeaea;
  font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  svg {
    font-size: 1.2em;
  }

  &:hover {
    background: #e6e6e6;
  }

  &:active {
    background: #cccccc;
  }
`;

const MarketingBannerContainer = styled.div`
  background: #e9f6fe;
  margin: 0px 0px 40px 0px;
  padding: 40px;
  border-radius: 20px;
`;

const MarketingBanner = () => (
  <MarketingBannerContainer>
    <h1 style={{ fontSize: '1.4em', fontWeight: 500 }}>
      Get a competitive quote for your business insurance.
    </h1>
    <p>
      Oyster is the only all-in-one platform for all your insurance needs, from insurance for your
      business to insurance for your customers. Our team of experts can help you save up to 30% on
      insurance costs.
    </p>
    <ButtonContainer>
      <ButtonLink
        primary
        icon={<IoArrowForward />}
        href="https://www.withoyster.com/business-insurance"
      >
        Get a quote
      </ButtonLink>
    </ButtonContainer>
  </MarketingBannerContainer>
);

export const Certificate = (props: { url: string; details: CertificateDetails }) => {
  const [copyClicked, setCopyClicked] = React.useState(false);

  React.useEffect(() => {
    if (!copyClicked) {
      return;
    }

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(window.location.href).catch(() => 0);
    }

    const timeout = setTimeout(() => {
      setCopyClicked(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [copyClicked]);

  return (
    <>
      <PageSection noBorder>
        <h1 style={{ marginBottom: '8px' }}>Certificate of Insurance</h1>
        <h2 style={{ marginTop: '8px', fontSize: '1.1em', fontWeight: 500 }}>
          {props.details.certificateNumber}
        </h2>
        <p>
          This document was produced by{' '}
          <b style={{ fontWeight: 500 }}>{props.details.producer.name}</b> to certify that the
          insured has been issued the listed policies, each with its respective limits and
          conditions.
        </p>
        <ActionsContainer>
          <Action onClick={() => setCopyClicked(true)}>
            {!copyClicked && (
              <>
                Copy Link <IoCopyOutline />
              </>
            )}
            {copyClicked && (
              <>
                Link Copied! <IoCheckmarkOutline />
              </>
            )}
          </Action>
          <a href={props.url || ''} target="_BLANK" style={{ textDecoration: 'none' }}>
            <Action>
              View Certificate <IoOpenOutline />
            </Action>
          </a>
        </ActionsContainer>
      </PageSection>
      <PageSection noBorder>
        <CertificateSection certificate={props.details} />
      </PageSection>
      <PageSection>
        <MarketingBanner />
      </PageSection>
    </>
  );
};
