import * as React from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';

const ColorSelection = styled.div`
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }
`;

const ColorContainer = styled.div`
  border-radius: 6px;
  position: relative;
  height: 16px;
  width: 16px;
  padding: 6px 5px;
`;

const HexContainer = styled.div`
  padding: 0px 5px 0px 7px;
  position: relative;

  .hex {
    background-color: transparent;
    width: 100%;
    font-family: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
      'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
      'Courier New', monospace;
    height: 16px;
  }
`;

export const ColorPicker = (props: {
  color: string;
  onUpdateSettings: (color: string) => void;
}) => {
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [primaryColor, setPrimaryColor] = React.useState(props.color);

  return (
    <>
      <ColorSelection onClick={() => setShowColorPicker(true)}>
        <ColorContainer style={{ backgroundColor: primaryColor }} />
        <HexContainer>
          <div className="hex">{primaryColor}</div>
        </HexContainer>
      </ColorSelection>
      {showColorPicker && (
        <div style={{ zIndex: 2, position: 'absolute', right: '40px', top: '260px' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }}
            onClick={() => {
              props.onUpdateSettings(primaryColor);
              setShowColorPicker(false);
            }}
          />
          <SketchPicker
            color={primaryColor}
            onChangeComplete={(color: { hex: string }) => setPrimaryColor(color.hex)}
          />
        </div>
      )}
    </>
  );
};
