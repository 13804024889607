import * as React from 'react';

import { PageContainer, PageSection, TwoPaneContainer } from '@oysterjs/ui/Page';
import { OysterLogo } from '@oysterjs/ui/Logo';
import { CoverageItem, CoveragePageSection } from '@oysterjs/ui/CoverageItem';
import { FormColumn, FormContainer, FormRow } from '@oysterjs/ui/Form/builder';
import { LeftPaneContainer, PageBackground, LogoContainer } from '@oysterjs/ui/SplitPane';
import { TextInput } from '@oysterjs/ui/Form/text';
import { BusinessInsuranceType, ValidationError } from '@oysterjs/types';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import { useHistory } from 'react-router';
import {
  CreateBusinessApplication,
  ProductType,
  useCreateBusinessApplicationMutation
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { toLegacyValidationError } from '@oysterjs/types/merchant/graphql/map';
import { Select } from '@oysterjs/ui/Form/select';

const CommercialInsuranceAboutYou = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [inputChanged, setInputChanged] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [formData, setFormData] = React.useState<CreateBusinessApplication>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: ''
  });

  const [createBusinessApplicationMutation] = useCreateBusinessApplicationMutation();

  React.useEffect(() => {
    if (!loading) {
      setInputChanged(false);
    }
  }, [loading]);

  React.useEffect(() => {
    setInputChanged(true);
  }, [formData]);

  const onCreateApplication = async () => {
    setLoading(true);
    try {
      const results = await createBusinessApplicationMutation({
        variables: {
          input: formData
        }
      });

      if (!results.data || !results.data.createBusinessApplication) {
        throw new Error('Application creation failed');
      }

      const { validationError } = results.data.createBusinessApplication;
      if (validationError) {
        setValidationError(toLegacyValidationError(validationError));
      }

      const isBikeApplication = formData.productVerticals?.includes(
        ProductType.Bike || ProductType.OutdoorSports
      );
      const noValidationError =
        !validationError?.field ||
        !['FirstName', 'LastName', 'Email', 'PhoneNumber', 'ProductVerticals'].includes(
          validationError.field
        );

      if (noValidationError && isBikeApplication) {
        // Go to fillout
        const filloutParams = new URLSearchParams({
          first_name: formData.firstName || '',
          last_name: formData.lastName || '',
          email: formData.emailAddress || '',
          phone: formData.phoneNumber || '',
          business_type: 'BIKES'
        });
        window.location.href = `https://www.withoyster.com/business-insurance-app?${filloutParams.toString()}`;
      } else if (noValidationError) {
        // Go to rest of commercial application
        history.push(
          '/app/commercial/:id/business'.replace(':id', results.data!.createBusinessApplication!.id)
        );
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <PageSection noBorder>
        <h1>Get started with a quote.</h1>
        <p>
          Find affordable coverage options online with Oyster. Get started with a few pieces of
          information.
        </p>
        <FormContainer style={{ paddingTop: '20px' }}>
          <FormRow>
            <FormColumn title="First Name">
              <TextInput
                style={{ maxWidth: '300px' }}
                type="text"
                value={formData.firstName || ''}
                autoComplete="given-name"
                error={
                  !inputChanged &&
                  validationError?.Field === 'FirstName' &&
                  validationError?.Message
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setFormData((prev) => ({ ...prev, firstName: value }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn title="Last Name">
              <TextInput
                style={{ maxWidth: '300px' }}
                type="text"
                value={formData.lastName || ''}
                autoComplete="family-name"
                error={
                  !inputChanged && validationError?.Field === 'LastName' && validationError?.Message
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setFormData((prev) => ({ ...prev, lastName: value }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn title="Email Address">
              <TextInput
                style={{ maxWidth: '300px' }}
                type="email"
                value={formData.emailAddress || ''}
                autoComplete="email"
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setFormData((prev) => ({ ...prev, emailAddress: value }));
                }}
                error={
                  !inputChanged && validationError?.Field === 'Email' && validationError?.Message
                }
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn title="Phone Number">
              <TextInput
                style={{ maxWidth: '300px' }}
                inputMode="tel"
                autoComplete="tel"
                error={
                  !inputChanged &&
                  validationError?.Field === 'PhoneNumber' &&
                  validationError?.Message
                }
                value={formData.phoneNumber || ''}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setFormData((prev) => ({ ...prev, phoneNumber: value }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn title="Business type">
              <Select
                style={{ maxWidth: '300px' }}
                options={[
                  { value: '' },
                  { displayValue: 'Bikes & eBikes', value: ProductType.Bike },
                  { displayValue: 'Jewelry', value: ProductType.Jewelry },
                  { displayValue: 'Electronics', value: ProductType.Electronics },
                  {
                    displayValue: 'Collectibles',
                    value: ProductType.Collectibles
                  },
                  {
                    displayValue: 'Home and Furniture',
                    value: ProductType.Furniture
                  },
                  { displayValue: 'Fashion', value: ProductType.Fashion },
                  { displayValue: 'Motorcycle', value: ProductType.Motorcycle },
                  {
                    displayValue: 'Off-road Vehicles',
                    value: ProductType.OffroadVehicle
                  },
                  {
                    displayValue: 'Food and Beverage',
                    value: ProductType.FoodAndBeverage
                  },
                  {
                    displayValue: 'Beauty and Supplements',
                    value: ProductType.BeautyAndSupplements
                  },
                  {
                    displayValue: 'Outdoor Sports',
                    value: ProductType.OutdoorSports
                  },
                  { displayValue: 'Other', value: ProductType.Other }
                ]}
                value={formData.productVerticals?.length ? formData.productVerticals[0] : ''}
                onChange={(value) => {
                  setFormData((prev) => ({ ...prev, productVerticals: [value] }));
                }}
                error={
                  !inputChanged &&
                  validationError?.Field === 'ProductVerticals' &&
                  validationError?.Message
                }
              />
            </FormColumn>
          </FormRow>
        </FormContainer>
      </PageSection>
      <PageSection noBorder>
        <ButtonContainer>
          <Button primary loading={loading} icon={<IoArrowForward />} onClick={onCreateApplication}>
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </>
  );
};

export const CommercialInsuranceDetails = (): JSX.Element => {
  return (
    <PageSection noBorder>
      <h1>Protect your business today.</h1>
      <p>
        {' '}
        Oyster provides the best coverages for your business tailored to your industry sector.{' '}
        <a href="https://www.withoyster.com/merchant-library" target="_blank">
          Learn More.
        </a>
      </p>
      <CoveragePageSection title="Coverage Options">
        {[
          {
            title: BusinessInsuranceType.BusinessOwners,
            description: 'Combines essential coverages that include both liability and property.'
          },
          {
            title: BusinessInsuranceType.GeneralLiability,
            description:
              'Covers your business from legal claims arising from normal business operations.'
          },
          {
            title: BusinessInsuranceType.Property,
            description: "Covers your business' buildings and their contents."
          },
          {
            title: BusinessInsuranceType.WorkersCompensation,
            description: 'Covers your workers by providing benefits in case of illness or injury.'
          },
          {
            title: 'More',
            description: 'Consult our insurance experts for specialized coverage.'
          }
        ].map((opt) => (
          <CoverageItem {...opt} />
        ))}
      </CoveragePageSection>
    </PageSection>
  );
};

export const CommercialInsuranceInformationPane = (): JSX.Element => {
  return (
    <LeftPaneContainer>
      <CommercialInsuranceDetails />
    </LeftPaneContainer>
  );
};

export const CommercialInsuranceCollectionPane = (): JSX.Element => {
  return (
    <PageBackground>
      <PageContainer footerTextColor="#999999">
        <LogoContainer>
          <PageSection noBorder>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <a href="https://withoyster.com">
                <OysterLogo light />
              </a>
              <text style={{ color: '#999999' }}>Beta</text>
            </div>
          </PageSection>
        </LogoContainer>
        <TwoPaneContainer leftPaneWidth={50} rightPane={<CommercialInsuranceAboutYou />}>
          <CommercialInsuranceInformationPane />
        </TwoPaneContainer>
      </PageContainer>
    </PageBackground>
  );
};

export const CommercialInsurance = (): JSX.Element => {
  return <CommercialInsuranceCollectionPane />;
};
