import * as React from 'react';
import styled from 'styled-components';
import { useGetCertificateLazyQuery } from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { Certificate } from './certificate';
import { CertificateSkeleton } from './skeleton';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { ThrowError } from '../../authenticated';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';

const TransitionContainer = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: 0.15s opacity ease;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: 0.15s opacity ease;
  }
`;

export const CertificateApp = (props: { certificateNumber: string }) => {
  const [getCertificate, { data, error }] = useGetCertificateLazyQuery({
    variables: { certificateNumber: props.certificateNumber }
  });

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // For visual purposes load longer
    setTimeout(() => getCertificate().finally(() => setLoading(false)), 1500);
  }, []);

  return (
    <PageContainer width={800}>
      <PageSection noBorder style={{ paddingTop: '60px' }}>
        <OysterLogo />
      </PageSection>

      <SwitchTransition mode="out-in">
        <CSSTransition key={loading.toString()} timeout={100} classNames="fade">
          <TransitionContainer>
            {loading && <CertificateSkeleton />}
            {!loading && error && (
              <ErrorBoundary>
                <ThrowError error={error} />
              </ErrorBoundary>
            )}
            {!loading && !error && data?.certificate?.details && data?.certificate?.url && (
              <Certificate url={data.certificate.url} details={data.certificate.details} />
            )}
            {!loading && !error && !data?.certificate?.details && (
              <ErrorBoundary>
                <ThrowError error={new Error('Certificate not found')} />
              </ErrorBoundary>
            )}
          </TransitionContainer>
        </CSSTransition>
      </SwitchTransition>
    </PageContainer>
  );
};
