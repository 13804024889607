import { init as initApm } from '@oysterjs/core/apm';
initApm();

import { init as initAppConfiguration } from '@oysterjs/core/api/appConfiguration';
initAppConfiguration();

import './style.scss';

import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route as BrowserRoute } from 'react-router-dom';

import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import config from '@oysterjs/core/config';

import MerchantDashboard from './pages';
import MerchantPersonalization from './pages/personalization';

import {
  OnboardCheckfront,
  OnboardLightspeedRetailRSeries,
  OnboardShopify,
  OnboardWooCommerce
} from './onboard';
import { useGA } from '@oysterjs/core/ga';
import { SignIn, SignUp } from './pages/signin';
import { useGoogleTags } from '@oysterjs/core/analytics/googletags';
import { HubspotChatContextProvider } from '@oysterjs/core/hubspot';
import { Authenticated } from './authenticated';
import { CommercialInsurance } from './pages/commercial/landing';
import CommercialInsuranceApp from './pages/commercial';
import { CertificateApp } from './pages/certificate';
import { ApolloProvider } from '@apollo/client';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';

const App = () => {
  if (config().environment === 'production') {
    useGA('G-MX1C87Q096');
    useGoogleTags();
  }

  return (
    <HubspotChatContextProvider>
      <Switch>
        <Route path="/link/shopify">
          <OnboardShopify />
        </Route>
        <Route path="/link/lsretail-rseries">
          <OnboardLightspeedRetailRSeries />
        </Route>
        <Route path="/link/woocommerce">
          <OnboardWooCommerce />
        </Route>
        <Route path="/link/checkfront">
          <OnboardCheckfront />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/certificate/:certificateNumber">
          {(props) => (
            <ApolloProvider client={getMerchantGraphQLClient()}>
              <CertificateApp certificateNumber={props.match?.params.certificateNumber || ''} />
            </ApolloProvider>
          )}
        </Route>
        <Route path="/commercial-insurance">
          <ApolloProvider client={getMerchantGraphQLClient()}>
            <CommercialInsurance />
          </ApolloProvider>
        </Route>
        <Route path="/personalization">
          <MerchantPersonalization />
        </Route>
        <Route path="/app/commercial">
          <ApolloProvider client={getMerchantGraphQLClient()}>
            <CommercialInsuranceApp />
          </ApolloProvider>
        </Route>
        <Route path="/">
          <Authenticated>{() => <MerchantDashboard />}</Authenticated>
        </Route>
      </Switch>
    </HubspotChatContextProvider>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
