import { PageTitle } from '@oysterjs/ui/Page/section';
import { ReferralsPage } from '../insurance/product/referrals';

export const MerchantReportsPage = () => {
  return (
    <>
      <PageTitle
        title="Reports"
        description="View reports of your store's performance or referral statistics"
      >
        <ReferralsPage />
      </PageTitle>
    </>
  );
};
