import React from 'react';
import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 500;
  margin-bottom: 0;
`;

export const Description = styled.p`
  font-size: 0.9em;
  margin-top: 0.5em;
  color: #666666;
`;

export const PageTitle = (
  props: React.PropsWithChildren<{
    title: string;
    description?: string | JSX.Element;
    badge?: JSX.Element;
  }>
) => (
  <div>
    <Title>{props.title}</Title>
    {props.badge && <div style={{ paddingTop: '6px' }}>{props.badge}</div>}
    {props.description && <Description>{props.description}</Description>}
    <div style={{ padding: '12px 0px 0px 0px', boxSizing: 'border-box' }}>{props.children}</div>
  </div>
);

export const Heading = styled.h2`
  font-weight: 500;
  margin-bottom: 0;
  color: #333333;
`;

export const SubHeading = styled.h3`
  margin-bottom: 0;
`;

export const PageSection = (
  props: React.PropsWithChildren<{ title: string; description?: string }>
) => (
  <div style={{ padding: '24px 0px 0px 0px' }}>
    <Heading>{props.title}</Heading>
    {props.description && <Description>{props.description}</Description>}
    <div style={{ padding: '12px 0px 0px 0px', boxSizing: 'border-box' }}>{props.children}</div>
  </div>
);
