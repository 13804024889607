import * as React from 'react';

import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import {
  BusinessApplication,
  UpdateBusinessApplication,
  ValidationError,
  useAllBusinessApplicationQuery,
  useUpdateBusinessApplicationMutation
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { LoadableContainer } from '@oysterjs/ui/Loadable';
import { RouteComponentProps, matchPath, useHistory } from 'react-router';
import { Spinner } from '@oysterjs/ui/Spinner';

export interface Page {
  path: string;
  render: (props: RouteComponentProps<{ [key: string]: string | undefined }>) => JSX.Element;
  hasError: (validationError: ValidationError) => boolean;
  icon: JSX.Element;
}

export interface PageProps {
  application: BusinessApplication;
  onNext: () => Promise<boolean>;
  onBack: ((e) => unknown) | null;
  onUpdate: (policy: BusinessApplication, forceUpdate?: boolean) => Promise<BusinessApplication>;
  validationError?: ValidationError;
  loading: boolean;
}

// TODO: Check the state of the application
export const GetApplicationWrapper = (props: {
  applicationId?: string;
  children: (data: BusinessApplication) => JSX.Element | null;
}): JSX.Element => {
  // const history = useHistory();
  const gqlApplication = useAllBusinessApplicationQuery({
    variables: {
      input: {
        id: props.applicationId || ''
      }
    }
  });

  if (!gqlApplication.data?.businessApplication) {
    return (
      <LoadableContainer inline={false}>
        <Spinner color="#333333" />
      </LoadableContainer>
    );
  }

  return <>{props.children(gqlApplication.data!.businessApplication as BusinessApplication)}</>;
};

export const PageWrapper: React.FunctionComponent<{
  application: BusinessApplication;
  pages: Page[];
  component: React.FunctionComponent<React.PropsWithChildren<PageProps>>;
}> = (props) => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [application, setApplication] = React.useState(props.application);
  const [validationError, setValidationError] = React.useState<ValidationError>();

  const [updateBusinessApplicationMutation, updateApplicationStates] =
    useUpdateBusinessApplicationMutation();
  React.useEffect(() => {
    if (updateApplicationStates.data && updateApplicationStates.data.updateBusinessApplication) {
      setApplication({
        ...application,
        ...(updateApplicationStates.data.updateBusinessApplication as BusinessApplication)
      });
    }
  }, [updateApplicationStates.data]);
  React.useEffect(
    () => setLoading(updateApplicationStates.loading),
    [updateApplicationStates.loading]
  );
  React.useEffect(() => {
    // TODO: render error
  }, [updateApplicationStates.error]);

  const currentPageIndex = props.pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  const onUpdate = async (updatedApplication: BusinessApplication, forceUpdate?: boolean) => {
    if (forceUpdate) {
      const mutationResults = await updateBusinessApplicationMutation({
        variables: {
          input: {
            desiredCoverageStartDate: updatedApplication.desiredCoverageStartDate,
            generalApplication: updatedApplication.generalApplication,
            id: updatedApplication.id,
            insuranceTypes: updatedApplication.insuranceTypes,
            liabilityCoverage: updatedApplication.liabilityCoverage,
            locations: updatedApplication.locations,
            manufacturingUnderwriting: updatedApplication.manufacturingUnderwriting,
            operations: updatedApplication.operations,
            optedIntoOysterBundle: updatedApplication.optedIntoOysterBundle,
            otherUnderwriting: updatedApplication.otherUnderwriting,
            profile: updatedApplication.profile,
            propertyCoverage: updatedApplication.propertyCoverage,
            rentalUnderwriting: updatedApplication.rentalUnderwriting,
            retailUnderwriting: updatedApplication.retailUnderwriting,
            serviceUnderwriting: updatedApplication.serviceUnderwriting,
            wholesaleUnderwriting: updatedApplication.wholesaleUnderwriting,
            workersCompCoverage: updatedApplication.workersCompCoverage
          } as UpdateBusinessApplication
        }
      });

      if (mutationResults.data && mutationResults.data.updateBusinessApplication) {
        setApplication({
          ...application,
          ...(mutationResults.data.updateBusinessApplication as BusinessApplication)
        });
      }
    }
    setApplication(updatedApplication);
    return application;
  };

  const onBack = (e) => {
    e.preventDefault();
    if (currentPageIndex > 0) {
      history.push(props.pages[currentPageIndex - 1].path.replace(':id', application.id));
    }
  };

  const onNext = async () => {
    const mutationResults = await updateBusinessApplicationMutation({
      variables: {
        input: {
          desiredCoverageStartDate: application.desiredCoverageStartDate,
          generalApplication: application.generalApplication,
          id: application.id,
          insuranceTypes: application.insuranceTypes,
          liabilityCoverage: application.liabilityCoverage,
          locations: application.locations,
          manufacturingUnderwriting: application.manufacturingUnderwriting,
          operations: application.operations,
          optedIntoOysterBundle: application.optedIntoOysterBundle,
          otherUnderwriting: application.otherUnderwriting,
          profile: application.profile,
          propertyCoverage: application.propertyCoverage,
          rentalUnderwriting: application.rentalUnderwriting,
          retailUnderwriting: application.retailUnderwriting,
          serviceUnderwriting: application.serviceUnderwriting,
          wholesaleUnderwriting: application.wholesaleUnderwriting,
          workersCompCoverage: application.workersCompCoverage
        } as UpdateBusinessApplication
      }
    });

    if (!mutationResults.errors || mutationResults.errors.length === 0) {
      // Check for validation errors
      if (
        mutationResults.data &&
        mutationResults.data.updateBusinessApplication &&
        mutationResults.data.updateBusinessApplication.validationError
      ) {
        const validationError = mutationResults.data.updateBusinessApplication.validationError;
        setValidationError(validationError);

        let nextPageIndex = props.pages.findIndex((page) => page.hasError(validationError));
        if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
          nextPageIndex = currentPageIndex + 1;
        }
        if (nextPageIndex !== currentPageIndex && nextPageIndex < props.pages.length) {
          history.push(props.pages[nextPageIndex].path.replace(':id', application.id));
        }
      } else {
        setValidationError(undefined);
        if (currentPageIndex < props.pages.length - 1) {
          history.push(props.pages[currentPageIndex + 1].path.replace(':id', application.id));
        }
      }

      // Update application
      if (mutationResults.data && mutationResults.data.updateBusinessApplication) {
        setApplication({
          ...application,
          ...(mutationResults.data.updateBusinessApplication as Partial<BusinessApplication>)
        });
      }

      return true;
    } else {
      // TODO: currently we only get the first error
      const err = WrappedError.asWrappedError(mutationResults.errors);
      // Send the user to a page that renders the right error
      // based on err.Type and optionally err.Field
      if (err.type() === ErrorType.underwritingError) {
        // Show the user an error indicating we can't offer them insurance.
        window.localStorage.setItem(err.type(), JSON.stringify(err));
        history.push(`/app/commercial/ineligible`);
      }

      if (err.type() === ErrorType.validationError) {
        // Show the user the error in err.Message and highlight the
        // field corresponding to err.Field.
        setValidationError(err.getGraphQLSchemaValidationError());
        // find page to go to if any
        let nextPageIndex = props.pages.findIndex((page) =>
          page.hasError(err.getGraphQLSchemaValidationError())
        );
        if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
          nextPageIndex = currentPageIndex + 1;
        }
        if (nextPageIndex !== currentPageIndex && nextPageIndex < props.pages.length) {
          history.push(props.pages[nextPageIndex].path.replace(':id', application.id));
        }
      }
    }

    return false;
  };

  return React.createElement(props.component, {
    loading,
    application,
    validationError,
    onNext,
    onBack,
    onUpdate
  });
};
