import * as React from 'react';
import styled from 'styled-components';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { useWindowDimensions } from '@oysterjs/core/window';

import { OysterLogo } from '@oysterjs/ui/Logo';
import {
  IoApps,
  IoCashOutline,
  IoExitOutline,
  IoHomeOutline,
  IoNewspaperOutline,
  IoPeopleOutline,
  IoPersonAddOutline,
  IoPricetagsOutline,
  IoShieldCheckmarkOutline
} from 'react-icons/io5';
import { merchantUserSignOut } from '@oysterjs/core/api/merchant';
import { resetToken } from '@oysterjs/core/auth';

const LeftMenuContainer = styled.nav`
  display: block;
  padding: 0px 40px;
  box-sizing: border-box;
  min-width: 250px;
  position: sticky;
  background: #fafafa;
  border-right: 1px solid #eaeaea;

  a,
  a:visited {
    color: #333333;
    text-decoration: none;
  }

  a.active-tab {
    color: #0ea5e9;
  }
`;

const NavItemContainer = styled.ol`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const LogoContainer = styled.div`
  padding: 20px 0px;
`;

const NavItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  :hover {
    color: #0ea5e9;
  }

  :active {
    color: #269985;
  }
`;

export const TextWithIcon = styled.div<{ padding?: string; alignItems?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  gap: 10px;
  padding: ${(props) => (props.padding ? props.padding : '16px 0px')};
`;

const DesktopNavBar: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { url } = useRouteMatch();
  // TODO: Enable this if we want to show rental as a sub child of apps in side bar
  // const location = useLocation();

  const signOut = () => {
    merchantUserSignOut().finally(() => {
      resetToken();
    });
  };

  return (
    <LeftMenuContainer>
      <LogoContainer>
        <OysterLogo />
      </LogoContainer>
      <NavItemContainer>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/home`} activeClassName="active-tab">
            <TextWithIcon>
              <IoHomeOutline />
              Home
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/insurance`} activeClassName="active-tab">
            <TextWithIcon>
              <IoShieldCheckmarkOutline />
              Insurance
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/channels`} activeClassName="active-tab">
            <TextWithIcon>
              <IoPricetagsOutline />
              Channels
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={`${url.replace(/\/+$/, '')}/payouts`} activeClassName="active-tab">
            <TextWithIcon>
              <IoCashOutline />
              Payouts
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={`${url.replace(/\/+$/, '')}/apps`} activeClassName="active-tab">
            <TextWithIcon>
              <IoApps />
              Apps
            </TextWithIcon>
          </NavLink>
        </NavItem>
        {/* TODO: Enable this if we want to show rental as a sub child of apps in side bar */}
        {/* {location.pathname.includes('/apps') && (
          <NavItem>
            <NavLink to={`${url.replace(/\/+$/, '')}/apps/rental`} activeClassName="active-tab">
              <TextWithIcon style={{ marginLeft: '20px' }}>
                <IoCalendarOutline />
                Rental
              </TextWithIcon>
            </NavLink>
          </NavItem>
        )} */}
        <NavItem>
          <NavLink to={`${url.replace(/\/+$/, '')}/reports`} activeClassName="active-tab">
            <TextWithIcon>
              <IoNewspaperOutline />
              Reports
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={`${url.replace(/\/+$/, '')}/team`} activeClassName="active-tab">
            <TextWithIcon>
              <IoPeopleOutline />
              Team
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={`${url.replace(/\/+$/, '')}/invite`} activeClassName="active-tab">
            <TextWithIcon>
              <IoPersonAddOutline />
              Invite
            </TextWithIcon>
          </NavLink>
        </NavItem>
        <NavItem onClick={signOut}>
          <TextWithIcon>
            <IoExitOutline />
            Sign out
          </TextWithIcon>
        </NavItem>
      </NavItemContainer>
    </LeftMenuContainer>
  );
};

export const SideBar: React.FunctionComponent<React.PropsWithChildren<unknown>> = () =>
  useWindowDimensions().innerWidth < 900 ? null : <DesktopNavBar />;
