import styled from 'styled-components';

const StatisticContainer = styled.div`
  font-weight: 500;
  font-size: 1.5em;
`;

const StatisticCaption = styled.div`
  font-size: 0.8em;
  color: #666666;
`;

export enum StatisticType {
  none,
  percent,
  currency
}

interface StatisticProps {
  value: number;
  caption: string;
  type?: StatisticType;
}

const formatValue = (value: number, type: StatisticType) => {
  switch (type) {
    case StatisticType.none:
      return new Intl.NumberFormat(undefined, {
        style: 'decimal'
      }).format(value);
    case StatisticType.currency:
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    case StatisticType.percent:
      return new Intl.NumberFormat(undefined, {
        style: 'percent'
      }).format(value);
  }
};

export const Statistic = (props: StatisticProps) => (
  <div style={{ maxWidth: '150px' }}>
    <StatisticContainer>
      {formatValue(props.value, props.type || StatisticType.none)}
    </StatisticContainer>
    <StatisticCaption>{props.caption}</StatisticCaption>
  </div>
);
