import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Int64: { input: number; output: number };
  Time: { input: string; output: string };
};

export enum AchPaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

/** Describes an address that can be accepted as input */
export type Address = {
  __typename?: 'Address';
  /**
   *  Level 3 administrative division depending on country. For example, this is
   * the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
   */
  city: Scalars['String']['output'];
  /** First line of the address. */
  line1: Scalars['String']['output'];
  /** Optional second line of the address. */
  line2?: Maybe<Scalars['String']['output']>;
  /**
   *  Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
   * in the U.K., etc.
   */
  postalCode: Scalars['String']['output'];
  /**
   *  Level 2 administrative division depending on country. For example, this is
   * the county in the U.S., prefectural city in China, division in India, etc.
   */
  subZone?: Maybe<Scalars['String']['output']>;
  /**
   *  Level 1 administrative division depending on country. For example, this is
   * the state in the U.S., the province in Canada, etc.
   */
  zone: Scalars['String']['output'];
};

/** Describes an address that can be accepted as input */
export type AddressInput = {
  /**
   *  Level 3 administrative division depending on country. For example, this is
   * the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
   */
  city?: InputMaybe<Scalars['String']['input']>;
  /** First line of the address. */
  line1?: InputMaybe<Scalars['String']['input']>;
  /** Optional second line of the address. */
  line2?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
   * in the U.K., etc.
   */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Level 2 administrative division depending on country. For example, this is
   * the county in the U.S., prefectural city in China, division in India, etc.
   */
  subZone?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Level 1 administrative division depending on country. For example, this is
   * the state in the U.S., the province in Canada, etc.
   */
  zone?: InputMaybe<Scalars['String']['input']>;
};

/** ApplicationDecline describes a decline from carrier for a quote request */
export type ApplicationDecline = {
  __typename?: 'ApplicationDecline';
  /** The carrier who declined the request */
  carrier: InsuranceCarrier;
  /** The decline details */
  decline: Scalars['String']['output'];
  /** The application version which the decline happened */
  declinedOnVersion: Scalars['Int64']['output'];
  /** The type of insurance/coverage being declined */
  type: InsuranceType;
};

/** Describes the reason why an application was updated */
export enum ApplicationUpdateReasonCode {
  /** Application was canceled because the user explicitly requested it so */
  ApplicationCanceledByUser = 'APPLICATION_CANCELED_BY_USER',
  /** Application was canceled because it expired before it could be evaluated by the carrier, or because the customer did not confirm they wanted to proceed with the policy */
  ApplicationExpired = 'APPLICATION_EXPIRED',
  /** Application was canceled because it was rejected by the carrier */
  ApplicationRejected = 'APPLICATION_REJECTED',
  /** Application was updated due a data migration */
  DataMigration = 'DATA_MIGRATION',
  /** Offer was canceled because the user explicitly requested it so */
  OfferCanceledByUser = 'OFFER_CANCELED_BY_USER',
  /** Offer was canceled because it expired before the user submitted it */
  OfferExpired = 'OFFER_EXPIRED'
}

/** Describes the type of update that occured to a application */
export enum ApplicationUpdateType {
  /** The application was canceled and is no longer active */
  CancelApplication = 'CANCEL_APPLICATION',
  /** The application offer was canceled */
  CancelOffer = 'CANCEL_OFFER',
  /** The application was created, either directely or by completing an offer */
  CreateApplication = 'CREATE_APPLICATION',
  /** The application was completed and submitted, and is now ready to be reviewed by the carrier */
  SubmitApplication = 'SUBMIT_APPLICATION',
  /** The application offer was submitted */
  SubmitOffer = 'SUBMIT_OFFER',
  /** Unknown update type. Requires manual inspection of the application to determine the actual update */
  Unknown = 'UNKNOWN',
  /** The application was updated. Requires manual inspection of the application to determine the actual update */
  UpdateApplication = 'UPDATE_APPLICATION'
}

/** Describes a particular version of a policy */
export type ApplicationVersion = {
  __typename?: 'ApplicationVersion';
  /** The full policy object that includes all of the updates made in this version and all previous versions */
  businessApplication: BusinessApplication;
  /** The date this version was created */
  createdAt: Scalars['Time']['output'];
  /** The version number of the previous policy version, if there is any. If this field is null, then this version is the only version */
  previousVersion?: Maybe<Scalars['Int64']['output']>;
  /** The entity that initiated the update */
  updateInitiator: Scalars['String']['output'];
  /** Indicates whether this update is 'private' in the sense that it should not be displayed to the policyholder */
  updatePrivate: Scalars['Boolean']['output'];
  /** A human-readable description of the reason why this update was made */
  updateReason: Scalars['String']['output'];
  /** A programmatic representation of the reason why this update was made */
  updateReasonCode: ApplicationUpdateReasonCode;
  /** The types of updates made between this version and the previous version */
  updateTypes: Array<ApplicationUpdateType>;
  /** The version number of this policy version */
  version: Scalars['Int64']['output'];
};

/**
 * Describes the Ascend billable that this policy is associated with. This
 * type represents the response from the Ascend /v1/billables API
 */
export type AscendBillable = {
  __typename?: 'AscendBillable';
  /** Fee collected by your agency (dollars). Fully earned, not financeable, and funded directly to your agency. */
  agencyFees: Scalars['Float']['output'];
  /** The identifier assigned to the quote/endorsement by the issuing carrier or wholesaler. */
  billableIdentifier: Scalars['String']['output'];
  /** Fee collected by the wholesaler (dollars). Fully earned, not financeable, and funded directly to the wholesaler. */
  brokerFees: Scalars['Float']['output'];
  /** An insurance company. */
  carrier: AscendCarrier;
  /** A type of coverage. */
  coverageType: AscendCoverage;
  /** A short description to explain to the insured what they are purchasing. */
  description?: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the billable */
  id: Scalars['String']['output'];
  /** Determines if the carrier can audit customer raising premium significantly. */
  isAuditable: Scalars['Boolean']['output'];
  /** Determines if specific lines of business are filed with other bureaus which they have to notify on cancelation. */
  isFiled: Scalars['Boolean']['output'];
  /** Determines if the policy is fined by 10% on top of earned premium when insured cancels */
  isShortRate: Scalars['Boolean']['output'];
  /** Set state determined extended cancelation period for the policy. */
  minDaysToCancel: Scalars['Int']['output'];
  /** The minimum rate to charge insureds when they decide to finance. */
  minEarnedRate: Scalars['Float']['output'];
  /** Any additional fees that come with the policy (dollars). This fee is treated as non-refundable & fully earned. */
  otherFees: Scalars['Float']['output'];
  /** Payouts created for this program, if any. This corresponds to the data returned from the /v1/payouts endpoint */
  payouts: Array<AscendPayout>;
  /** Any policy fees that come with the billable (dollars). This fee is treated as non-refundable & fully earned. */
  policyFees: Scalars['Float']['output'];
  /** The amount to pay for the insurance policy (dollars). */
  premium: Scalars['Float']['output'];
  /** Your commission rate for this policy. */
  sellerCommissionRate?: Maybe<Scalars['Float']['output']>;
  /** The amount of surplus lines tax to be collected (dollars). */
  surplusLinesTax: Scalars['Float']['output'];
  /** Any taxes & unearned fees (dollars). Taxes and fees are financed. */
  taxesAndFees: Scalars['Float']['output'];
  /** An insurance wholesaler or MGA. */
  wholesaler?: Maybe<AscendWholesaler>;
};

/** An insurance company. */
export type AscendCarrier = {
  __typename?: 'AscendCarrier';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The carrier display name. */
  title: Scalars['String']['output'];
};

/** A type of coverage. */
export type AscendCoverage = {
  __typename?: 'AscendCoverage';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The coverage type display name. */
  title: Scalars['String']['output'];
};

/** A document representing a transaction between the insured and Ascend. */
export type AscendInvoice = {
  __typename?: 'AscendInvoice';
  /** The checkout url of the invoice. */
  checkoutUrl?: Maybe<Scalars['String']['output']>;
  /** The date by which the invoice should be paid. */
  dueDate?: Maybe<Scalars['Time']['output']>;
  /** The invoice unique identifier. */
  id: Scalars['String']['output'];
  /** The unique identifier of the insured invoice is attached to. */
  insuredID: Scalars['String']['output'];
  /** A number assigned to uniquely identify the invoice. */
  invoiceNumber: Scalars['String']['output'];
  /** The url of the invoice document. */
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  /** The date when the invoice was shared with the insured. */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The date when the invoice was paid by the insured. */
  paidAt?: Maybe<Scalars['Time']['output']>;
  /** The name of the insured/business paying for the invoice. */
  payerName: Scalars['String']['output'];
  /** It represents the payment instrument used by an insured. */
  paymentMethod?: Maybe<AscendPaymentMethod>;
  /** The unique identifier of the program invoice is attached to. */
  programID?: Maybe<Scalars['String']['output']>;
  /** The current state of the invoice. */
  status: Scalars['String']['output'];
  /** The total amount charged, in dollars. */
  totalAmount: Scalars['Float']['output'];
};

/** A loan associated with an Ascend program */
export type AscendLoan = {
  __typename?: 'AscendLoan';
  /** The amount financed through the loan represented in dollars. */
  amountFinanced: Scalars['Float']['output'];
  /** The total annual rate charged. */
  apr: Scalars['Float']['output'];
  /** The date when the loan was created. The date uses UTC and is represented in the ISO8601 format. */
  createdAt: Scalars['Time']['output'];
  /** The initial up-front payment represented in dollars. */
  downPayment: Scalars['Float']['output'];
  /** The loan unique identifier. */
  id: Scalars['String']['output'];
  /** The number of payments that will be made to repay the loan. */
  numberOfPayments: Scalars['Int']['output'];
  /** The current state of the loan. */
  status: Scalars['String']['output'];
  /** The amount that will be payed in each payment in dollars. */
  termPayment: Scalars['Float']['output'];
  /** The date when the loan was last updated. The date uses UTC and is represented in the ISO8601 format. */
  updatedAt: Scalars['Time']['output'];
};

/** The account the Ascend payout was made to */
export type AscendPayableAccount = {
  __typename?: 'AscendPayableAccount';
  /** The ID of the payable account */
  id: Scalars['String']['output'];
  /** The last four digits of the bank account */
  lastFour: Scalars['String']['output'];
  /** The owner name of the bank account */
  ownerName: Scalars['String']['output'];
  /** The routing number of the bank account */
  routingNumber: Scalars['String']['output'];
  /** The usage type of the bank account */
  usageType: AscendPayableAccountUsageType;
};

export enum AscendPayableAccountUsageType {
  /** Organization's bank account we transfer commissions to. */
  CommissionAccount = 'COMMISSION_ACCOUNT',
  /** Paid out to carrier/wholesaler */
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  /** Organization's bank account we transfer full premium to. */
  TrustAccount = 'TRUST_ACCOUNT'
}

export type AscendPaymentMethod = {
  __typename?: 'AscendPaymentMethod';
  type: AscendPaymentMethodType;
};

export enum AscendPaymentMethodType {
  AchCreditTransfer = 'ACH_CREDIT_TRANSFER',
  AchDebit = 'ACH_DEBIT',
  Card = 'CARD'
}

/** A payout from Ascend to an entity */
export type AscendPayout = {
  __typename?: 'AscendPayout';
  /** The date the payout was created */
  createdAt: Scalars['Time']['output'];
  /** The date that the payout failed */
  failedAt?: Maybe<Scalars['Time']['output']>;
  /** The gross amount paid out, in dollars. */
  grossPayoutAmount: Scalars['Float']['output'];
  /** The payout unique identifier */
  id: Scalars['String']['output'];
  /** If found, the details of the bank transaction on Oyster's side */
  linkedBankTransaction?: Maybe<LinkedBankTransaction>;
  /** The net amount paid out, in dollars. */
  netPayoutAmount: Scalars['Float']['output'];
  /** The date that the payout was paid */
  paidAt?: Maybe<Scalars['Time']['output']>;
  /** The account that the payout was made to */
  payableAccount: AscendPayableAccount;
  /** The date that the payout will be paid */
  payingAt?: Maybe<Scalars['Time']['output']>;
  /** The type of the recipient */
  recipientType: AscendRecipientType;
  /** The reference number included in the bank memo associated with this payout */
  reference?: Maybe<Scalars['String']['output']>;
  /** The current state of the payout */
  status: AscendPayoutStatus;
  /** The type of the payout */
  type: AscendPayoutType;
};

/** The status of the payout */
export enum AscendPayoutStatus {
  /** The payout has been canceled */
  Canceled = 'CANCELED',
  /** The payout has failed to reach the payable account. */
  Failed = 'FAILED',
  /** The payout is blocked until the invoice release_date when funds can be paid to the payable account. */
  OnHold = 'ON_HOLD',
  /** The payout has been paid to designated PayableAccount */
  Paid = 'PAID',
  /** The payout has been initiated but we have not received confirmation that it has cleared. */
  Paying = 'PAYING',
  /** The payout is waiting for confirmation from servicer indicating it has been funded. */
  Transferred = 'TRANSFERRED',
  /** Initial state, no action has been taken on the payout. */
  Unpaid = 'UNPAID'
}

/** The type of the payout */
export enum AscendPayoutType {
  /** Transfer cumulative supplier funding to org so that they can pay out to supplier - paid to the organization's trust account */
  AgentSupplierFunding = 'AGENT_SUPPLIER_FUNDING',
  /** Policy's commission paid to organization's commission account */
  Commission = 'COMMISSION',
  /** Policy's full premium (premium w/ commission) paid to organization's trust account */
  FullPremium = 'FULL_PREMIUM',
  /** Policy's net premium (full premium - commission) paid out to org's trust account (direct bill) */
  NetPremium = 'NET_PREMIUM',
  /** Payout to agency's commission account for one-off invoice */
  OneOff = 'ONE_OFF',
  /** Payout to org for additional funds they have transferred to us - paid to the organization's commission account */
  Overpayment = 'OVERPAYMENT',
  /** Policy's premium paid out to supplier's honor_supplier_account or supplier_account (agency bill) */
  Supplier = 'SUPPLIER',
  /** Payout to agency's trust account for surplus line taxes */
  SurplusLinesTax = 'SURPLUS_LINES_TAX',
  /** Financed policy's downpayment paid to honor's downpayment account */
  TakeRate = 'TAKE_RATE'
}

/** A collection of insurance quotes pooled to produce 1 checkout link. */
export type AscendProgram = {
  __typename?: 'AscendProgram';
  /** The date the program was archived. The date uses UTC and is represented in the ISO8601 format. */
  archivedAt?: Maybe<Scalars['Time']['output']>;
  /** The date the program was checked out. The date uses UTC and is represented in the ISO8601 format. */
  checkedOutAt?: Maybe<Scalars['Time']['output']>;
  /** The date the program was created. The date uses UTC and is represented in the ISO8601 format. */
  createdAt: Scalars['Time']['output'];
  /** The unique identifier for the program */
  id: Scalars['String']['output'];
  /** Invoices created for this program, if any. This corresponds to the data returned from the /v1/invoices endpoint */
  invoices: Array<AscendInvoice>;
  /** Financing details for this program, if any. This corresponds to the data returned from the /v1/loans endpoint */
  loans: Array<AscendLoan>;
  /** A URL to complete the checkout process. */
  programUrl: Scalars['String']['output'];
  /** The payment option chosen by the insured during the checkout process. */
  selectedPaymentOptionType?: Maybe<PaymentOptionType>;
  /** The state of the program */
  status: AscendProgramStatus;
  /** The date the program was last updated. The date uses UTC and is represented in the ISO8601 format. */
  updatedAt: Scalars['Time']['output'];
};

export enum AscendProgramCreationMode {
  BundleWithExistingQuote = 'BUNDLE_WITH_EXISTING_QUOTE',
  CreateProgram = 'CREATE_PROGRAM',
  UseSpecificProgramId = 'USE_SPECIFIC_PROGRAM_ID'
}

/** The status of a program */
export enum AscendProgramStatus {
  Archived = 'ARCHIVED',
  CheckedOut = 'CHECKED_OUT',
  Created = 'CREATED',
  Elected = 'ELECTED',
  Purchased = 'PURCHASED',
  ReadyForCheckout = 'READY_FOR_CHECKOUT'
}

/** The type of the Ascend recipient */
export enum AscendRecipientType {
  Account = 'ACCOUNT',
  Carrier = 'CARRIER',
  Organization = 'ORGANIZATION',
  Wholesaler = 'WHOLESALER'
}

/** An insurance wholesaler or MGA. */
export type AscendWholesaler = {
  __typename?: 'AscendWholesaler';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The wholesaler display name. */
  title: Scalars['String']['output'];
};

export type BindQuoteInput = {
  id: Scalars['ID']['input'];
};

/** Business Application represents an application */
export type BusinessApplication = {
  __typename?: 'BusinessApplication';
  /** Class code */
  classCodes?: Maybe<Array<Scalars['String']['output']>>;
  /** The date the application was originally created */
  createdAt: Scalars['Time']['output'];
  /** Contains a list of declines this application has received */
  declines?: Maybe<Array<ApplicationDecline>>;
  /** The proposed start date provided by merchant */
  desiredCoverageStartDate: Scalars['Time']['output'];
  /** retrieve all attached files for this application */
  files: Array<File>;
  /** General application that all operations and coverages need */
  generalApplication?: Maybe<GeneralApplication>;
  /** The id of the business application */
  id: Scalars['ID']['output'];
  /** The coverages a business is applying for */
  insuranceTypes: Array<InsuranceType>;
  /** Liabiity coverage application */
  liabilityCoverage?: Maybe<CoverageApplication>;
  /** Locations where business operates out of */
  locations: Array<Location>;
  /** Manufacturing specific application */
  manufacturingUnderwriting?: Maybe<OperationApplication>;
  /** Merchant that is associated with the business application */
  merchant?: Maybe<Merchant>;
  /** Operation types of a business */
  operations: Array<OperationType>;
  optedIntoOysterBundle: Scalars['Boolean']['output'];
  /** Other operations specific application */
  otherUnderwriting?: Maybe<OperationApplication>;
  /** The basic business profile */
  profile: BusinessProfile;
  /** Property coverage application */
  propertyCoverage?: Maybe<PropertyCoverageApplication>;
  /** client can query this - either look up cached quotes or generate new one */
  quotes: Array<BusinessQuote>;
  /** Rental specific application */
  rentalUnderwriting?: Maybe<RentalApplication>;
  /** Retail specific application */
  retailUnderwriting?: Maybe<OperationApplication>;
  /** Service specific application */
  serviceUnderwriting?: Maybe<OperationApplication>;
  /** State of the application */
  state: BusinessApplicationState;
  /** The date this application was last updated */
  updatedAt: Scalars['Time']['output'];
  /** Contains the validation errors from form validation */
  validationError?: Maybe<ValidationError>;
  /** The version of this application */
  version: Scalars['Int64']['output'];
  /**
   * The previous versions of this application, subject to the specified limit and offset.
   * The first version in this list will be this version (i.e. identical to the current application), followed by the version before that, and so-forth.
   */
  versionHistory: Array<ApplicationVersion>;
  /** Wholesale specific application */
  wholesaleUnderwriting?: Maybe<OperationApplication>;
  /** Workers Comp coverage application */
  workersCompCoverage?: Maybe<CoverageApplication>;
};

/** Business Application represents an application */
export type BusinessApplicationVersionHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The state of business application */
export enum BusinessApplicationState {
  /** A state where application is canceled for various reasons */
  Canceled = 'CANCELED',
  /** After application is actioned and completed */
  Completed = 'COMPLETED',
  /** After application is actioned and declined */
  Declined = 'DECLINED',
  /** After application object is created and before merchant submits */
  Pending = 'PENDING',
  /** After application is submitted but not completely actioned */
  Submitted = 'SUBMITTED',
  Unknown = 'UNKNOWN'
}

/** Describe the response for application submission mutation */
export type BusinessApplicationSubmissionResponse = {
  __typename?: 'BusinessApplicationSubmissionResponse';
  application?: Maybe<BusinessApplication>;
  redirectionUrl?: Maybe<Scalars['String']['output']>;
};

export type BusinessInsurancePersonalizationInput = {
  insuranceOtherDesc?: InputMaybe<Scalars['String']['input']>;
  insuranceTypes: Array<InsuranceType>;
  operationOtherDesc?: InputMaybe<Scalars['String']['input']>;
  operationTypes: Array<OperationType>;
  revenueManufacturing?: InputMaybe<Scalars['Float']['input']>;
  revenueOther?: InputMaybe<Scalars['Float']['input']>;
  revenueRental?: InputMaybe<Scalars['Float']['input']>;
  revenueRentalPercentGuided?: InputMaybe<Scalars['Float']['input']>;
  revenueRetail?: InputMaybe<Scalars['Float']['input']>;
  revenueServiceOrRepair?: InputMaybe<Scalars['Float']['input']>;
  revenueWholesale?: InputMaybe<Scalars['Float']['input']>;
  totalPayroll?: InputMaybe<Scalars['Float']['input']>;
};

/** Business Policy represents the business or commercial insurance policy */
export type BusinessPolicy = {
  __typename?: 'BusinessPolicy';
  /** The application that resulted in this policy, if any */
  application?: Maybe<BusinessApplication>;
  /** The broker providing this policy, if any */
  broker?: Maybe<InsuranceCarrier>;
  /** If the policy was canceled or nonrenewed, information about the latest instance of cancellation or nonrenewal */
  cancellation?: Maybe<BusinessPolicyCancellation>;
  /** The carrier providing this policy */
  carrier: InsuranceCarrier;
  /** The date the policy object was originally created */
  createdAt: Scalars['Time']['output'];
  /** The coverage deductibles */
  deductibles: Deductibles;
  /** Details of the policy */
  details: BusinessPolicyDetails;
  /** The timestamp when this policy expires */
  expiresAt?: Maybe<Scalars['Time']['output']>;
  /** The external/carrier ID of the policy */
  externalId: Scalars['ID']['output'];
  /** All attached files for this application */
  files: Array<File>;
  /** The internal ID of the policy */
  id: Scalars['ID']['output'];
  /** The timestamp when this policy is inforce */
  inforceAt?: Maybe<Scalars['Time']['output']>;
  /** The timestamp when this policy was issued */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The coverage limits of the policy */
  limits: Limits;
  /** The market this policy was written in */
  market: InsuranceMarket;
  /** Merchant that is associated with the policy */
  merchant?: Maybe<Merchant>;
  /** The number or external identifier associated with this policy within the carrier system */
  policyNumber: Scalars['String']['output'];
  /** The pricing information for this policy */
  pricing: BusinessPolicyPricing;
  /** The quote that resulted in this policy, if any */
  quote?: Maybe<BusinessQuote>;
  /** The number or external identifier associated with this quote within the carrier system */
  quoteNumber: Scalars['String']['output'];
  /** If the policy was reinstated, information about the latest instance of when it was reinstated */
  reinstatement?: Maybe<BusinessPolicyReinstatement>;
  /** If this policy was renewed from another policy, information about the renewal and its previous versions */
  renewal?: Maybe<BusinessPolicyRenewal>;
  /** The source of the policy, and associated details */
  source: BusinessPolicySource;
  /** The current state of the policy */
  state: PolicyState;
  /** The type of insurance this policy represents */
  type: InsuranceType;
  /** The date this policy object was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The version of this policy */
  version: Scalars['Int64']['output'];
  /** The previous versions of this policy. The first version in this list will be this version (i.e. identical to the current policy), followed by the version before that, and so-forth. */
  versionHistory?: Maybe<Array<BusinessPolicyVersion>>;
};

export type BusinessPolicyAgencyBilling = {
  __typename?: 'BusinessPolicyAgencyBilling';
  ascendBillable?: Maybe<AscendBillable>;
  ascendProgram?: Maybe<AscendProgram>;
  stripeInvoice?: Maybe<StripeInvoice>;
  type: BusinessPolicyAgencyBillingType;
};

export enum BusinessPolicyAgencyBillingType {
  Ascend = 'ASCEND',
  Manual = 'MANUAL',
  StripeInvoice = 'STRIPE_INVOICE'
}

/** The billing type of policy, can be direct or agent */
export enum BusinessPolicyBillingType {
  Agency = 'AGENCY',
  Direct = 'DIRECT',
  Unknown = 'UNKNOWN'
}

export type BusinessPolicyCancellation = {
  __typename?: 'BusinessPolicyCancellation';
  canceledAt: Scalars['Time']['output'];
  canceledBy: BusinessPolicyCancellationSource;
  effectiveAt: Scalars['Time']['output'];
  reason: BusinessPolicyCancellationReason;
  reasonDescription: Scalars['String']['output'];
  type: BusinessPolicyCancellationType;
};

export enum BusinessPolicyCancellationReason {
  CarrierClosedClass = 'CARRIER_CLOSED_CLASS',
  CarrierLossHistory = 'CARRIER_LOSS_HISTORY',
  CarrierNonpayment = 'CARRIER_NONPAYMENT',
  CarrierUnderwritingReason = 'CARRIER_UNDERWRITING_REASON',
  InsuredClosedBusiness = 'INSURED_CLOSED_BUSINESS',
  InsuredFoundBetterCoverage = 'INSURED_FOUND_BETTER_COVERAGE',
  InsuredFoundBetterPrice = 'INSURED_FOUND_BETTER_PRICE',
  InsuredNoExposure = 'INSURED_NO_EXPOSURE',
  Other = 'OTHER',
  OysterFoundBetterCoverage = 'OYSTER_FOUND_BETTER_COVERAGE',
  OysterFoundBetterPrice = 'OYSTER_FOUND_BETTER_PRICE'
}

export enum BusinessPolicyCancellationSource {
  Carrier = 'CARRIER',
  Insured = 'INSURED',
  Oyster = 'OYSTER'
}

export enum BusinessPolicyCancellationType {
  Cancellation = 'CANCELLATION',
  Nonrenewal = 'NONRENEWAL',
  QuoteExpired = 'QUOTE_EXPIRED'
}

/** Details of a commercial policy. */
export type BusinessPolicyDetails = {
  __typename?: 'BusinessPolicyDetails';
  /** The locations specified for this policy */
  locations: Array<Location>;
  /** The primary location for this policy */
  primaryLocation: Location;
  /** The raw policy data from carrier */
  rawData: Scalars['String']['output'];
};

export type BusinessPolicyInput = {
  additionalLocations?: InputMaybe<Array<LocationInput>>;
  agencyBillingType?: InputMaybe<BusinessPolicyAgencyBillingType>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['input'];
  ascendExistingQuoteRefId?: InputMaybe<Scalars['String']['input']>;
  ascendProgramCreationMode?: InputMaybe<AscendProgramCreationMode>;
  ascendProgramId?: InputMaybe<Scalars['String']['input']>;
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['input'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['input'];
  brokerId?: InputMaybe<Scalars['String']['input']>;
  carrierId: Scalars['String']['input'];
  estimatedCommission: Scalars['Float']['input'];
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  id: Scalars['String']['input'];
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  market: InsuranceMarket;
  minimumEarnedPremium: Scalars['Float']['input'];
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['input'];
  /** Payment interval of the policy. If billed via ASCEND, leave this blank */
  paymentIntervalType?: InputMaybe<PaymentIntervalType>;
  paymentOccurrencesPerInterval: Scalars['Int']['input'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['input'];
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium: Scalars['Float']['input'];
  primaryLocation?: InputMaybe<LocationInput>;
  quoteNumber: Scalars['String']['input'];
  sourceAgentId: Scalars['String']['input'];
  sourceLeadId?: InputMaybe<Scalars['String']['input']>;
  sourceNotes: Scalars['String']['input'];
  sourceType: BusinessPolicySourceType;
  state: PolicyState;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['input'];
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total: Scalars['Float']['input'];
  type: InsuranceType;
};

/** Represents all pricing information for a policy */
export type BusinessPolicyPricing = {
  __typename?: 'BusinessPolicyPricing';
  /** Actual commission received by Oyster for this policy */
  actualCommission?: Maybe<Scalars['Float']['output']>;
  /** Add a resolver to compute actual commission + our fees */
  actualRevenue: Scalars['Float']['output'];
  /** If billing type is AGENCY, this specifies information about the agency bill */
  agencyBilling?: Maybe<BusinessPolicyAgencyBilling>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['output'];
  /** The billing type */
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['output'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['output'];
  /** Estimate of the commission Oyster receive for this policy */
  estimatedCommission: Scalars['Float']['output'];
  /** Add a resolver to compute estimated commission + our fees */
  estimatedRevenue: Scalars['Float']['output'];
  /** The fees associated with the insurance */
  fees: Array<Fee>;
  /** The interval type of payment plan. Leave null if currently unknown */
  intervalType?: Maybe<PaymentIntervalType>;
  /** The minimum premium that must be paid for policy, regardless of cancellation, if any */
  minimumEarnedPremium: Scalars['Float']['output'];
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['output'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['output'];
  /** The premium for this policy */
  premium: Scalars['Float']['output'];
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['output'];
  /** The total price paid for this policy */
  total: Scalars['Float']['output'];
};

export type BusinessPolicyQueryInput = {
  id: Scalars['ID']['input'];
  type: BusinessPolicyQueryInputType;
};

export enum BusinessPolicyQueryInputType {
  MerchantId = 'MERCHANT_ID',
  PolicyId = 'POLICY_ID'
}

export type BusinessPolicyReinstatement = {
  __typename?: 'BusinessPolicyReinstatement';
  effectiveAt: Scalars['Time']['output'];
  reasonDescription: Scalars['String']['output'];
  reinstatedAt: Scalars['Time']['output'];
};

export type BusinessPolicyRenewal = {
  __typename?: 'BusinessPolicyRenewal';
  nextPolicies: Array<BusinessPolicy>;
  previousPolicies: Array<BusinessPolicy>;
};

export type BusinessPolicySource = {
  __typename?: 'BusinessPolicySource';
  agent?: Maybe<OysterAgent>;
  leadId?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  type: BusinessPolicySourceType;
};

/** Describes the source where the business deal comes from */
export enum BusinessPolicySourceType {
  BoldPenguin = 'BOLD_PENGUIN',
  GtmCrossSell = 'GTM_CROSS_SELL',
  Inbound = 'INBOUND',
  Other = 'OTHER',
  SelfServe = 'SELF_SERVE',
  Tivly = 'TIVLY'
}

/** Describes the reason why an application was updated */
export enum BusinessPolicyUpdateReasonCode {
  /** Placeholder reason codes until the use case becomes more clear */
  Unknown = 'UNKNOWN'
}

/** Describes the type of update that occured to a policy */
export enum BusinessPolicyUpdateType {
  /** The policy was canceled and is no longer active */
  CancelPolicy = 'CANCEL_POLICY',
  /** The policy was created */
  CreatePolicy = 'CREATE_POLICY',
  /** The policy expired and its no longer active */
  ExpirePolicy = 'EXPIRE_POLICY',
  /** The policy was reinstated after previously being canceled */
  ReinstatePolicy = 'REINSTATE_POLICY',
  /** The policy was created as a renewal */
  RenewPolicy = 'RENEW_POLICY',
  /** The policy was updated. Requires manual inspection of the policy to determine the actual update */
  UpdatePolicy = 'UPDATE_POLICY'
}

/** Describes a particular version of a policy */
export type BusinessPolicyVersion = {
  __typename?: 'BusinessPolicyVersion';
  /** The full policy object that includes all of the updates made in this version and all previous versions */
  businessPolicy: BusinessPolicy;
  /** The date this version was created */
  createdAt: Scalars['Time']['output'];
  /** Produces a human-readable string that can be used to diff against another policy version. */
  diffableJson: Scalars['String']['output'];
  /** The version number of the previous policy version, if there is any. If this field is null, then this version is the only version */
  previousVersion?: Maybe<BusinessPolicyVersion>;
  /** The ID of the entity that initiated the update, depending on the type */
  updateInitiatorId: Scalars['String']['output'];
  /** The type of the entity that initiated the update */
  updateInitiatorType: UpdateInitiatorType;
  /** Indicates whether this update is 'private' in the sense that it should not be displayed to the policyholder */
  updatePrivate: Scalars['Boolean']['output'];
  /** A human-readable description of the reason why this update was made */
  updateReason?: Maybe<Scalars['String']['output']>;
  /** A programmatic representation of the reason why this update was made */
  updateReasonCode?: Maybe<BusinessPolicyUpdateReasonCode>;
  /** The types of updates made between this version and the previous version */
  updateTypes: Array<BusinessPolicyUpdateType>;
  /** The version number of this policy version */
  version: Scalars['Int64']['output'];
};

/** Business Profile represents the basic details collected from a business for their insurance applciation */
export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  address?: Maybe<Address>;
  dba: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  productVerticals: Array<ProductType>;
};

/** Business Profile represents the basic details collected from a business for their insurance applciation */
export type BusinessProfileInput = {
  address?: InputMaybe<AddressInput>;
  dba?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  productVerticals?: InputMaybe<Array<ProductType>>;
};

export type BusinessQuote = {
  __typename?: 'BusinessQuote';
  /** The application which this quote belongs to */
  application: BusinessApplication;
  /** The carrier providing this quote */
  carrier: InsuranceCarrier;
  /** Quote identifier on carrier's system */
  carrierQuoteId: Scalars['ID']['output'];
  /** Timestamp of object creation */
  createdAt: Scalars['Time']['output'];
  /** Details of this business quote, including pricing and discount configuration */
  details: BusinessQuoteDetails;
  /** The internal id of the quote */
  id: Scalars['ID']['output'];
  /** Timestamp at which this quote was issued */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The merchant associated with this quote */
  merchant: Merchant;
  /** Current state of this quote */
  state: BusinessQuoteState;
  /** Type of insurance quoted */
  type: InsuranceType;
  /** The underwriter for this quote, if information exists */
  underwriter?: Maybe<InsuranceCarrier>;
  /** Last update timstamp of this object */
  updatedAt: Scalars['Time']['output'];
  /** Timestamp at which this quote becomes invalid */
  validUntil?: Maybe<Scalars['Time']['output']>;
};

export type BusinessQuoteDetails = {
  __typename?: 'BusinessQuoteDetails';
  /** The URL which agents can use to access the quote */
  agentUrl?: Maybe<Scalars['String']['output']>;
  /** Whether to allow any discounts to be applied to this quote pricing */
  allowDiscounts: Scalars['Boolean']['output'];
  /** The URL on carrier's system which we can use for application editing and manual binding purposes, if any */
  applicationUrl?: Maybe<Scalars['String']['output']>;
  /** Notices contains all the text and checkboxes we need to show in the frontend for compliance */
  notices: Array<BusinessQuoteNotices>;
  /** The pricing information for the quote */
  pricing: BusinessQuotePricing;
  /** The URL where the quote proposal document lives, if any */
  proposalUrl?: Maybe<Scalars['String']['output']>;
  /** The raw quote data from carrier */
  rawData?: Maybe<Scalars['String']['output']>;
};

/** BusinessQuoteNotices represents a compliance text for quote binding purposes */
export type BusinessQuoteNotices = {
  __typename?: 'BusinessQuoteNotices';
  /** Optional identifier to help frontend show specific content */
  identifier?: Maybe<Scalars['String']['output']>;
  /** Whether this notice requires acceptance from users */
  requireAcceptance: Scalars['Boolean']['output'];
  /** The dynamic text/subtitle to be shown, if any */
  text?: Maybe<Scalars['String']['output']>;
  /** The title to be shown, if any */
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents all pricing information for a quote */
export type BusinessQuotePricing = {
  __typename?: 'BusinessQuotePricing';
  /** The estimated price after rebate */
  bundled: Scalars['Float']['output'];
  /** The deductibles associated with the quote */
  deductibles: Deductibles;
  /** The fees associated with the quote */
  fees: Array<Fee>;
  /** The interval type of payment plan */
  intervalType: PaymentIntervalType;
  /** The coverage limits of the quote */
  limits: Limits;
  /** The amount of payment occurrence per interval type */
  occurrence: Scalars['Int']['output'];
  /** The premium provided by carrier */
  premium: Scalars['Float']['output'];
  /** The total price to be paid */
  total: Scalars['Float']['output'];
};

export enum BusinessQuoteState {
  Bindable = 'BINDABLE',
  Bound = 'BOUND',
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type CancelBusinessPolicyInput = {
  canceledBy: BusinessPolicyCancellationSource;
  effectiveAt: Scalars['Time']['input'];
  reason: BusinessPolicyCancellationReason;
  reasonDescription: Scalars['String']['input'];
  type: BusinessPolicyCancellationType;
};

export type CertificateCoverage = {
  __typename?: 'CertificateCoverage';
  effectiveDate: Scalars['Time']['output'];
  expirationDate: Scalars['Time']['output'];
  hasWaiverOfSubrogation: Scalars['Boolean']['output'];
  holderIsAdditionalInsured: Scalars['Boolean']['output'];
  insurer: CoverageInsurer;
  limits: Array<CertificateLimit>;
  policyNumber: Scalars['String']['output'];
  type: InsuranceType;
};

export type CertificateDetails = {
  __typename?: 'CertificateDetails';
  certificateHolder: CertificateEntity;
  certificateNumber: Scalars['String']['output'];
  coverages: Array<CertificateCoverage>;
  descriptionOfOperations: Scalars['String']['output'];
  insured: CertificateEntity;
  producer: CertificateEntity;
};

export type CertificateEntity = {
  __typename?: 'CertificateEntity';
  address: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CertificateLimit = {
  __typename?: 'CertificateLimit';
  limit: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type CoverageApplication = {
  __typename?: 'CoverageApplication';
  aggregateLimit: Scalars['Float']['output'];
  deductible: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
};

export type CoverageApplicationInput = {
  deductible?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
};

export type CoverageInsurer = {
  __typename?: 'CoverageInsurer';
  naic: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** CreateBusinessApplication is the input for the creation of a business application */
export type CreateBusinessApplication = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  productVerticals?: InputMaybe<Array<ProductType>>;
};

export type CreateBusinessPoliciesInput = {
  insured: InsuredInput;
  merchantId: Scalars['String']['input'];
  policies: Array<BusinessPolicyInput>;
};

export type CreateFileInput = {
  bytesBase64: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  role: FileRole;
  visibility: FileVisibility;
};

export type CreateMerchantInput = {
  address: AddressInput;
  businessDba: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  personalization?: InputMaybe<PersonalizationInput>;
  phone: Scalars['String']['input'];
  productVertical: ProductType;
  userFirstName: Scalars['String']['input'];
  userLastName: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

/** Deductibles represents deductibles component of an insurance */
export type Deductibles = {
  __typename?: 'Deductibles';
  /** bpp deductible */
  bpp?: Maybe<Scalars['Float']['output']>;
  /** building deductible */
  building?: Maybe<Scalars['Float']['output']>;
  /** property damage deductible */
  propertyDamageLiability?: Maybe<Scalars['Float']['output']>;
};

/** Fee represents a fee structure that can be applied to either quotes, policies, or premiums */
export type Fee = {
  __typename?: 'Fee';
  /** The amount to be paid per occurrence */
  amount: Scalars['Float']['output'];
  /** The fee name to be displayed */
  displayName: Scalars['String']['output'];
  /** The type of occurrence interval, for example fee occurrence of 2 with type annual means it would be collected twice per year */
  intervalType: PaymentIntervalType;
  /** The amount of occurrence per interval type */
  occurrence: Scalars['Int']['output'];
};

/**
 * A file describes a document that is attached to an object, with
 * metadata and URLs to fetch it.
 */
export type File = {
  __typename?: 'File';
  /** The internal URL to fetch the file */
  adminUrl: Scalars['String']['output'];
  /** The timestamp this file was created */
  createdAt: Scalars['Time']['output'];
  /** The details of this specific file, depending on the file role */
  details?: Maybe<FileDetails>;
  /** The unique identifier of the file */
  id: Scalars['ID']['output'];
  /** The file name, for display and download purposes */
  name: Scalars['String']['output'];
  /** The notes associated with the file */
  notes: Scalars['String']['output'];
  /** The role of the file, i.e. how it relates to the object it is attached to */
  role: FileRole;
  /** The size of the file in bytes, if available */
  size: Scalars['Int']['output'];
  /** The file MIME type */
  type: Scalars['String']['output'];
  /** The timestamp this file was updated */
  updatedAt: Scalars['Time']['output'];
  /** The public URL to fetch the file, if the file is visible to the user */
  url?: Maybe<Scalars['String']['output']>;
  /** The visibility of the file, i.e. who can view this file */
  visibility: FileVisibility;
};

export type FileDetails = CertificateDetails;

/** File role describes a type of file related to an object */
export enum FileRole {
  /** The file is a form generated as part of an application */
  ApplicationForm = 'APPLICATION_FORM',
  /** The file is a binder, a pre-coverage confirmation document */
  Binder = 'BINDER',
  /** The file is a certificate of insurance */
  Certificate = 'CERTIFICATE',
  /** The file is a template to generate a certificate of liability insurance */
  CertificateOfLiabilityInsuranceTemplate = 'CERTIFICATE_OF_LIABILITY_INSURANCE_TEMPLATE',
  /** The file is an invoice, generally for internal tracking purposes */
  Invoice = 'INVOICE',
  /** The file is a policy document */
  PolicyDocument = 'POLICY_DOCUMENT',
  /** The file role is unknown */
  Unknown = 'UNKNOWN'
}

/** File visibility describes who is able to view a file */
export enum FileVisibility {
  /** Only internal users can view this file */
  Internal = 'INTERNAL',
  /** End users and internal users can view this file */
  User = 'USER'
}

export type GeneralApplication = {
  __typename?: 'GeneralApplication';
  ageOfBusinessInMonths: Scalars['Int64']['output'];
  annualPayroll: Scalars['Float']['output'];
  annualRevenue: Scalars['Float']['output'];
  businessStartDate: Scalars['Time']['output'];
  manufactureOrWholesaleOwnBrand: Scalars['Boolean']['output'];
  numEmployees: Scalars['Int64']['output'];
  numFullTimeEmployees: Scalars['Int64']['output'];
  numPartTimeEmployees: Scalars['Int64']['output'];
};

export type GeneralApplicationInput = {
  ageOfBusinessInMonths?: InputMaybe<Scalars['Int64']['input']>;
  annualPayroll?: InputMaybe<Scalars['Float']['input']>;
  annualRevenue?: InputMaybe<Scalars['Float']['input']>;
  businessStartDate?: InputMaybe<Scalars['Time']['input']>;
  manufactureOrWholesaleOwnBrand?: InputMaybe<Scalars['Boolean']['input']>;
  numEmployees?: InputMaybe<Scalars['Int64']['input']>;
  numFullTimeEmployees?: InputMaybe<Scalars['Int64']['input']>;
  numPartTimeEmployees?: InputMaybe<Scalars['Int64']['input']>;
};

export type GenerateCertificateInput = {
  additionalInsured: Scalars['Boolean']['input'];
  additionalInsuredRelationship?: InputMaybe<Scalars['String']['input']>;
  address: AddressInput;
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type GetBusinessApplication = {
  id?: InputMaybe<Scalars['ID']['input']>;
  merchantId?: InputMaybe<Scalars['ID']['input']>;
  policyId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsuranceCarrier = {
  __typename?: 'InsuranceCarrier';
  /** If available, the ID of this carrier in Ascend */
  ascendId?: Maybe<Scalars['String']['output']>;
  /** Human readable short identifier of this carrier */
  id: Scalars['String']['output'];
  /** True if this insurance entity is a broker which we use to access a carrier */
  isBroker: Scalars['Boolean']['output'];
  /** True if this insurance entity is a carrier with binding authority */
  isCarrier: Scalars['Boolean']['output'];
  /** Legal name of this carrier */
  name: Scalars['String']['output'];
  /** The types of insurance policies supported by this carrier */
  supportedPolicies?: Maybe<Array<InsuranceType>>;
};

/** Describes the market the insurance policy was written in. */
export enum InsuranceMarket {
  /** The admitted market */
  Admitted = 'ADMITTED',
  /** The Excess and Surplus (E&S) market */
  ExcessAndSurplus = 'EXCESS_AND_SURPLUS'
}

/** Insurance type for business insurance */
export enum InsuranceType {
  AccidentAndHealth = 'ACCIDENT_AND_HEALTH',
  BusinessOwners = 'BUSINESS_OWNERS',
  CommercialAuto = 'COMMERCIAL_AUTO',
  Cyber = 'CYBER',
  DirectorsAndOfficers = 'DIRECTORS_AND_OFFICERS',
  EmploymentPracticesLiability = 'EMPLOYMENT_PRACTICES_LIABILITY',
  ErrorsAndOmission = 'ERRORS_AND_OMISSION',
  GeneralLiability = 'GENERAL_LIABILITY',
  JewelersBlock = 'JEWELERS_BLOCK',
  Other = 'OTHER',
  Package = 'PACKAGE',
  ProductLiability = 'PRODUCT_LIABILITY',
  ProfessionalLiability = 'PROFESSIONAL_LIABILITY',
  Property = 'PROPERTY',
  Rental = 'RENTAL',
  ShippingAndTransportation = 'SHIPPING_AND_TRANSPORTATION',
  UmbrellaOrExcess = 'UMBRELLA_OR_EXCESS',
  WorkersCompensation = 'WORKERS_COMPENSATION'
}

export type InsuredInput = {
  addressCity: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressState: Scalars['String']['input'];
  addressZipCode: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

/** Limits represents limits component of an insurance */
export type Limits = {
  __typename?: 'Limits';
  /** bpp limit */
  bpp?: Maybe<Scalars['Float']['output']>;
  /** building limit */
  building?: Maybe<Scalars['Float']['output']>;
  /** gl limit */
  gl?: Maybe<Scalars['Float']['output']>;
  /** gl aggregate limit */
  glAggregate?: Maybe<Scalars['Float']['output']>;
  /** gl aggregate pco limit */
  glAggregatePco?: Maybe<Scalars['Float']['output']>;
};

/** Details of a bank transaction linked to a payout */
export type LinkedBankTransaction = {
  __typename?: 'LinkedBankTransaction';
  /** The amount of the transaction in dollars */
  amount: Scalars['Float']['output'];
  /** The ID of the counterparty in the bank account's system */
  counterPartyId: Scalars['String']['output'];
  /** The name of the counterparty */
  counterPartyName: Scalars['String']['output'];
  /** The date the transaction was created */
  createdAt: Scalars['Time']['output'];
  /** The link to the transaction in the partner bank's system */
  dashboardLink: Scalars['String']['output'];
  /** The bank description of the transaction */
  description: Scalars['String']['output'];
  /** The ID of the transaction in the bank account */
  id: Scalars['String']['output'];
  /** The memo line of the transaction */
  memo: Scalars['String']['output'];
  /** The status of the transaction in the bank account */
  status: AchPaymentStatus;
};

/** Location information for each business location */
export type Location = {
  __typename?: 'Location';
  address: Address;
  buildingValue: Scalars['Float']['output'];
  hasSprinklers: Scalars['Boolean']['output'];
  locationType: LocationType;
  personalPropertyValue: Scalars['Float']['output'];
  squareFootage: Scalars['Float']['output'];
};

export type LocationInput = {
  address?: InputMaybe<AddressInput>;
  buildingValue?: InputMaybe<Scalars['Float']['input']>;
  hasSprinklers?: InputMaybe<Scalars['Boolean']['input']>;
  locationType?: InputMaybe<LocationType>;
  personalPropertyValue?: InputMaybe<Scalars['Float']['input']>;
  squareFootage?: InputMaybe<Scalars['Float']['input']>;
};

/** Location type of business building */
export enum LocationType {
  /** The building is a home */
  Home = 'HOME',
  /** The building is leased */
  Leased = 'LEASED',
  /** The building is owned by the business */
  Owned = 'OWNED',
  /** The building type is unknown */
  Unknown = 'UNKNOWN'
}

/** Describes a merchant partner's account */
export type Merchant = {
  __typename?: 'Merchant';
  /** The business' physical address */
  address: Address;
  /** Any applications that this merchant has submitted */
  applications: Array<BusinessApplication>;
  /** The date this merchant was created */
  createdAt: Scalars['Time']['output'];
  /** The business' dba */
  dba: Scalars['String']['output'];
  /** The business' website domain */
  domain: Scalars['String']['output'];
  /** The business' email address */
  email: Scalars['String']['output'];
  /** Any files that are associated with this merchant */
  files: Array<File>;
  /** The ID of the merchant */
  id: Scalars['ID']['output'];
  /** The merchant's API keys */
  keys?: Maybe<Array<MerchantKey>>;
  /** The Mercury recipient ID for this merchant, used to determine the payout information for this merchant */
  mercuryRecipientId?: Maybe<Scalars['String']['output']>;
  /** The business' name */
  name: Scalars['String']['output'];
  /** The merchant's preferred payment method */
  paymentMethod: MerchantPaymentMethod;
  /** The business' contact phone number */
  phone: Scalars['String']['output'];
  /** List of product verticals this business sells */
  productVerticals: Array<ProductType>;
  /** The date this merchant was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The users that can access this merchant's account */
  users: Array<MerchantUser>;
};

/** A merchant's API key */
export type MerchantKey = {
  __typename?: 'MerchantKey';
  /** The API key the merchant uses to authenticate themselves via the API/SDK */
  apiKey: Scalars['ID']['output'];
  /** The date this API key was created */
  createdAt: Scalars['Time']['output'];
  /** The Merchant that this API key belongs to */
  merchant?: Maybe<Merchant>;
  /** The date this API key was updated */
  updatedAt: Scalars['Time']['output'];
};

/** The payment method */
export enum MerchantPaymentMethod {
  /** The merchant has opted to be paid out via physical check */
  Check = 'CHECK',
  /** The merchant has opted to be paid out via ACH and has connected a bank account */
  Electronic = 'ELECTRONIC',
  /** The merchant has not selected a payment method */
  Unknown = 'UNKNOWN'
}

/** A user that can log into the merchant's account */
export type MerchantUser = {
  __typename?: 'MerchantUser';
  /** The email address of the user */
  email: Scalars['String']['output'];
  /** The first name of the user */
  firstName: Scalars['String']['output'];
  /** The ID of the user */
  id: Scalars['ID']['output'];
  /** The last name of the user */
  lastName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Delete the document and remove the reference from the given business policy */
  businessPolicyDeleteDocument?: Maybe<BusinessPolicy>;
  /** Upload the document for this business policy to S3 */
  businessPolicyUploadDocument: File;
  cancelBusinessPolicy: BusinessPolicy;
  createBusinessApplication?: Maybe<BusinessApplication>;
  /** Create business insurance policies according to specification */
  createBusinessPolicies: Array<BusinessPolicy>;
  createMerchant?: Maybe<Merchant>;
  /** Generate a certificate of insurance with the given inputs for the current merchant */
  generateCertificate?: Maybe<File>;
  reinstateBusinessPolicy: BusinessPolicy;
  renewBusinessPolicy: BusinessPolicy;
  submitBusinessApplication?: Maybe<BusinessApplicationSubmissionResponse>;
  updateBusinessApplication?: Maybe<BusinessApplication>;
  /** Update the following business insurance policy with the specified changes */
  updateBusinessPolicy: BusinessPolicy;
};

export type MutationBusinessPolicyDeleteDocumentArgs = {
  fileId: Scalars['ID']['input'];
  policyId: Scalars['ID']['input'];
};

export type MutationBusinessPolicyUploadDocumentArgs = {
  policyId: Scalars['ID']['input'];
  req: CreateFileInput;
};

export type MutationCancelBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: CancelBusinessPolicyInput;
};

export type MutationCreateBusinessApplicationArgs = {
  input: CreateBusinessApplication;
};

export type MutationCreateBusinessPoliciesArgs = {
  req: CreateBusinessPoliciesInput;
};

export type MutationCreateMerchantArgs = {
  req?: InputMaybe<CreateMerchantInput>;
};

export type MutationGenerateCertificateArgs = {
  req: GenerateCertificateInput;
};

export type MutationReinstateBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: ReinstateBusinessPolicyInput;
};

export type MutationRenewBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: RenewBusinessPolicyInput;
};

export type MutationSubmitBusinessApplicationArgs = {
  input: SubmitBusinessApplicationInput;
};

export type MutationUpdateBusinessApplicationArgs = {
  input: UpdateBusinessApplication;
};

export type MutationUpdateBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: UpdateBusinessPolicyInput;
};

export type OperationApplication = {
  __typename?: 'OperationApplication';
  revenue: Scalars['Float']['output'];
};

export type OperationApplicationInput = {
  revenue?: InputMaybe<Scalars['Float']['input']>;
};

export enum OperationType {
  ManufacturingDesign = 'MANUFACTURING_DESIGN',
  Other = 'OTHER',
  Rental = 'RENTAL',
  Retail = 'RETAIL',
  Service = 'SERVICE',
  Wholesale = 'WHOLESALE'
}

export type OysterAgent = {
  __typename?: 'OysterAgent';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** PaymentIntervalType enumerates the interval of a fee occurrence */
export enum PaymentIntervalType {
  /** Describes an annual payment */
  Annual = 'ANNUAL',
  /** Describes a monthly payment */
  Monthly = 'MONTHLY',
  /** Describes a non-recurring payment */
  None = 'NONE',
  /** Describes a quarterly payment */
  Quarterly = 'QUARTERLY'
}

/** How the customer chose to pay */
export enum PaymentOptionType {
  AnnualPayInFull = 'ANNUAL_PAY_IN_FULL',
  MonthlyFinanced = 'MONTHLY_FINANCED'
}

export type PersonalizationInput = {
  businessInsurance?: InputMaybe<BusinessInsurancePersonalizationInput>;
};

/** The enumeration of states for business policy */
export enum PolicyState {
  Binding = 'BINDING',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Inforce = 'INFORCE',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Unknown = 'UNKNOWN'
}

/** The type of a single insured item on a policy */
export enum ProductType {
  BeautyAndSupplements = 'BEAUTY_AND_SUPPLEMENTS',
  /** A bike or eBike */
  Bike = 'BIKE',
  /** A collectible */
  Collectibles = 'COLLECTIBLES',
  /** An electronics item, such as a phone, laptop, or tablet */
  Electronics = 'ELECTRONICS',
  /** Fashion pieces */
  Fashion = 'FASHION',
  FoodAndBeverage = 'FOOD_AND_BEVERAGE',
  /** Furniture items */
  Furniture = 'FURNITURE',
  /** A jewelry item */
  Jewelry = 'JEWELRY',
  /** A motorcycle, moped, scooter, or other higher-powered motor vehicle */
  Motorcycle = 'MOTORCYCLE',
  /** An ATV, UTV, or other offroad vehicle */
  OffroadVehicle = 'OFFROAD_VEHICLE',
  /** Other producs */
  Other = 'OTHER',
  OutdoorSports = 'OUTDOOR_SPORTS',
  /** An unknown product type. This value should be impossible */
  Unknown = 'UNKNOWN'
}

export type PropertyCoverageApplication = {
  __typename?: 'PropertyCoverageApplication';
  bppDeductible: Scalars['Float']['output'];
  bppLimit: Scalars['Float']['output'];
  buildingDeductible: Scalars['Float']['output'];
  buildingLimit: Scalars['Float']['output'];
};

export type PropertyCoverageApplicationInput = {
  bppDeductible?: InputMaybe<Scalars['Float']['input']>;
  bppLimit?: InputMaybe<Scalars['Float']['input']>;
  buildingDeductible?: InputMaybe<Scalars['Float']['input']>;
  buildingLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type Query = {
  __typename?: 'Query';
  businessApplication: BusinessApplication;
  businessApplications: Array<BusinessApplication>;
  businessPolicies: Array<BusinessPolicy>;
  businessPolicy?: Maybe<BusinessPolicy>;
  carriers: Array<InsuranceCarrier>;
  certificate?: Maybe<File>;
  merchant?: Maybe<Merchant>;
  merchants: Array<Merchant>;
  quote: Array<BusinessQuote>;
  updateLink: Scalars['String']['output'];
};

export type QueryBusinessApplicationArgs = {
  input: GetBusinessApplication;
};

export type QueryBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCertificateArgs = {
  certificateNumber: Scalars['String']['input'];
};

export type QueryMerchantArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryQuoteArgs = {
  input: QuoteInput;
};

export type QueryUpdateLinkArgs = {
  id: Scalars['ID']['input'];
};

export type QuoteInput = {
  allowNewQuotes: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  stateIn?: InputMaybe<Array<BusinessQuoteState>>;
  type: QuoteInputType;
};

export enum QuoteInputType {
  ApplicationId = 'APPLICATION_ID',
  PolicyId = 'POLICY_ID',
  QuoteId = 'QUOTE_ID'
}

export type ReinstateBusinessPolicyInput = {
  effectiveAt: Scalars['Time']['input'];
  reasonDescription: Scalars['String']['input'];
};

export type RenewBusinessPolicyInput = {
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['input'];
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['input'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['input'];
  /** The estimated commission Oyster earns on this policy */
  estimatedCommission: Scalars['Float']['input'];
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['input'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['input'];
  policyNumber: Scalars['String']['input'];
  /** The actual premium of the policy */
  premium: Scalars['Float']['input'];
  quoteNumber: Scalars['String']['input'];
  state: PolicyState;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['input'];
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total: Scalars['Float']['input'];
};

export type RentalApplication = {
  __typename?: 'RentalApplication';
  rentalPercentGuided: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
};

export type RentalApplicationInput = {
  rentalPercentGuided?: InputMaybe<Scalars['Float']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

/** The Stripe invoice information used to bill a policy */
export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['String']['output'];
};

export type SubmitBusinessApplicationInput = {
  id: Scalars['ID']['input'];
  paymentMethodToken?: InputMaybe<Scalars['String']['input']>;
};

/** UpdateBusinessApplication is the input for updating the specified business application */
export type UpdateBusinessApplication = {
  desiredCoverageStartDate?: InputMaybe<Scalars['Time']['input']>;
  /** General application that all operations and coverages need */
  generalApplication?: InputMaybe<GeneralApplicationInput>;
  /** The business app ID */
  id: Scalars['ID']['input'];
  /** The coverages a business is applying for */
  insuranceTypes?: InputMaybe<Array<InsuranceType>>;
  /** Liabiity coverage application */
  liabilityCoverage?: InputMaybe<CoverageApplicationInput>;
  /** Locations where business operates out of */
  locations?: InputMaybe<Array<LocationInput>>;
  /** Manufacturing specific application */
  manufacturingUnderwriting?: InputMaybe<OperationApplicationInput>;
  /** Operation types of a business */
  operations?: InputMaybe<Array<OperationType>>;
  optedIntoOysterBundle?: InputMaybe<Scalars['Boolean']['input']>;
  /** Other specific application */
  otherUnderwriting?: InputMaybe<OperationApplicationInput>;
  /** The basic business profile */
  profile?: InputMaybe<BusinessProfileInput>;
  /** Property coverage application */
  propertyCoverage?: InputMaybe<PropertyCoverageApplicationInput>;
  /** Rental specific application */
  rentalUnderwriting?: InputMaybe<RentalApplicationInput>;
  /** Retail specific application */
  retailUnderwriting?: InputMaybe<OperationApplicationInput>;
  /** Service specific application */
  serviceUnderwriting?: InputMaybe<OperationApplicationInput>;
  /** Wholesale specific application */
  wholesaleUnderwriting?: InputMaybe<OperationApplicationInput>;
  /** Workers Comp coverage application */
  workersCompCoverage?: InputMaybe<CoverageApplicationInput>;
};

export type UpdateBusinessPolicyInput = {
  /** Additional fees collected by Oyster as revenue. */
  agencyFees?: InputMaybe<Scalars['Float']['input']>;
  /** Fees collected by the wholesale broker */
  brokerFees?: InputMaybe<Scalars['Float']['input']>;
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  estimatedCommission?: InputMaybe<Scalars['Float']['input']>;
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  market?: InputMaybe<InsuranceMarket>;
  minimumEarnedPremium?: InputMaybe<Scalars['Float']['input']>;
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees?: InputMaybe<Scalars['Float']['input']>;
  paymentIntervalType?: InputMaybe<PaymentIntervalType>;
  paymentOccurrencesPerInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Fees collected by the carrier to administer the policy or program */
  policyFees?: InputMaybe<Scalars['Float']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium?: InputMaybe<Scalars['Float']['input']>;
  quoteNumber?: InputMaybe<Scalars['String']['input']>;
  sourceAgentId?: InputMaybe<Scalars['String']['input']>;
  sourceLeadId?: InputMaybe<Scalars['String']['input']>;
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<BusinessPolicySourceType>;
  state?: InputMaybe<PolicyState>;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes?: InputMaybe<Scalars['Float']['input']>;
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<InsuranceType>;
  updateReason: Scalars['String']['input'];
};

export enum UpdateInitiatorType {
  Cronjob = 'CRONJOB',
  Oyster = 'OYSTER',
  User = 'USER',
  Workflow = 'WORKFLOW'
}

/** Describe a validation error for a field */
export type ValidationError = {
  __typename?: 'ValidationError';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subField?: Maybe<Scalars['String']['output']>;
};

export type GetCertificateQueryVariables = Exact<{
  certificateNumber: Scalars['String']['input'];
}>;

export type GetCertificateQuery = {
  __typename?: 'Query';
  certificate?:
    | {
        __typename?: 'File';
        url?: string | null | undefined;
        details?:
          | {
              __typename?: 'CertificateDetails';
              certificateNumber: string;
              descriptionOfOperations: string;
              insured: { __typename?: 'CertificateEntity'; name: string; address: string };
              producer: { __typename?: 'CertificateEntity'; name: string; address: string };
              certificateHolder: {
                __typename?: 'CertificateEntity';
                name: string;
                address: string;
              };
              coverages: Array<{
                __typename?: 'CertificateCoverage';
                type: InsuranceType;
                effectiveDate: string;
                expirationDate: string;
                policyNumber: string;
                holderIsAdditionalInsured: boolean;
                hasWaiverOfSubrogation: boolean;
                limits: Array<{ __typename?: 'CertificateLimit'; name: string; limit: number }>;
                insurer: { __typename?: 'CoverageInsurer'; name: string; naic: string };
              }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GenerateCertificateMutationVariables = Exact<{
  req: GenerateCertificateInput;
}>;

export type GenerateCertificateMutation = {
  __typename?: 'Mutation';
  generateCertificate?:
    | {
        __typename?: 'File';
        details?:
          | { __typename?: 'CertificateDetails'; certificateNumber: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMerchantQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantQuery = {
  __typename?: 'Query';
  merchant?:
    | {
        __typename?: 'Merchant';
        files: Array<{
          __typename?: 'File';
          id: string;
          name: string;
          role: FileRole;
          url?: string | null | undefined;
          createdAt: string;
          details?:
            | {
                __typename?: 'CertificateDetails';
                certificateNumber: string;
                descriptionOfOperations: string;
                certificateHolder: {
                  __typename?: 'CertificateEntity';
                  name: string;
                  address: string;
                };
                insured: { __typename?: 'CertificateEntity'; name: string; address: string };
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type AllBusinessApplicationQueryVariables = Exact<{
  input: GetBusinessApplication;
}>;

export type AllBusinessApplicationQuery = {
  __typename?: 'Query';
  businessApplication: {
    __typename?: 'BusinessApplication';
    id: string;
    state: BusinessApplicationState;
    operations: Array<OperationType>;
    insuranceTypes: Array<InsuranceType>;
    classCodes?: Array<string> | null | undefined;
    desiredCoverageStartDate: string;
    optedIntoOysterBundle: boolean;
    profile: {
      __typename?: 'BusinessProfile';
      name: string;
      dba: string;
      email: string;
      phone: string;
      domain: string;
      firstName: string;
      lastName: string;
      productVerticals: Array<ProductType>;
      address?:
        | {
            __typename?: 'Address';
            line1: string;
            line2?: string | null | undefined;
            city: string;
            zone: string;
            subZone?: string | null | undefined;
            postalCode: string;
          }
        | null
        | undefined;
    };
    locations: Array<{
      __typename?: 'Location';
      squareFootage: number;
      locationType: LocationType;
      hasSprinklers: boolean;
      buildingValue: number;
      personalPropertyValue: number;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        subZone?: string | null | undefined;
        postalCode: string;
      };
    }>;
    generalApplication?:
      | {
          __typename?: 'GeneralApplication';
          annualPayroll: number;
          annualRevenue: number;
          numEmployees: number;
          numFullTimeEmployees: number;
          numPartTimeEmployees: number;
          businessStartDate: string;
          manufactureOrWholesaleOwnBrand: boolean;
        }
      | null
      | undefined;
    rentalUnderwriting?:
      | { __typename?: 'RentalApplication'; revenue: number; rentalPercentGuided: number }
      | null
      | undefined;
    retailUnderwriting?:
      | { __typename?: 'OperationApplication'; revenue: number }
      | null
      | undefined;
    serviceUnderwriting?:
      | { __typename?: 'OperationApplication'; revenue: number }
      | null
      | undefined;
    wholesaleUnderwriting?:
      | { __typename?: 'OperationApplication'; revenue: number }
      | null
      | undefined;
    manufacturingUnderwriting?:
      | { __typename?: 'OperationApplication'; revenue: number }
      | null
      | undefined;
    otherUnderwriting?: { __typename?: 'OperationApplication'; revenue: number } | null | undefined;
    propertyCoverage?:
      | {
          __typename?: 'PropertyCoverageApplication';
          bppLimit: number;
          bppDeductible: number;
          buildingLimit: number;
          buildingDeductible: number;
        }
      | null
      | undefined;
    liabilityCoverage?:
      | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
      | null
      | undefined;
    workersCompCoverage?:
      | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
      | null
      | undefined;
    merchant?: { __typename?: 'Merchant'; id: string } | null | undefined;
    quotes: Array<{
      __typename?: 'BusinessQuote';
      id: string;
      type: InsuranceType;
      carrier: { __typename?: 'InsuranceCarrier'; id: string; name: string };
      details: {
        __typename?: 'BusinessQuoteDetails';
        proposalUrl?: string | null | undefined;
        pricing: {
          __typename?: 'BusinessQuotePricing';
          premium: number;
          total: number;
          bundled: number;
          intervalType: PaymentIntervalType;
          fees: Array<{
            __typename?: 'Fee';
            displayName: string;
            amount: number;
            intervalType: PaymentIntervalType;
          }>;
        };
        notices: Array<{
          __typename?: 'BusinessQuoteNotices';
          identifier?: string | null | undefined;
          title?: string | null | undefined;
          text?: string | null | undefined;
          requireAcceptance: boolean;
        }>;
      };
    }>;
    files: Array<{ __typename?: 'File'; id: string }>;
  };
};

export type PostCreateBusinessApplicationQueryVariables = Exact<{
  input: GetBusinessApplication;
}>;

export type PostCreateBusinessApplicationQuery = {
  __typename?: 'Query';
  businessApplication: {
    __typename?: 'BusinessApplication';
    id: string;
    profile: {
      __typename?: 'BusinessProfile';
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
};

export type RetrieveQuoteQueryVariables = Exact<{
  input: QuoteInput;
}>;

export type RetrieveQuoteQuery = {
  __typename?: 'Query';
  quote: Array<{
    __typename?: 'BusinessQuote';
    type: InsuranceType;
    carrier: { __typename?: 'InsuranceCarrier'; id: string; name: string };
    details: {
      __typename?: 'BusinessQuoteDetails';
      proposalUrl?: string | null | undefined;
      pricing: {
        __typename?: 'BusinessQuotePricing';
        premium: number;
        total: number;
        bundled: number;
        intervalType: PaymentIntervalType;
        fees: Array<{
          __typename?: 'Fee';
          displayName: string;
          amount: number;
          intervalType: PaymentIntervalType;
        }>;
      };
      notices: Array<{
        __typename?: 'BusinessQuoteNotices';
        identifier?: string | null | undefined;
        title?: string | null | undefined;
        text?: string | null | undefined;
        requireAcceptance: boolean;
      }>;
    };
  }>;
};

export type GetMerchantBusinessPoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantBusinessPoliciesQuery = {
  __typename?: 'Query';
  businessPolicies: Array<{
    __typename?: 'BusinessPolicy';
    id: string;
    type: InsuranceType;
    policyNumber: string;
    state: PolicyState;
    inforceAt?: string | null | undefined;
    expiresAt?: string | null | undefined;
    files: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      role: FileRole;
      url?: string | null | undefined;
    }>;
    carrier: { __typename?: 'InsuranceCarrier'; name: string };
    pricing: { __typename?: 'BusinessPolicyPricing'; premium: number; total: number };
  }>;
};

export type GetMerchantBusinessPolicyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetMerchantBusinessPolicyQuery = {
  __typename?: 'Query';
  businessPolicy?:
    | {
        __typename?: 'BusinessPolicy';
        id: string;
        type: InsuranceType;
        policyNumber: string;
        state: PolicyState;
        inforceAt?: string | null | undefined;
        expiresAt?: string | null | undefined;
        files: Array<{
          __typename?: 'File';
          id: string;
          name: string;
          role: FileRole;
          url?: string | null | undefined;
        }>;
        carrier: { __typename?: 'InsuranceCarrier'; name: string };
        pricing: {
          __typename?: 'BusinessPolicyPricing';
          billingType: BusinessPolicyBillingType;
          intervalType?: PaymentIntervalType | null | undefined;
          premium: number;
          total: number;
        };
      }
    | null
    | undefined;
};

export type CreateBusinessApplicationMutationVariables = Exact<{
  input: CreateBusinessApplication;
}>;

export type CreateBusinessApplicationMutation = {
  __typename?: 'Mutation';
  createBusinessApplication?:
    | {
        __typename?: 'BusinessApplication';
        id: string;
        validationError?:
          | {
              __typename?: 'ValidationError';
              field: string;
              subField?: string | null | undefined;
              message: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateBusinessApplicationMutationVariables = Exact<{
  input: UpdateBusinessApplication;
}>;

export type UpdateBusinessApplicationMutation = {
  __typename?: 'Mutation';
  updateBusinessApplication?:
    | {
        __typename?: 'BusinessApplication';
        id: string;
        state: BusinessApplicationState;
        operations: Array<OperationType>;
        insuranceTypes: Array<InsuranceType>;
        classCodes?: Array<string> | null | undefined;
        desiredCoverageStartDate: string;
        optedIntoOysterBundle: boolean;
        validationError?:
          | {
              __typename?: 'ValidationError';
              field: string;
              subField?: string | null | undefined;
              message: string;
            }
          | null
          | undefined;
        profile: {
          __typename?: 'BusinessProfile';
          name: string;
          dba: string;
          email: string;
          phone: string;
          domain: string;
          firstName: string;
          lastName: string;
          productVerticals: Array<ProductType>;
          address?:
            | {
                __typename?: 'Address';
                line1: string;
                line2?: string | null | undefined;
                city: string;
                zone: string;
                subZone?: string | null | undefined;
                postalCode: string;
              }
            | null
            | undefined;
        };
        locations: Array<{
          __typename?: 'Location';
          squareFootage: number;
          locationType: LocationType;
          hasSprinklers: boolean;
          buildingValue: number;
          personalPropertyValue: number;
          address: {
            __typename?: 'Address';
            line1: string;
            line2?: string | null | undefined;
            city: string;
            zone: string;
            subZone?: string | null | undefined;
            postalCode: string;
          };
        }>;
        generalApplication?:
          | {
              __typename?: 'GeneralApplication';
              annualPayroll: number;
              annualRevenue: number;
              numEmployees: number;
              numFullTimeEmployees: number;
              numPartTimeEmployees: number;
              businessStartDate: string;
              manufactureOrWholesaleOwnBrand: boolean;
            }
          | null
          | undefined;
        rentalUnderwriting?:
          | { __typename?: 'RentalApplication'; revenue: number; rentalPercentGuided: number }
          | null
          | undefined;
        retailUnderwriting?:
          | { __typename?: 'OperationApplication'; revenue: number }
          | null
          | undefined;
        serviceUnderwriting?:
          | { __typename?: 'OperationApplication'; revenue: number }
          | null
          | undefined;
        wholesaleUnderwriting?:
          | { __typename?: 'OperationApplication'; revenue: number }
          | null
          | undefined;
        manufacturingUnderwriting?:
          | { __typename?: 'OperationApplication'; revenue: number }
          | null
          | undefined;
        otherUnderwriting?:
          | { __typename?: 'OperationApplication'; revenue: number }
          | null
          | undefined;
        propertyCoverage?:
          | {
              __typename?: 'PropertyCoverageApplication';
              bppLimit: number;
              bppDeductible: number;
              buildingLimit: number;
              buildingDeductible: number;
            }
          | null
          | undefined;
        liabilityCoverage?:
          | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
          | null
          | undefined;
        workersCompCoverage?:
          | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
          | null
          | undefined;
        merchant?: { __typename?: 'Merchant'; id: string } | null | undefined;
        quotes: Array<{
          __typename?: 'BusinessQuote';
          id: string;
          type: InsuranceType;
          carrier: { __typename?: 'InsuranceCarrier'; id: string; name: string };
          details: {
            __typename?: 'BusinessQuoteDetails';
            proposalUrl?: string | null | undefined;
            pricing: {
              __typename?: 'BusinessQuotePricing';
              premium: number;
              total: number;
              bundled: number;
              intervalType: PaymentIntervalType;
              fees: Array<{
                __typename?: 'Fee';
                displayName: string;
                amount: number;
                intervalType: PaymentIntervalType;
              }>;
            };
            notices: Array<{
              __typename?: 'BusinessQuoteNotices';
              identifier?: string | null | undefined;
              title?: string | null | undefined;
              text?: string | null | undefined;
              requireAcceptance: boolean;
            }>;
          };
        }>;
        files: Array<{ __typename?: 'File'; id: string }>;
      }
    | null
    | undefined;
};

export type SubmitBusinessApplicationMutationVariables = Exact<{
  input: SubmitBusinessApplicationInput;
}>;

export type SubmitBusinessApplicationMutation = {
  __typename?: 'Mutation';
  submitBusinessApplication?:
    | {
        __typename?: 'BusinessApplicationSubmissionResponse';
        redirectionUrl?: string | null | undefined;
        application?:
          | {
              __typename?: 'BusinessApplication';
              id: string;
              state: BusinessApplicationState;
              operations: Array<OperationType>;
              insuranceTypes: Array<InsuranceType>;
              classCodes?: Array<string> | null | undefined;
              desiredCoverageStartDate: string;
              optedIntoOysterBundle: boolean;
              validationError?:
                | {
                    __typename?: 'ValidationError';
                    field: string;
                    subField?: string | null | undefined;
                    message: string;
                  }
                | null
                | undefined;
              profile: {
                __typename?: 'BusinessProfile';
                name: string;
                dba: string;
                email: string;
                phone: string;
                domain: string;
                firstName: string;
                lastName: string;
                productVerticals: Array<ProductType>;
                address?:
                  | {
                      __typename?: 'Address';
                      line1: string;
                      line2?: string | null | undefined;
                      city: string;
                      zone: string;
                      subZone?: string | null | undefined;
                      postalCode: string;
                    }
                  | null
                  | undefined;
              };
              locations: Array<{
                __typename?: 'Location';
                squareFootage: number;
                locationType: LocationType;
                hasSprinklers: boolean;
                buildingValue: number;
                personalPropertyValue: number;
                address: {
                  __typename?: 'Address';
                  line1: string;
                  line2?: string | null | undefined;
                  city: string;
                  zone: string;
                  subZone?: string | null | undefined;
                  postalCode: string;
                };
              }>;
              generalApplication?:
                | {
                    __typename?: 'GeneralApplication';
                    annualPayroll: number;
                    annualRevenue: number;
                    numEmployees: number;
                    numFullTimeEmployees: number;
                    numPartTimeEmployees: number;
                    businessStartDate: string;
                    manufactureOrWholesaleOwnBrand: boolean;
                  }
                | null
                | undefined;
              rentalUnderwriting?:
                | { __typename?: 'RentalApplication'; revenue: number; rentalPercentGuided: number }
                | null
                | undefined;
              retailUnderwriting?:
                | { __typename?: 'OperationApplication'; revenue: number }
                | null
                | undefined;
              serviceUnderwriting?:
                | { __typename?: 'OperationApplication'; revenue: number }
                | null
                | undefined;
              wholesaleUnderwriting?:
                | { __typename?: 'OperationApplication'; revenue: number }
                | null
                | undefined;
              manufacturingUnderwriting?:
                | { __typename?: 'OperationApplication'; revenue: number }
                | null
                | undefined;
              otherUnderwriting?:
                | { __typename?: 'OperationApplication'; revenue: number }
                | null
                | undefined;
              propertyCoverage?:
                | {
                    __typename?: 'PropertyCoverageApplication';
                    bppLimit: number;
                    bppDeductible: number;
                    buildingLimit: number;
                    buildingDeductible: number;
                  }
                | null
                | undefined;
              liabilityCoverage?:
                | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
                | null
                | undefined;
              workersCompCoverage?:
                | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
                | null
                | undefined;
              merchant?: { __typename?: 'Merchant'; id: string } | null | undefined;
              quotes: Array<{
                __typename?: 'BusinessQuote';
                id: string;
                type: InsuranceType;
                carrier: { __typename?: 'InsuranceCarrier'; id: string; name: string };
                details: {
                  __typename?: 'BusinessQuoteDetails';
                  proposalUrl?: string | null | undefined;
                  pricing: {
                    __typename?: 'BusinessQuotePricing';
                    premium: number;
                    total: number;
                    bundled: number;
                    intervalType: PaymentIntervalType;
                    fees: Array<{
                      __typename?: 'Fee';
                      displayName: string;
                      amount: number;
                      intervalType: PaymentIntervalType;
                    }>;
                  };
                  notices: Array<{
                    __typename?: 'BusinessQuoteNotices';
                    identifier?: string | null | undefined;
                    title?: string | null | undefined;
                    text?: string | null | undefined;
                    requireAcceptance: boolean;
                  }>;
                };
              }>;
              files: Array<{ __typename?: 'File'; id: string }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BusinessApplicationFieldsFragment = {
  __typename?: 'BusinessApplication';
  id: string;
  state: BusinessApplicationState;
  operations: Array<OperationType>;
  insuranceTypes: Array<InsuranceType>;
  classCodes?: Array<string> | null | undefined;
  desiredCoverageStartDate: string;
  optedIntoOysterBundle: boolean;
  profile: {
    __typename?: 'BusinessProfile';
    name: string;
    dba: string;
    email: string;
    phone: string;
    domain: string;
    firstName: string;
    lastName: string;
    productVerticals: Array<ProductType>;
    address?:
      | {
          __typename?: 'Address';
          line1: string;
          line2?: string | null | undefined;
          city: string;
          zone: string;
          subZone?: string | null | undefined;
          postalCode: string;
        }
      | null
      | undefined;
  };
  locations: Array<{
    __typename?: 'Location';
    squareFootage: number;
    locationType: LocationType;
    hasSprinklers: boolean;
    buildingValue: number;
    personalPropertyValue: number;
    address: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      subZone?: string | null | undefined;
      postalCode: string;
    };
  }>;
  generalApplication?:
    | {
        __typename?: 'GeneralApplication';
        annualPayroll: number;
        annualRevenue: number;
        numEmployees: number;
        numFullTimeEmployees: number;
        numPartTimeEmployees: number;
        businessStartDate: string;
        manufactureOrWholesaleOwnBrand: boolean;
      }
    | null
    | undefined;
  rentalUnderwriting?:
    | { __typename?: 'RentalApplication'; revenue: number; rentalPercentGuided: number }
    | null
    | undefined;
  retailUnderwriting?: { __typename?: 'OperationApplication'; revenue: number } | null | undefined;
  serviceUnderwriting?: { __typename?: 'OperationApplication'; revenue: number } | null | undefined;
  wholesaleUnderwriting?:
    | { __typename?: 'OperationApplication'; revenue: number }
    | null
    | undefined;
  manufacturingUnderwriting?:
    | { __typename?: 'OperationApplication'; revenue: number }
    | null
    | undefined;
  otherUnderwriting?: { __typename?: 'OperationApplication'; revenue: number } | null | undefined;
  propertyCoverage?:
    | {
        __typename?: 'PropertyCoverageApplication';
        bppLimit: number;
        bppDeductible: number;
        buildingLimit: number;
        buildingDeductible: number;
      }
    | null
    | undefined;
  liabilityCoverage?:
    | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
    | null
    | undefined;
  workersCompCoverage?:
    | { __typename?: 'CoverageApplication'; limit: number; deductible: number }
    | null
    | undefined;
  merchant?: { __typename?: 'Merchant'; id: string } | null | undefined;
  quotes: Array<{
    __typename?: 'BusinessQuote';
    id: string;
    type: InsuranceType;
    carrier: { __typename?: 'InsuranceCarrier'; id: string; name: string };
    details: {
      __typename?: 'BusinessQuoteDetails';
      proposalUrl?: string | null | undefined;
      pricing: {
        __typename?: 'BusinessQuotePricing';
        premium: number;
        total: number;
        bundled: number;
        intervalType: PaymentIntervalType;
        fees: Array<{
          __typename?: 'Fee';
          displayName: string;
          amount: number;
          intervalType: PaymentIntervalType;
        }>;
      };
      notices: Array<{
        __typename?: 'BusinessQuoteNotices';
        identifier?: string | null | undefined;
        title?: string | null | undefined;
        text?: string | null | undefined;
        requireAcceptance: boolean;
      }>;
    };
  }>;
  files: Array<{ __typename?: 'File'; id: string }>;
};

export const BusinessApplicationFieldsFragmentDoc = gql`
  fragment BusinessApplicationFields on BusinessApplication {
    id
    state
    profile {
      name
      dba
      email
      phone
      domain
      firstName
      lastName
      productVerticals
      address {
        line1
        line2
        city
        zone
        subZone
        postalCode
      }
    }
    locations {
      squareFootage
      locationType
      hasSprinklers
      buildingValue
      personalPropertyValue
      address {
        line1
        line2
        city
        zone
        subZone
        postalCode
      }
    }
    operations
    insuranceTypes
    classCodes
    generalApplication {
      annualPayroll
      annualRevenue
      numEmployees
      numFullTimeEmployees
      numPartTimeEmployees
      businessStartDate
      manufactureOrWholesaleOwnBrand
    }
    rentalUnderwriting {
      revenue
      rentalPercentGuided
    }
    retailUnderwriting {
      revenue
    }
    serviceUnderwriting {
      revenue
    }
    wholesaleUnderwriting {
      revenue
    }
    manufacturingUnderwriting {
      revenue
    }
    otherUnderwriting {
      revenue
    }
    propertyCoverage {
      bppLimit
      bppDeductible
      buildingLimit
      buildingDeductible
    }
    liabilityCoverage {
      limit
      deductible
    }
    workersCompCoverage {
      limit
      deductible
    }
    merchant {
      id
    }
    quotes {
      id
      carrier {
        id
        name
      }
      type
      details {
        proposalUrl
        pricing {
          premium
          total
          bundled
          intervalType
          fees {
            displayName
            amount
            intervalType
          }
        }
        notices {
          identifier
          title
          text
          requireAcceptance
        }
      }
    }
    files {
      id
    }
    desiredCoverageStartDate
    optedIntoOysterBundle
  }
`;
export const GetCertificateDocument = gql`
  query GetCertificate($certificateNumber: String!) {
    certificate(certificateNumber: $certificateNumber) {
      url
      details {
        ... on CertificateDetails {
          certificateNumber
          insured {
            name
            address
          }
          producer {
            name
            address
          }
          certificateHolder {
            name
            address
          }
          descriptionOfOperations
          coverages {
            type
            effectiveDate
            expirationDate
            policyNumber
            holderIsAdditionalInsured
            hasWaiverOfSubrogation
            limits {
              name
              limit
            }
            insurer {
              name
              naic
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCertificateQuery__
 *
 * To run a query within a React component, call `useGetCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateQuery({
 *   variables: {
 *      certificateNumber: // value for 'certificateNumber'
 *   },
 * });
 */
export function useGetCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables> &
    ({ variables: GetCertificateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options
  );
}
export function useGetCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options
  );
}
export function useGetCertificateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options
  );
}
export type GetCertificateQueryHookResult = ReturnType<typeof useGetCertificateQuery>;
export type GetCertificateLazyQueryHookResult = ReturnType<typeof useGetCertificateLazyQuery>;
export type GetCertificateSuspenseQueryHookResult = ReturnType<
  typeof useGetCertificateSuspenseQuery
>;
export type GetCertificateQueryResult = Apollo.QueryResult<
  GetCertificateQuery,
  GetCertificateQueryVariables
>;
export const GenerateCertificateDocument = gql`
  mutation GenerateCertificate($req: GenerateCertificateInput!) {
    generateCertificate(req: $req) {
      details {
        ... on CertificateDetails {
          certificateNumber
        }
      }
    }
  }
`;
export type GenerateCertificateMutationFn = Apollo.MutationFunction<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;

/**
 * __useGenerateCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCertificateMutation, { data, loading, error }] = useGenerateCertificateMutation({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useGenerateCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCertificateMutation,
    GenerateCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateCertificateMutation, GenerateCertificateMutationVariables>(
    GenerateCertificateDocument,
    options
  );
}
export type GenerateCertificateMutationHookResult = ReturnType<
  typeof useGenerateCertificateMutation
>;
export type GenerateCertificateMutationResult = Apollo.MutationResult<GenerateCertificateMutation>;
export type GenerateCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;
export const GetMerchantDocument = gql`
  query GetMerchant {
    merchant {
      files {
        id
        name
        role
        url
        createdAt
        details {
          ... on CertificateDetails {
            certificateNumber
            certificateHolder {
              name
              address
            }
            insured {
              name
              address
            }
            descriptionOfOperations
          }
        }
      }
    }
  }
`;

/**
 * __useGetMerchantQuery__
 *
 * To run a query within a React component, call `useGetMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMerchantQuery, GetMerchantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMerchantQuery, GetMerchantQueryVariables>(GetMerchantDocument, options);
}
export function useGetMerchantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantQuery, GetMerchantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMerchantQuery, GetMerchantQueryVariables>(
    GetMerchantDocument,
    options
  );
}
export function useGetMerchantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMerchantQuery, GetMerchantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMerchantQuery, GetMerchantQueryVariables>(
    GetMerchantDocument,
    options
  );
}
export type GetMerchantQueryHookResult = ReturnType<typeof useGetMerchantQuery>;
export type GetMerchantLazyQueryHookResult = ReturnType<typeof useGetMerchantLazyQuery>;
export type GetMerchantSuspenseQueryHookResult = ReturnType<typeof useGetMerchantSuspenseQuery>;
export type GetMerchantQueryResult = Apollo.QueryResult<
  GetMerchantQuery,
  GetMerchantQueryVariables
>;
export const AllBusinessApplicationDocument = gql`
  query AllBusinessApplication($input: GetBusinessApplication!) {
    businessApplication(input: $input) {
      ...BusinessApplicationFields
    }
  }
  ${BusinessApplicationFieldsFragmentDoc}
`;

/**
 * __useAllBusinessApplicationQuery__
 *
 * To run a query within a React component, call `useAllBusinessApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBusinessApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBusinessApplicationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllBusinessApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllBusinessApplicationQuery,
    AllBusinessApplicationQueryVariables
  > &
    ({ variables: AllBusinessApplicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllBusinessApplicationQuery, AllBusinessApplicationQueryVariables>(
    AllBusinessApplicationDocument,
    options
  );
}
export function useAllBusinessApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllBusinessApplicationQuery,
    AllBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllBusinessApplicationQuery, AllBusinessApplicationQueryVariables>(
    AllBusinessApplicationDocument,
    options
  );
}
export function useAllBusinessApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllBusinessApplicationQuery,
    AllBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllBusinessApplicationQuery, AllBusinessApplicationQueryVariables>(
    AllBusinessApplicationDocument,
    options
  );
}
export type AllBusinessApplicationQueryHookResult = ReturnType<
  typeof useAllBusinessApplicationQuery
>;
export type AllBusinessApplicationLazyQueryHookResult = ReturnType<
  typeof useAllBusinessApplicationLazyQuery
>;
export type AllBusinessApplicationSuspenseQueryHookResult = ReturnType<
  typeof useAllBusinessApplicationSuspenseQuery
>;
export type AllBusinessApplicationQueryResult = Apollo.QueryResult<
  AllBusinessApplicationQuery,
  AllBusinessApplicationQueryVariables
>;
export const PostCreateBusinessApplicationDocument = gql`
  query PostCreateBusinessApplication($input: GetBusinessApplication!) {
    businessApplication(input: $input) {
      id
      profile {
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

/**
 * __usePostCreateBusinessApplicationQuery__
 *
 * To run a query within a React component, call `usePostCreateBusinessApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCreateBusinessApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCreateBusinessApplicationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCreateBusinessApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostCreateBusinessApplicationQuery,
    PostCreateBusinessApplicationQueryVariables
  > &
    ({ variables: PostCreateBusinessApplicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PostCreateBusinessApplicationQuery,
    PostCreateBusinessApplicationQueryVariables
  >(PostCreateBusinessApplicationDocument, options);
}
export function usePostCreateBusinessApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostCreateBusinessApplicationQuery,
    PostCreateBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostCreateBusinessApplicationQuery,
    PostCreateBusinessApplicationQueryVariables
  >(PostCreateBusinessApplicationDocument, options);
}
export function usePostCreateBusinessApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PostCreateBusinessApplicationQuery,
    PostCreateBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PostCreateBusinessApplicationQuery,
    PostCreateBusinessApplicationQueryVariables
  >(PostCreateBusinessApplicationDocument, options);
}
export type PostCreateBusinessApplicationQueryHookResult = ReturnType<
  typeof usePostCreateBusinessApplicationQuery
>;
export type PostCreateBusinessApplicationLazyQueryHookResult = ReturnType<
  typeof usePostCreateBusinessApplicationLazyQuery
>;
export type PostCreateBusinessApplicationSuspenseQueryHookResult = ReturnType<
  typeof usePostCreateBusinessApplicationSuspenseQuery
>;
export type PostCreateBusinessApplicationQueryResult = Apollo.QueryResult<
  PostCreateBusinessApplicationQuery,
  PostCreateBusinessApplicationQueryVariables
>;
export const RetrieveQuoteDocument = gql`
  query RetrieveQuote($input: QuoteInput!) {
    quote(input: $input) {
      carrier {
        id
        name
      }
      type
      details {
        proposalUrl
        pricing {
          premium
          total
          bundled
          intervalType
          fees {
            displayName
            amount
            intervalType
          }
        }
        notices {
          identifier
          title
          text
          requireAcceptance
        }
      }
    }
  }
`;

/**
 * __useRetrieveQuoteQuery__
 *
 * To run a query within a React component, call `useRetrieveQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveQuoteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetrieveQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveQuoteQuery, RetrieveQuoteQueryVariables> &
    ({ variables: RetrieveQuoteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveQuoteQuery, RetrieveQuoteQueryVariables>(
    RetrieveQuoteDocument,
    options
  );
}
export function useRetrieveQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetrieveQuoteQuery, RetrieveQuoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveQuoteQuery, RetrieveQuoteQueryVariables>(
    RetrieveQuoteDocument,
    options
  );
}
export function useRetrieveQuoteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RetrieveQuoteQuery, RetrieveQuoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RetrieveQuoteQuery, RetrieveQuoteQueryVariables>(
    RetrieveQuoteDocument,
    options
  );
}
export type RetrieveQuoteQueryHookResult = ReturnType<typeof useRetrieveQuoteQuery>;
export type RetrieveQuoteLazyQueryHookResult = ReturnType<typeof useRetrieveQuoteLazyQuery>;
export type RetrieveQuoteSuspenseQueryHookResult = ReturnType<typeof useRetrieveQuoteSuspenseQuery>;
export type RetrieveQuoteQueryResult = Apollo.QueryResult<
  RetrieveQuoteQuery,
  RetrieveQuoteQueryVariables
>;
export const GetMerchantBusinessPoliciesDocument = gql`
  query GetMerchantBusinessPolicies {
    businessPolicies {
      id
      type
      files {
        id
        name
        role
        url
      }
      policyNumber
      state
      inforceAt
      expiresAt
      carrier {
        name
      }
      pricing {
        premium
        total
      }
    }
  }
`;

/**
 * __useGetMerchantBusinessPoliciesQuery__
 *
 * To run a query within a React component, call `useGetMerchantBusinessPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantBusinessPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantBusinessPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantBusinessPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >(GetMerchantBusinessPoliciesDocument, options);
}
export function useGetMerchantBusinessPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >(GetMerchantBusinessPoliciesDocument, options);
}
export function useGetMerchantBusinessPoliciesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >(GetMerchantBusinessPoliciesDocument, options);
}
export type GetMerchantBusinessPoliciesQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPoliciesQuery
>;
export type GetMerchantBusinessPoliciesLazyQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPoliciesLazyQuery
>;
export type GetMerchantBusinessPoliciesSuspenseQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPoliciesSuspenseQuery
>;
export type GetMerchantBusinessPoliciesQueryResult = Apollo.QueryResult<
  GetMerchantBusinessPoliciesQuery,
  GetMerchantBusinessPoliciesQueryVariables
>;
export const GetMerchantBusinessPolicyDocument = gql`
  query GetMerchantBusinessPolicy($id: ID!) {
    businessPolicy(id: $id) {
      id
      type
      files {
        id
        name
        role
        url
      }
      policyNumber
      state
      inforceAt
      expiresAt
      carrier {
        name
      }
      pricing {
        billingType
        intervalType
        premium
        total
      }
    }
  }
`;

/**
 * __useGetMerchantBusinessPolicyQuery__
 *
 * To run a query within a React component, call `useGetMerchantBusinessPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantBusinessPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantBusinessPolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMerchantBusinessPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  > &
    ({ variables: GetMerchantBusinessPolicyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMerchantBusinessPolicyQuery, GetMerchantBusinessPolicyQueryVariables>(
    GetMerchantBusinessPolicyDocument,
    options
  );
}
export function useGetMerchantBusinessPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >(GetMerchantBusinessPolicyDocument, options);
}
export function useGetMerchantBusinessPolicySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >(GetMerchantBusinessPolicyDocument, options);
}
export type GetMerchantBusinessPolicyQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPolicyQuery
>;
export type GetMerchantBusinessPolicyLazyQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPolicyLazyQuery
>;
export type GetMerchantBusinessPolicySuspenseQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPolicySuspenseQuery
>;
export type GetMerchantBusinessPolicyQueryResult = Apollo.QueryResult<
  GetMerchantBusinessPolicyQuery,
  GetMerchantBusinessPolicyQueryVariables
>;
export const CreateBusinessApplicationDocument = gql`
  mutation CreateBusinessApplication($input: CreateBusinessApplication!) {
    createBusinessApplication(input: $input) {
      id
      validationError {
        field
        subField
        message
      }
    }
  }
`;
export type CreateBusinessApplicationMutationFn = Apollo.MutationFunction<
  CreateBusinessApplicationMutation,
  CreateBusinessApplicationMutationVariables
>;

/**
 * __useCreateBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessApplicationMutation, { data, loading, error }] = useCreateBusinessApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessApplicationMutation,
    CreateBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBusinessApplicationMutation,
    CreateBusinessApplicationMutationVariables
  >(CreateBusinessApplicationDocument, options);
}
export type CreateBusinessApplicationMutationHookResult = ReturnType<
  typeof useCreateBusinessApplicationMutation
>;
export type CreateBusinessApplicationMutationResult =
  Apollo.MutationResult<CreateBusinessApplicationMutation>;
export type CreateBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessApplicationMutation,
  CreateBusinessApplicationMutationVariables
>;
export const UpdateBusinessApplicationDocument = gql`
  mutation UpdateBusinessApplication($input: UpdateBusinessApplication!) {
    updateBusinessApplication(input: $input) {
      ...BusinessApplicationFields
      validationError {
        field
        subField
        message
      }
    }
  }
  ${BusinessApplicationFieldsFragmentDoc}
`;
export type UpdateBusinessApplicationMutationFn = Apollo.MutationFunction<
  UpdateBusinessApplicationMutation,
  UpdateBusinessApplicationMutationVariables
>;

/**
 * __useUpdateBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessApplicationMutation, { data, loading, error }] = useUpdateBusinessApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessApplicationMutation,
    UpdateBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBusinessApplicationMutation,
    UpdateBusinessApplicationMutationVariables
  >(UpdateBusinessApplicationDocument, options);
}
export type UpdateBusinessApplicationMutationHookResult = ReturnType<
  typeof useUpdateBusinessApplicationMutation
>;
export type UpdateBusinessApplicationMutationResult =
  Apollo.MutationResult<UpdateBusinessApplicationMutation>;
export type UpdateBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessApplicationMutation,
  UpdateBusinessApplicationMutationVariables
>;
export const SubmitBusinessApplicationDocument = gql`
  mutation SubmitBusinessApplication($input: SubmitBusinessApplicationInput!) {
    submitBusinessApplication(input: $input) {
      application {
        ...BusinessApplicationFields
        validationError {
          field
          subField
          message
        }
      }
      redirectionUrl
    }
  }
  ${BusinessApplicationFieldsFragmentDoc}
`;
export type SubmitBusinessApplicationMutationFn = Apollo.MutationFunction<
  SubmitBusinessApplicationMutation,
  SubmitBusinessApplicationMutationVariables
>;

/**
 * __useSubmitBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessApplicationMutation, { data, loading, error }] = useSubmitBusinessApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitBusinessApplicationMutation,
    SubmitBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitBusinessApplicationMutation,
    SubmitBusinessApplicationMutationVariables
  >(SubmitBusinessApplicationDocument, options);
}
export type SubmitBusinessApplicationMutationHookResult = ReturnType<
  typeof useSubmitBusinessApplicationMutation
>;
export type SubmitBusinessApplicationMutationResult =
  Apollo.MutationResult<SubmitBusinessApplicationMutation>;
export type SubmitBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitBusinessApplicationMutation,
  SubmitBusinessApplicationMutationVariables
>;
