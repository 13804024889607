import * as React from 'react';
import styled from 'styled-components';
import { Merchant, MerchantUser, ValidationError } from '@oysterjs/types';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { IoPersonAdd, IoPersonOutline, IoArrowForward } from 'react-icons/io5';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { FormColumn, FormColumnStyle, FormContainer, FormRow } from '@oysterjs/ui/Form/builder';
import { ErrorDisplay, TextInput } from '@oysterjs/ui/Form/text';
import { createMerchantUser, getMerchantUsers } from '@oysterjs/core/api/merchant';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';

const UserListContainer = styled.ul`
  padding: 0px 0px 24px 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserContainer = styled.li`
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 8px;
`;

const UserIconContainer = styled.div`
  width: 30px;
  font-size: 1.3em;
  color: #333333;
  padding: 2px 0px;
  display: flex;
`;

const UserDetailsContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UserName = styled.div`
  font-weight: 500;
`;

const UserEmail = styled.div`
  font-size: 0.9em;
  color: #666666;
`;

const UserList = (props: { users: MerchantUser[] }) => (
  <UserListContainer>
    {props.users.map((user) => (
      <UserContainer key={user.Email}>
        <UserIconContainer>
          <IoPersonOutline />
        </UserIconContainer>
        <UserDetailsContainer>
          <UserName>
            {user.FirstName} {user.LastName}
          </UserName>
          <UserEmail>{user.Email}</UserEmail>
        </UserDetailsContainer>
      </UserContainer>
    ))}
  </UserListContainer>
);

const AddUserFormContainer = styled.div`
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  padding: 24px;
  max-width: 360px;
`;

const AddUserForm = (props: {
  onAddUser: (users: MerchantUser[]) => void;
  onClose: () => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [formData, setFormData] = React.useState({
    FirstName: '',
    LastName: '',
    Email: ''
  });
  const [error, setError] = React.useState('');

  const handleAddUser = async () => {
    setLoading(true);
    try {
      await createMerchantUser(formData);
      props.onAddUser((await getMerchantUsers()).Users);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      if (err.type() === ErrorType.validationError) {
        setError('');
        setValidationError(err.getValidationError());
      } else {
        setValidationError(undefined);
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AddUserFormContainer>
      <FormContainer>
        <FormRow breakMobile>
          <FormColumn
            title="First Name"
            description="The user's preferred first name."
            formColumnStyle={FormColumnStyle.merchant}
          >
            <TextInput
              type="text"
              name="first-name"
              error={validationError?.Field === 'FirstName' && validationError?.Message}
              value={formData.FirstName}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setFormData((prev) => ({ ...prev, FirstName: value }));
              }}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn
            title="Last Name"
            description="The user's preferred last name."
            formColumnStyle={FormColumnStyle.merchant}
          >
            <TextInput
              type="text"
              name="last-name"
              error={validationError?.Field === 'LastName' && validationError?.Message}
              value={formData.LastName}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setFormData((prev) => ({ ...prev, LastName: value }));
              }}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn
            title="Email Address"
            description="The email address the user will use to log in."
            formColumnStyle={FormColumnStyle.merchant}
          >
            <TextInput
              type="email"
              name="email"
              error={validationError?.Field === 'Email' && validationError?.Message}
              value={formData.Email}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setFormData((prev) => ({ ...prev, Email: value }));
              }}
              inputMode="email"
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <ButtonContainer>
            <Button loading={loading} onClick={() => props.onClose()}>
              Cancel
            </Button>
            <Button
              primary
              icon={<IoArrowForward />}
              loading={loading}
              onClick={() => handleAddUser()}
            >
              Add User
            </Button>
          </ButtonContainer>
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </FormRow>
      </FormContainer>
    </AddUserFormContainer>
  );
};

export const TeamPage = (props: { merchant: Merchant; merchantUsers: MerchantUser[] }) => {
  const [users, setUsers] = React.useState(props.merchantUsers);
  const [showAddUser, setShowAddUser] = React.useState(false);

  return (
    <>
      <PageTitle title="Team" description="Add other team members to access your account." />
      <UserList users={[...users]} />
      {!showAddUser && (
        <Button primary leftIcon={<IoPersonAdd />} onClick={() => setShowAddUser(true)}>
          Add Team Member
        </Button>
      )}
      {showAddUser && (
        <AddUserForm
          onClose={() => setShowAddUser(false)}
          onAddUser={(users) => {
            setUsers(users);
            setShowAddUser(false);
          }}
        />
      )}
    </>
  );
};
