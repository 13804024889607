import * as React from 'react';

import { Button } from '@oysterjs/ui/Button';
import {
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantRentalConfiguration,
  RentalPolicyState
} from '@oysterjs/types';
import { ErrorDetail, ErrorDetails } from '@oysterjs/ui/ErrorBoundary';
import config from '@oysterjs/core/config';
import { getMerchantRentalConfiguration, requestCheckfront } from '@oysterjs/core/api/merchant';
import { Spinner } from '@oysterjs/ui/Spinner';
import { useHistory } from 'react-router';

export const CheckfrontSetup = (props: {
  integration?: MerchantIntegration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
  publicHandle: string;
}) => {
  const history = useHistory();
  const [integration, setIntegration] = React.useState(props.integration);
  const [loading, setLoading] = React.useState(false);
  const [configuration, setConfiguration] = React.useState<MerchantRentalConfiguration>();

  const onFetchConfigurationData = async () => {
    setLoading(true);
    try {
      const config = await getMerchantRentalConfiguration();
      setConfiguration(config.Configuration);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onFetchConfigurationData();
  }, []);

  const onSetup = async () => {
    if (integration) {
      return;
    }

    setLoading(true);
    try {
      const checkfrontIntegration = await requestCheckfront();
      setIntegration(checkfrontIntegration);
    } finally {
      setLoading(false);
    }
  };

  const onClickRentalApp = () => {
    history.push('/apps/rental');
  };

  const notificationURL = `${config().serviceBaseUrl.webhooks}/checkfront/${props.publicHandle}`;

  return (
    <>
      {loading && <Spinner color="#333333" />}
      {!loading &&
        (!configuration || configuration?.Details.State !== RentalPolicyState.approved) && (
          <div>
            <h3>Prerequisite</h3>
            <p>
              Before you can integrate with Checkfront, please finish setting up your rental
              configuration. You can do so by going to the Apps tab and installing the Rental App.
              You can also click the button below to link you there.
            </p>
            <Button
              loading={loading}
              primary
              onClick={onClickRentalApp}
              style={{ marginBottom: '30px' }}
            >
              Setup Rental Configuration
            </Button>
          </div>
        )}
      {!loading &&
        !integration &&
        configuration &&
        configuration?.Details.State === RentalPolicyState.approved && (
          <div>
            <p>
              In just a few minutes, add an integration that offers Oyster rental insurance to your
              customers on you Checkfront account.
            </p>
            <Button loading={loading} primary onClick={onSetup} style={{ marginBottom: '30px' }}>
              Setup Oyster Checkfront Integration
            </Button>
          </div>
        )}
      {!loading &&
        integration &&
        integration.Status != MerchantIntegrationStatus.active &&
        configuration &&
        configuration?.Details.State === RentalPolicyState.approved && (
          <>
            <p>
              To start offering insurance for bookings in your Checkfront store, you need to set up
              a webhook in your Checkfront Account with Oyster.
            </p>
            <p>
              Do so by going to <b>Manage &gt; Developer &gt; Webhooks</b>. Click{' '}
              <b>+ Add Webhook</b> and enter the following values:
            </p>
            <ErrorDetails errorData={notificationURL} style={{ maxWidth: '100%' }}>
              <ErrorDetail title="Notification URL" description={notificationURL} />
            </ErrorDetails>
            <p>
              <img style={{ width: '100%' }} src="/images/checkfront-instruction-1.png" />
            </p>
          </>
        )}
      {!loading &&
        integration &&
        integration.Status == MerchantIntegrationStatus.active &&
        configuration &&
        configuration?.Details.State === RentalPolicyState.approved && (
          <p>
            Your Checkfront account is all set up! Please contact{' '}
            <a target="_blank" href={'mailto:partners@withoyster.com'}>
              partners@withoyster.com
            </a>{' '}
            if you would like to remove this integration.
          </p>
        )}
    </>
  );
};
