import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 24px 0px 24px 0px;

  tr td,
  tr th {
    padding: 16px 16px;
    vertical-align: top;
    vertical-align: middle;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      text-align: right;
      vertical-align: middle;
    }
  }

  thead tr td,
  thead tr th {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 0.8em;
    color: #999999;
    border-bottom: 2px solid #f2f2f2;
    text-transform: uppercase;

    &:first-child {
      /* padding-left: 0px; */
    }

    &:last-child {
      /* padding-right: 0px; */
      text-align: right;
    }
  }

  tbody tr td {
    padding: 16px 16px;
  }

  tbody tr:hover {
    background: #f8f8f8;
    cursor: pointer;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0px 12px 0px;
  gap: 12px;
`;

export const Action = styled.button`
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  padding: 8px 16px;
  border-radius: 30px;
  user-select: none;

  font-size: 0.7em;
  font-weight: 500;

  justify-content: center;

  color: #333333;
  background: #eaeaea;
  font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  svg {
    font-size: 1.2em;
  }

  &:hover {
    background: #e6e6e6;
  }

  &:active {
    background: #cccccc;
  }
`;
