import styled from 'styled-components';

export const PageBackground = styled.div<{ merchantBrandColor?: string }>`
  background: linear-gradient(
    90deg,
    ${(props) => props.merchantBrandColor || '#0f172a'} 50%,
    white 50%
  );
  min-height: 100%;
  width: 100%;
  position: absolute;

  @media (max-width: 700px) {
    background: none;
  }
`;

export const LogoContainer = styled.div<{ merchantBrandColor?: string }>`
  max-width: 50%;

  @media (max-width: 700px) {
    max-width: 100%;
    background: ${(props) => props.merchantBrandColor || '#0f172a'};
    transition: 0.15s ease-in-out background;
  }
`;

export const LeftPaneContainer = styled.div<{ merchantBrandColor?: string; textColor?: string }>`
  color: white;

  .coverage-page-section--title,
  .coverage-page-section-coverage-item--description {
    color: ${(props) => props.textColor || 'rgba(255, 255, 255)'};
    opacity: 0.7;
  }

  .coverage-page-section-coverage-item--container {
    @media (min-width: 700px) {
      max-width: 100%;
    }
  }

  @media (max-width: 700px) {
    background: ${(props) => props.merchantBrandColor || '#0f172a'};
    transition: 0.15s ease-in-out background;
  }
`;
