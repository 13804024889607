import * as React from 'react';
import { IoArrowForward, IoCheckmarkOutline, IoCheckmarkSharp } from 'react-icons/io5';
import styled from 'styled-components';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { Checkbox } from '../Form/checkbox';
import { Radio } from '../Form/radio';

export const CardGallery = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  gap: 40px;
  padding: 20px 0px 0px 0px;

  a {
    text-decoration: none;
  }
`;

const CardContainer = styled.div<{ comingSoon?: boolean }>`
  box-shadow: ${(props) =>
    !props.comingSoon
      ? '0px 0px 12px rgba(55, 55, 55, 0.3)'
      : '0px 0px 12px rgba(55, 55, 55, 0.3)'};
  height: 260px;
  width: 240px;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
  cursor: ${(props) => (props.comingSoon ? 'default' : 'pointer')};
  position: relative;
  overflow-y: hidden;

  ${(props) =>
    !props.comingSoon
      ? `:hover {
    box-shadow: 0px 0px 24px rgba(55, 55, 55, 0.4);
    transform: scale(1.02);
  }`
      : ''}
`;

const CardImage = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    height: 85%;
    max-width: 190px;
    border-radius: 8px;
  }
`;

const CardContents = styled.div`
  padding: 20px;

  h3 {
    margin: 0 0 5px 0;
    padding: 0;
    color: #333333;
  }

  h4 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #333333;
    font-size: 0.85em;
  }
`;

const CardBadgeContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface ComingSoonProps {
  product: string;
}

export const ComingSoon: React.FunctionComponent<React.PropsWithChildren<ComingSoonProps>> = () => {
  const [success, setSuccess] = React.useState(false);

  const onClick = () => {
    if (success) {
      return;
    }

    setSuccess(true);
  };

  return (
    <div>
      <Button
        icon={success ? <IoCheckmarkOutline /> : <IoArrowForward />}
        primary
        disabled={success}
        onClick={onClick}
      >
        Get notified
      </Button>
    </div>
  );
};

export const Card = (props: {
  title: string;
  description: string;
  image: JSX.Element;
  badge?: JSX.Element;
  comingSoon?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [mouseOver, setMouseOver] = React.useState(false);

  React.useEffect(() => {
    if (!ref || !ref.current || !props.comingSoon) {
      return;
    }

    ref.current.scroll({
      top: mouseOver ? 300 : 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [mouseOver]);

  return (
    <CardContainer
      ref={ref}
      comingSoon={props.comingSoon}
      onClick={props.comingSoon ? undefined : props.onClick}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      aria-label={props.title}
    >
      <div
        style={{
          opacity: props.comingSoon && !mouseOver ? 0.5 : 1.0,
          transition: 'all 0.3s ease-in-out'
        }}
      >
        <CardImage>{props.image}</CardImage>
        <CardContents>
          <h3>{props.title}</h3>
          <h4>{props.description}</h4>
        </CardContents>
        {props.comingSoon && <ComingSoon product={props.title} />}
      </div>
      {props.badge && <CardBadgeContainer>{props.badge}</CardBadgeContainer>}
    </CardContainer>
  );
};

const SlimCardContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  max-width: 320px;
  width: 100%;
  border-radius: 8px;
  transition: 0.15s all;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  border: ${(props) => (props.selected ? '1px solid transparent' : '1px dashed #C8C8C8')};
  box-shadow: ${(props) =>
    props.disabled && props.selected
      ? '0 0 0 1px #666666'
      : !props.disabled && props.selected
      ? '0 0 0 2px #0ea5e9'
      : '0 0 0 0px #C8C8C8'};
`;

const SlimCardImage = styled.div`
  width: 100px;
  height: 100%;
  padding: 20px 10px 20px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;

  img {
    width: 100%;
    border-radius: 8px;
  }

  svg {
    color: #4c617f;
    font-size: 2.4em;
  }
`;

const SlimCardContents = styled(CardContents)`
  width: 100%;
  padding: 20px 20px 20px 10px;
  flex-grow: 1;

  h3 {
    margin: 0 0 2px 0;
  }
`;

const CheckmarkBox = styled.div<{ selected?: boolean; disabled?: boolean }>`
  position: absolute;
  height: 30px;
  width: 30px;
  text-align: center;
  top: -2px;
  right: -2px;
  line-height: 32px;
  color: white;
  background: ${(props) => (props.disabled ? '#666666' : '#0EA5E9')};
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: 0.15s all;

  visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.selected ? 1 : 0)};
`;

const TitleBadge = styled.div`
  display: flex;
  justify-content: space-between;
  alignitems: center;
`;

export const SelectableOptionCard = (props: {
  title: string;
  description: string;
  image: JSX.Element;
  badge?: JSX.Element;
  titleAction?: JSX.Element;
  selected?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <SlimCardContainer
    style={props.style}
    onClick={props.onClick}
    selected={props.selected}
    disabled={props.disabled}
  >
    <SlimCardImage>{props.image}</SlimCardImage>
    <SlimCardContents>
      <TitleBadge style={{ paddingBottom: '5px' }}>
        <h3 style={{ margin: '0' }}>{props.title}</h3>
        {(props.badge || props.titleAction) && (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: '0 0 120px'
            }}
          >
            {props.badge}
            {props.titleAction}
          </div>
        )}
      </TitleBadge>
      <h4>{props.description}</h4>
    </SlimCardContents>
    <CheckmarkBox selected={props.selected} disabled={props.disabled}>
      {!props.loading && <IoCheckmarkSharp aria-label="Selected" />}
      {props.loading && <Spinner size={16} />}
    </CheckmarkBox>
  </SlimCardContainer>
);

const SelectableCheckboxCardContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  transition: 0.15s all;
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid #0EA5E9' : '1px solid #E2E2E2')};
`;

const SelectableCheckboxCardContents = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 20px;
  flex-grow: 1;
`;

export const SelectableCheckboxCard = (props: {
  title: string;
  subTitle: JSX.Element;
  description: JSX.Element;
  selected?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <SelectableCheckboxCardContainer
    style={props.style}
    onClick={props.onClick}
    selected={props.selected}
    disabled={props.disabled}
  >
    <SelectableCheckboxCardContents>
      <Checkbox checked={props.selected} label={props.title} onChange={props.onClick} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <text style={{ margin: '0', fontSize: '16px', fontWeight: 'bold' }}>{props.title}</text>
        {props.subTitle}
        {props.description}
      </div>
    </SelectableCheckboxCardContents>
  </SelectableCheckboxCardContainer>
);

const ItemizedItemsCardContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
`;

const ItemizedItemsCardContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  flex-grow: 1;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemizedItemsCardDivider = styled.div`
  width: 100%;
  height: 1.5px;
  border: 0;
  background-color: #e2e2e2;
`;

type Items = {
  title: string;
  link?: string;
  price: number;
};

type FeeDetails = {
  title: string;
  price: number;
};

type PriceBreakdown = {
  subTotal: number;
  fees?: FeeDetails[];
  grandTotal: number;
};

export const ItemizedItemsCard = (props: {
  items: Items[];
  price: PriceBreakdown;
  style?: React.CSSProperties;
}): JSX.Element => (
  <ItemizedItemsCardContainer>
    <ItemizedItemsCardContents>
      {props.items.map((item) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ItemContainer>
              <text style={{ fontSize: '1em' }}>{item.title}</text>
              <text style={{ fontSize: '1em' }}>{'$' + item.price}</text>
            </ItemContainer>
            {item.link && (
              <a href={item.link} target="_blank" style={{ fontSize: '0.75em' }}>
                View quote document
              </a>
            )}
          </div>
        );
      })}
      <ItemizedItemsCardDivider />
      <div style={{ alignSelf: 'flex-end' }}>
        <ItemContainer style={{ width: '200px', marginBottom: '3px' }}>
          <text style={{ fontSize: '0.9em' }}>Subtotal</text>
          <text style={{ fontSize: '0.9em' }}>{'$' + props.price.subTotal}</text>
        </ItemContainer>
        {props.price.fees &&
          props.price.fees.map((fee) => {
            return (
              <ItemContainer style={{ width: '200px', marginBottom: '3px' }}>
                <text style={{ fontSize: '0.9em' }}>{fee.title}</text>
                <text style={{ fontSize: '0.9em' }}>{'$' + fee.price}</text>
              </ItemContainer>
            );
          })}
        <ItemContainer style={{ width: '200px', marginTop: '3px' }}>
          <text style={{ fontSize: '0.95em' }}>Total Due</text>
          <text style={{ fontSize: '0.95em' }}>{'$' + props.price.grandTotal}</text>
        </ItemContainer>
      </div>
    </ItemizedItemsCardContents>
  </ItemizedItemsCardContainer>
);

const RadioSelectableCardContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  position: relative;
  flex-direction: column;
  display: flex;
  max-width: calc(33% - 16px);
  width: 100%;
  border-radius: 8px;
  transition: 0.15s all;
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid #0EA5E9' : '1px solid #E2E2E2')};

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const RadioSelectableCardContents = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 20px 20px 20px 20px;
  flex-grow: 1;
`;

const RadioSelectableListItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 20px;
`;

const RadioSelectableCardDivider = styled.div<{ selected?: boolean }>`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.selected ? '#0EA5E9' : '#666666')};

  &::before,
  &::after {
    flex-grow: 1;
    content: '';
    border: 0;
    background-color: ${(props) => (props.selected ? '#0EA5E9' : '#E2E2E2')};
    height: 1.5px;
  }

  &::before {
    margin-right: 5px;
  }

  &::after {
    margin-left: 5px;
  }
`;

export const RadioSelectableCard = (props: {
  title: string;
  subTitle: JSX.Element;
  description: JSX.Element;
  selected?: boolean;
  loading?: boolean;
  disabled?: boolean;
  checkItems?: string[];
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <RadioSelectableCardContainer
    style={props.style}
    onClick={props.onClick}
    selected={props.selected}
    disabled={props.disabled}
  >
    <RadioSelectableCardContents>
      <Radio
        checked={props.selected}
        label={props.title}
        onChange={props.onClick}
        id={props.title}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <text style={{ margin: '0', fontSize: '16px', fontWeight: 'bold' }}>{props.title}</text>
        {props.subTitle}
        {props.description}
      </div>
    </RadioSelectableCardContents>
    {props.checkItems && (
      <>
        <RadioSelectableCardDivider selected={props.selected}>
          What's covered
        </RadioSelectableCardDivider>
        <RadioSelectableListItems>
          {props.checkItems.map((label) => {
            return (
              <span
                style={{
                  display: 'flex',
                  gap: '5px',
                  fontSize: '12px',
                  alignContent: 'start',
                  color: '#666666'
                }}
              >
                <IoCheckmarkSharp style={{ color: '0EA5E9', fontSize: '1em' }} />
                {label}
              </span>
            );
          })}
        </RadioSelectableListItems>
      </>
    )}
  </RadioSelectableCardContainer>
);

const ShadowCardContainer = styled.div`
  background: #f6f6f6;
  border: 2px dashed #dfdfdf;
  height: 260px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  transition: 0.15s all;
  cursor: pointer;

  color: #b2b2b2;

  svg {
    font-size: 3em;
    color: #b2b2b2;
  }

  :hover {
    border: 2px solid #dfdfdf;
    transform: scale(1.02);
  }
`;

export const ShadowCard = (props: {
  icon: JSX.Element;
  description: string;
  onClick?: () => void;
}): JSX.Element => (
  <ShadowCardContainer onClick={props.onClick}>
    <div>{props.icon}</div>
    <div style={{ padding: '10px' }}>{props.description}</div>
  </ShadowCardContainer>
);

export const WidgetCardGallery = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  font-size: 0.8em;
  width: 400px;
  flex-wrap: wrap;
  padding-inline-start: 5px;

  a {
    text-decoration: none;
  }
`;

const WidgetCardContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  max-width: 160px;
  width: 100%;
  border-radius: 8px;
  transition: 0.15s all;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  border: ${(props) => (props.selected ? '1px solid transparent' : '1px dashed #C8C8C8')};
  background-color: ${(props) => (props.selected ? '#0ea5e930' : 'transparent')};
  box-shadow: ${(props) =>
    props.disabled && props.selected
      ? '0 0 0 1px #666666'
      : !props.disabled && props.selected
      ? '0 0 0 2px #0ea5e9'
      : '0 0 0 0px #C8C8C8'};
`;

const WidgetCardContents = styled(CardContents)`
  width: 100%;
  padding: 8px;
`;

export const WidgetOptionCard = (props: {
  title: JSX.Element;
  subtitle: JSX.Element;
  description: () => string | undefined;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <WidgetCardContainer
    style={props.style}
    onClick={props.onClick}
    selected={props.selected}
    disabled={props.disabled}
  >
    <WidgetCardContents>
      <TitleBadge style={{ paddingBottom: '5px' }}>
        <div
          style={{
            ...props.style,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {props.title}
          {props.subtitle}
        </div>
      </TitleBadge>
      {!!props.description() && <h4 style={props.style}>{props.description()}</h4>}
    </WidgetCardContents>
  </WidgetCardContainer>
);
