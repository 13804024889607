import { PageSection } from '@oysterjs/ui/Page';
import styled from 'styled-components';
import {
  Action,
  CertificateLeftSection,
  CertificateRightSection,
  CertificateRightSectionTitle,
  CertificateSectionContainer
} from './certificate';
import { IoCopyOutline } from 'react-icons/io5';

const Skeleton = styled.div<{ width: string }>`
  border-radius: 12px;
  color: transparent !important;
  width: 100%;
  max-width: ${(props) => props.width || '100%'};
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: #f2f2f2;
    }
    100% {
      background-color: #dadada;
    }
  }
`;

const CertificatePolicySkeleton = () => (
  <div>
    <Skeleton width="80%">
      <h1 style={{ margin: '0px', padding: '0', fontSize: '1.3em', fontWeight: 500 }}>X</h1>
    </Skeleton>
    <Skeleton width="50%">
      <h2 style={{ margin: '4px 0px', fontSize: '0.9em', fontWeight: 400, color: 'transparent' }}>
        X
      </h2>
    </Skeleton>
    <Skeleton width="30%">
      <h2 style={{ marginTop: '4px', fontSize: '0.9em', fontWeight: 400, color: 'transparent' }}>
        X
      </h2>
    </Skeleton>
    <ul
      style={{
        padding: '0px 0px',
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
      }}
    >
      <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
        <Skeleton width="70%">X</Skeleton>
      </li>
      <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
        <Skeleton width="50%">X</Skeleton>
      </li>
      <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
        <Skeleton width="60%">X</Skeleton>
      </li>
      <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
        <Skeleton width="40%">X</Skeleton>
      </li>
    </ul>
  </div>
);

const CertificateSectionSkeleton = () => (
  <CertificateSectionContainer>
    <CertificateLeftSection>
      <CertificatePolicySkeleton />
      <CertificatePolicySkeleton />
    </CertificateLeftSection>
    <CertificateRightSection>
      <div>
        <Skeleton width="100px">
          <CertificateRightSectionTitle style={{ color: 'transparent' }}>
            X
          </CertificateRightSectionTitle>
        </Skeleton>
        <Skeleton width="80%">
          <p style={{ margin: '4px 0px' }}>X</p>
        </Skeleton>
        <Skeleton width="100%">
          <p style={{ margin: '4px 0px' }}>
            A<br />B<br />C
          </p>
        </Skeleton>
      </div>
      <div>
        <Skeleton width="100px">
          <CertificateRightSectionTitle style={{ color: 'transparent' }}>
            X
          </CertificateRightSectionTitle>
        </Skeleton>
        <Skeleton width="80%">
          <p style={{ margin: '4px 0px' }}>X</p>
        </Skeleton>
        <Skeleton width="100%">
          <p style={{ margin: '4px 0px' }}>
            A<br />B<br />C
          </p>
        </Skeleton>
      </div>
      <div>
        <Skeleton width="160px">
          <CertificateRightSectionTitle style={{ color: 'transparent' }}>
            X
          </CertificateRightSectionTitle>
        </Skeleton>
        <Skeleton width="100%">
          <p style={{ margin: '4px 0px' }}>
            A<br />B<br />C
          </p>
        </Skeleton>
      </div>
    </CertificateRightSection>
  </CertificateSectionContainer>
);

export const CertificateSkeleton = () => (
  <>
    <PageSection noBorder>
      <Skeleton width="400px">
        <h1 style={{ marginBottom: '8px' }}>X</h1>
      </Skeleton>
      <Skeleton width="180px">
        <h2 style={{ marginTop: '8px', fontSize: '1.1em', fontWeight: 500, color: 'transparent' }}>
          X
        </h2>
      </Skeleton>
      <Skeleton width="100%">
        <p style={{ margin: '4px 0px' }}>X</p>
      </Skeleton>
      <Skeleton width="70%">
        <p style={{ margin: '4px 0px 16px 0px' }}>X</p>
      </Skeleton>
      <Skeleton width="280px">
        <Action style={{ visibility: 'hidden' }}>
          <IoCopyOutline />
        </Action>
      </Skeleton>
    </PageSection>
    <PageSection noBorder>
      <CertificateSectionSkeleton />
    </PageSection>
  </>
);
