import * as React from 'react';
import styled from 'styled-components';

import { ButtonContainer, LinkComponent, Button, ButtonLink } from '@oysterjs/ui/Button';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { Progress } from '@oysterjs/ui/Form/progress';
import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  IoArrowBack,
  IoArrowForward,
  IoBusinessOutline,
  IoCheckmarkSharp,
  IoCloseSharp,
  IoCloudUploadOutline,
  IoDocumentTextOutline,
  IoLinkOutline
} from 'react-icons/io5';
import {
  Switch,
  Redirect,
  Route as BrowserRoute,
  matchPath,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { Switch as OysterSwitch } from '@oysterjs/ui/Form/switch';
import { LabeledCheckbox, NColumnListContainer } from '../personalization/forms';
import { TextWithIcon } from '../../navbar/sidebar';
import { ItemizedItemsCard, RadioSelectableCard, SelectableCheckboxCard } from '@oysterjs/ui/Card';
import config from '@oysterjs/core/config';
import { Select } from '@oysterjs/ui/Form/select';
import { CurrencyInput, TextInput } from '@oysterjs/ui/Form/text';
import { AttachmentUploader } from '@oysterjs/ui/Attachment';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { GetApplicationWrapper, Page, PageProps, PageWrapper } from '../../page';
import { uploadCommercialDocuments } from '@oysterjs/core/api/merchant';
import { WrappedError } from '@oysterjs/core/errors';
import { Spinner } from '@oysterjs/ui/Spinner';
import { DatePicker } from '@oysterjs/ui/Form/datepicker';
// TODO: add back in when we figure out the bug
// import { enableCommercialOauth } from '@oysterjs/core/api/flag';
import { Banner } from '@oysterjs/ui/Banner';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { Radio, RadioProps } from '@oysterjs/ui/Form/radio';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { AttachmentFile, DefaultFileRoles, MerchantIntegrationType } from '@oysterjs/types';
import {
  BusinessApplication,
  BusinessProfile,
  BusinessQuote,
  InsuranceType,
  Location,
  LocationType,
  OperationType,
  ValidationError,
  GeneralApplication,
  useRetrieveQuoteQuery,
  QuoteInputType,
  CoverageApplication,
  useSubmitBusinessApplicationMutation,
  PropertyCoverageApplication,
  BusinessApplicationState,
  FileRole,
  FileVisibility
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import {
  getDisplayInsuranceType,
  toLegacyValidationError
} from '@oysterjs/types/merchant/graphql/map';
import { loadCoterieStripe } from '@oysterjs/core/stripe';
import { CardElementTokenCollectionInput } from '@oysterjs/core/payment';
import { DateOfBirthInput } from '@oysterjs/ui/Form/dob';

const FormatSvg = styled.span`
  display: flex;
`;

const VerticleStackButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const LinkButton = styled.button`
//   background-color: transparent;
//   font-size: 0.7em;
//   padding-top: 7px;
//   border: none;
//   cursor: pointer;
//   color: #0EA5E9;
//   text-decoration: underline;
// `;

const CoverageSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 25px;

  @media (max-width: 600px) {
    gap: 10px;
    flex-direction: column;
  }
`;

const GetOperationsLabel = (operationType: OperationType): string => {
  switch (operationType) {
    case OperationType.ManufacturingDesign:
      return 'Manufacturing';
    case OperationType.Service:
      return 'Service or Repair';
    case OperationType.Rental:
      return 'Rental';
    case OperationType.Retail:
      return 'Retail or E-Commerce';
    case OperationType.Wholesale:
      return 'Wholesale';
    default:
      return 'Other';
  }
};

export const CheckListItem = (props: { itemLabel: string }) => {
  return (
    <TextWithIcon style={{ padding: '0px 0px 8px 0px', alignItems: 'start' }}>
      <FormatSvg>
        <IoCheckmarkSharp style={{ color: '0EA5E9', fontSize: '1.5em' }} />
      </FormatSvg>
      {props.itemLabel}
    </TextWithIcon>
  );
};

export const getConnectionURL = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      switch (config().environment) {
        case 'dev':
        case 'local':
        case 'staging':
          return 'https://partners.shopify.com/2558128/apps/4714233857/test';
        case 'production':
          return 'https://apps.shopify.com/oyster';
      }
      break;
    case MerchantIntegrationType.lsretail_rseries:
      return `${config().backendBaseUrl.integrate}/lsretail-rseries/authorize`;
    default:
      return '';
  }
};

const CompletePageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 0px;
  gap: 20px;
`;

const CompletePageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const CompletePageImage = styled.img`
  display: block;
  margin: auto;
  width: 100%;
`;

export const CommercialInsuranceCompletePage: React.FunctionComponent<
  React.PropsWithChildren<PageProps>
> = (props) => {
  const [redirectionURL, setRedirectionURL] = React.useState<string | undefined>();
  const [error, setError] = React.useState('');
  const [application, setApplication] = React.useState<BusinessApplication>();
  const [coterieQuotes, setCoterieQuotes] = React.useState<BusinessQuote[]>(
    props.application.quotes.filter((q) => q.carrier.id === 'coterie')
  );
  const [requireCoteriePayment, setRequireCoteriePayment] = React.useState<boolean | undefined>();
  const [coteriePaymentToken, setCoteriePaymentToken] = React.useState<string>();
  const [alreadySubmitted, setAlreadySubmitted] = React.useState(false);

  const [submitMutation] = useSubmitBusinessApplicationMutation();

  const handleActivate = async () => {
    try {
      const res = await submitMutation({
        variables: {
          input: {
            id: props.application.id,
            paymentMethodToken: coteriePaymentToken
          }
        }
      });
      if (res.errors && res.errors.length > 0) {
        setError(res.errors[0].message);
      }
      setAlreadySubmitted(
        res.data?.submitBusinessApplication?.application?.state ==
          BusinessApplicationState.Submitted
      );
      if (res.data?.submitBusinessApplication) {
        setRedirectionURL(res.data.submitBusinessApplication.redirectionUrl || undefined);
        if (res.data.submitBusinessApplication?.application) {
          setApplication(res.data.submitBusinessApplication.application as BusinessApplication);
          setCoterieQuotes(
            (res.data.submitBusinessApplication.application.quotes as BusinessQuote[]).filter(
              (q) => q.carrier.id === 'coterie'
            )
          );
        }
      }
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    }
  };

  React.useEffect(() => {
    const hasCoterieQuote =
      coterieQuotes.length > 0 &&
      !!props.application.insuranceTypes.find((type) => coterieQuotes.find((q) => q.type === type));
    setRequireCoteriePayment(hasCoterieQuote && coteriePaymentToken === undefined);
  }, [coterieQuotes, coteriePaymentToken]);

  React.useEffect(() => {
    if (requireCoteriePayment === false) {
      handleActivate();
    }
  }, [props.application.id, coteriePaymentToken, requireCoteriePayment]);

  const getCoterieQuoteForType = (type: InsuranceType) => {
    return coterieQuotes.find((q) => q.type === type);
  };

  const getPayableCoterieQuote = () => {
    return props.application.insuranceTypes.map((i) => getCoterieQuoteForType(i)).find((q) => !!q);
  };

  return (
    <>
      {!alreadySubmitted && requireCoteriePayment && (
        <>
          <PageSection noBorder>
            <h1 style={{ marginBottom: '0px' }}>Confirm your coverage</h1>
            <p>
              You're almost ready to go. View your quote and the total cost breakdown below. When
              you are ready, confirm your payment method.
            </p>
            {props.application.insuranceTypes?.filter((i) => !getCoterieQuoteForType(i)).length >
              0 && (
              <Banner
                style={{ background: 'rgba(173, 173, 173, 0.11)' }}
                title={
                  `Your ` +
                  props.application.insuranceTypes
                    ?.filter((i) => !getCoterieQuoteForType(i))
                    .map((i) => getDisplayInsuranceType(i))
                    .join(' and ') +
                  (props.application.insuranceTypes?.filter((i) => !getCoterieQuoteForType(i))
                    .length > 1
                    ? ' Coverages'
                    : ' Coverage')
                }
                description={
                  `The ` +
                  props.application.insuranceTypes
                    ?.filter((i) => !getCoterieQuoteForType(i))
                    .map((i) => getDisplayInsuranceType(i))
                    .join(' and ') +
                  (props.application.insuranceTypes?.filter((i) => !getCoterieQuoteForType(i))
                    .length > 1
                    ? ' Coverages'
                    : ' Coverage') +
                  ` you selected requires additional review from
                    the Oyster team. Someone will reach out to you regarding this.` +
                  `${
                    props.application.insuranceTypes.filter((i) => !!getCoterieQuoteForType(i))
                      .length > 0
                      ? ` In the mean time,
                    we have summarized the rest of your coverages below.`
                      : ''
                  }`
                }
              />
            )}
            {requireCoteriePayment && !coteriePaymentToken && (
              <>
                <FormRowHeader
                  title="Coverage Summary"
                  description="Below is a summary of the coverages we are able to bind immediately."
                />
                <FormRow>
                  <FormColumn style={{ paddingTop: '10px' }}>
                    <ItemizedItemsCard
                      items={[
                        (() => {
                          const quote = getPayableCoterieQuote();
                          return {
                            title: getDisplayInsuranceType(quote?.type),
                            link: quote?.details?.proposalUrl
                              ? quote.details.proposalUrl
                              : undefined,
                            price: quote?.details?.pricing?.premium
                              ? quote.details.pricing.premium
                              : 0
                          };
                        })()
                      ]}
                      price={(() => {
                        const quote = getPayableCoterieQuote();
                        return {
                          subTotal: quote?.details?.pricing?.premium || 0,
                          fees: quote?.details?.pricing?.fees.map((fee) => ({
                            title: fee.displayName,
                            price: fee.amount
                          })),
                          grandTotal: quote?.details?.pricing.total || 0
                        };
                      })()}
                    />
                  </FormColumn>
                </FormRow>
              </>
            )}
            <FormRowHeader title="Payment" description="Please enter your payment details below." />
            <FormRow>
              <FormColumn>
                <CardElementTokenCollectionInput
                  stripeProvider={loadCoterieStripe}
                  canSkip={true}
                  onBack={props.onBack}
                  onSkip={() => {
                    setCoteriePaymentToken('');
                  }}
                  onError={setError}
                  onTokenCreated={setCoteriePaymentToken}
                  notices={getPayableCoterieQuote()?.details.notices || []}
                />
              </FormColumn>
            </FormRow>
          </PageSection>
        </>
      )}
      {!alreadySubmitted && !requireCoteriePayment && !application && !error && (
        <>
          <PageSection noBorder>
            <h1>Just a moment...</h1>
            <p>
              Thank you for choosing Oyster. We're glad you're joining us! Just one moment as we
              process your information...
            </p>
          </PageSection>
          <PageSection noBorder noPadding centered>
            <Spinner color="#333333" />
          </PageSection>

          <PageSection noBorder noPadding>
            {error && <p style={{ color: '#d1344b' }}>{error}</p>}
          </PageSection>
        </>
      )}
      {!alreadySubmitted && redirectionURL && (
        <CompletePageContainer>
          <CompletePageSection style={{ maxWidth: '400px' }}>
            {/* On coterie payment input suceeded */}
            {coteriePaymentToken && coteriePaymentToken.length > 0 && (
              <>
                <h1 style={{ marginBottom: '0' }}>We are finalizing your insurance coverage!</h1>
                <p>
                  <b>Next Steps:</b>
                  <br></br>Once your coverage has been processed we will send you all the
                  information about your new coverage.<br></br>
                  <br></br>In the meantime, you can visit our{' '}
                  <a href={redirectionURL} style={{ color: '#0EA5E9' }}>
                    dashboard
                  </a>{' '}
                  to check out your insurance policy and other products that Oyster has to offer!
                </p>
              </>
            )}
            {/* On skip or non-coterie insurance type selection */}
            {(!coteriePaymentToken || coteriePaymentToken.length === 0) && (
              <>
                {/* For non coterie application */}
                {!props.application.insuranceTypes.find((type) =>
                  coterieQuotes.find((q) => q.type === type)
                ) && (
                  <h1 style={{ marginBottom: '0' }}>Hooray! Your application has been received</h1>
                )}
                {/* For skipped payment input */}
                {!props.application.insuranceTypes.find((type) =>
                  coterieQuotes.find((q) => q.type === type)
                ) && <h1 style={{ marginBottom: '0' }}>Your request has been received!</h1>}
                <p>
                  <b>Next Steps:</b>
                  <br></br>A dedicated Oyster Commercial Team member will reach out with further
                  information. In the meantime, you can head to your{' '}
                  <a href={redirectionURL} style={{ color: '#0EA5E9' }}>
                    dashboard
                  </a>{' '}
                  to review your business information and check out other products that Oyster has
                  to offer!
                </p>
              </>
            )}
            <ButtonContainer>
              <ButtonLink primary icon={<IoArrowForward />} href={redirectionURL}>
                Continue to dashboard
              </ButtonLink>
            </ButtonContainer>
          </CompletePageSection>
          <CompletePageImage src="/images/high_five.svg" />
        </CompletePageContainer>
      )}
      {alreadySubmitted && (
        <>
          <PageSection noBorder>
            <h1 style={{ marginBottom: '0' }}>Hooray! Your application has been received</h1>
            <p>
              <b>Next Steps:</b>
              <br></br>A dedicated Oyster Commercial Team member will reach out with further
              information. In the meantime, you can head to your{' '}
              <a href={redirectionURL} style={{ color: '#0EA5E9' }}>
                dashboard
              </a>{' '}
              to review the information you have submitted and check out other products that Oyster
              has to offer!
            </p>
          </PageSection>
          {redirectionURL && (
            <PageSection noPadding style={{ paddingBottom: '40px' }}>
              <ButtonLink icon={<IoArrowForward />} primary href={redirectionURL}>
                Continue to Dashboard
              </ButtonLink>
            </PageSection>
          )}
        </>
      )}
      {error && (
        <>
          <PageSection noBorder>
            <h1>Oops!</h1>
            <p>
              There was an error submitting your information. Please reach out to{' '}
              <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we'll help
              resolve the issue.
            </p>
          </PageSection>
          <PageSection noBorder noPadding>
            {error && <p style={{ color: '#d1344b' }}>{error}</p>}
          </PageSection>
        </>
      )}
    </>
  );
};

enum LearnMorePolicyType {
  BOP,
  GL,
  CP,
  WC
}

const CommercialInsuranceCoverageSummaryPage: React.FunctionComponent<
  React.PropsWithChildren<PageProps>
> = (props) => {
  const [learnMorePolicyType, setLearnMorePolicyType] = React.useState<LearnMorePolicyType>();
  const [quotes, setQuotes] = React.useState<BusinessQuote[]>([]);
  const [selectedCoverage, setSelectedCoverage] = React.useState<InsuranceType>();
  const [additionalCoverages, setAdditionalCoverages] = React.useState([] as InsuranceType[]);

  const { data, loading } = useRetrieveQuoteQuery({
    variables: {
      input: {
        type: QuoteInputType.ApplicationId,
        id: props.application.id,
        allowNewQuotes: true
      }
    }
  });

  React.useEffect(() => {
    if (data && data.quote) {
      setQuotes(data?.quote as BusinessQuote[]);
    }
  }, [data]);

  // Set coverage to the already selected values if user goes back
  React.useEffect(() => {
    setSelectedCoverage(
      props.application.insuranceTypes
        ?.filter(
          (i) =>
            i === InsuranceType.BusinessOwners ||
            i === InsuranceType.GeneralLiability ||
            i === InsuranceType.Property
        )
        ?.at(0) || undefined
    );
    setAdditionalCoverages(
      props.application.insuranceTypes?.filter(
        (i) =>
          i !== InsuranceType.BusinessOwners &&
          i !== InsuranceType.GeneralLiability &&
          i !== InsuranceType.Property
      ) || []
    );
  }, []);

  // Everytime coverage or additional coverage changes, we want to update the insurance types
  React.useEffect(() => {
    updateBusinessApplication((businessApplication) => ({
      ...businessApplication,
      insuranceTypes: [selectedCoverage, ...additionalCoverages].filter(
        (c): c is InsuranceType => !!c
      )
    }));
  }, [selectedCoverage, additionalCoverages]);

  const updateBusinessApplication = (fn: (prev: BusinessApplication) => BusinessApplication) => {
    props.onUpdate({
      ...fn(props.application)
    });
  };

  const isOptingIntoOysterBundle = (): boolean => !!props.application.optedIntoOysterBundle;

  const onSubmit = () => {
    props.onNext();
  };

  const getQuoteForType = (type: InsuranceType): BusinessQuote | undefined => {
    return quotes?.find((q) => q.type === type);
  };

  return (
    <>
      {loading && (
        <PageSection noBorder noPadding centered>
          <Spinner color="#333333" />
        </PageSection>
      )}
      {!loading && quotes && quotes.length > 0 && (
        <PageSection>
          <h1 style={{ marginBottom: '0px' }}>Select your coverages</h1>
          <p>
            Choose from one of three comprehensive packages, providing overall coverage for your
            business.
          </p>
          <Banner
            style={{ background: 'rgba(173, 173, 173, 0.11)' }}
            title="Save money with the Oyster Bundle (TM)"
            bannerAction={
              <OysterSwitch
                enabled={isOptingIntoOysterBundle()}
                onChange={() => {
                  updateBusinessApplication((businessApplication) => ({
                    ...businessApplication,
                    optedIntoOysterBundle: !isOptingIntoOysterBundle()
                  }));
                }}
              />
            }
            description={
              <p>
                If you combine this purchase with some of Oyster's other products, you can generate
                additional revenue which you can use to offset the cost of insurance. Toggle this
                switch to learn more about our programs.{' '}
                <i>
                  This does not affect the value of any premiums owed and is only an estimate of
                  what you can earn.
                </i>
              </p>
            }
          />
          <CoverageSelectionContainer>
            {getQuoteForType(InsuranceType.BusinessOwners) && (
              <RadioSelectableCard
                key="Business Owner's"
                title="Business Owner's"
                subTitle={
                  <>
                    {props.application.optedIntoOysterBundle && (
                      <text
                        style={{
                          paddingTop: '3px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        }}
                      >
                        <span style={{ textDecoration: 'line-through', color: '#666666' }}>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'usd',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(
                            getQuoteForType(InsuranceType.BusinessOwners)?.details.pricing.total ||
                              0
                          )}
                          /year
                        </span>{' '}
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'usd',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          getQuoteForType(InsuranceType.BusinessOwners)?.details.pricing.bundled ||
                            0
                        )}
                        /year*
                      </text>
                    )}
                    {!isOptingIntoOysterBundle() && (
                      <text
                        style={{
                          paddingTop: '3px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        }}
                      >
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'usd',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          getQuoteForType(InsuranceType.BusinessOwners)?.details.pricing.total || 0
                        )}
                        /year*
                      </text>
                    )}
                  </>
                }
                description={
                  <text style={{ paddingTop: '10px', fontSize: '13px', fontWeight: 'normal' }}>
                    A business owner's policy combines the General Liability and Commercial Property
                    coverages.{' '}
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        setLearnMorePolicyType(LearnMorePolicyType.BOP);
                      }}
                    >
                      Learn more.
                    </a>
                  </text>
                }
                selected={selectedCoverage == InsuranceType.BusinessOwners}
                onClick={() => {
                  if (selectedCoverage == InsuranceType.BusinessOwners) {
                    setSelectedCoverage(undefined);
                  } else {
                    setSelectedCoverage(InsuranceType.BusinessOwners);
                  }
                }}
                checkItems={[
                  `Building contents up to ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(props.application.propertyCoverage?.bppLimit || 500000)}`,
                  `Liability up to ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(props.application.liabilityCoverage?.limit || 1000000)}`
                ]}
              />
            )}
            {getQuoteForType(InsuranceType.GeneralLiability) && (
              <RadioSelectableCard
                key="General Liability"
                title="General Liability"
                subTitle={
                  <>
                    {isOptingIntoOysterBundle() && (
                      <text
                        style={{
                          paddingTop: '3px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        }}
                      >
                        <span style={{ textDecoration: 'line-through', color: '#666666' }}>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'usd',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(
                            getQuoteForType(InsuranceType.GeneralLiability)?.details.pricing
                              .total || 0
                          )}
                          /year
                        </span>{' '}
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'usd',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          getQuoteForType(InsuranceType.GeneralLiability)?.details.pricing
                            .bundled || 0
                        )}
                        /year*
                      </text>
                    )}
                    {!isOptingIntoOysterBundle() && (
                      <text
                        style={{
                          paddingTop: '3px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        }}
                      >
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'usd',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          getQuoteForType(InsuranceType.GeneralLiability)?.details.pricing.total ||
                            0
                        )}
                        /year*
                      </text>
                    )}
                  </>
                }
                description={
                  <text style={{ paddingTop: '10px', fontSize: '13px', fontWeight: 'normal' }}>
                    A general liability policy covers legal claims against your business.{' '}
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        setLearnMorePolicyType(LearnMorePolicyType.GL);
                      }}
                    >
                      Learn more.
                    </a>
                  </text>
                }
                selected={selectedCoverage == InsuranceType.GeneralLiability}
                onClick={() => {
                  if (selectedCoverage == InsuranceType.GeneralLiability) {
                    setSelectedCoverage(undefined);
                  } else {
                    setSelectedCoverage(InsuranceType.GeneralLiability);
                  }
                }}
                checkItems={[
                  `Liability up to ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(props.application.liabilityCoverage?.limit || 1000000)}`
                ]}
              />
            )}
            {getQuoteForType(InsuranceType.Property) && (
              <RadioSelectableCard
                key="Commercial Property"
                title="Commercial Property"
                subTitle={
                  <>
                    {isOptingIntoOysterBundle() && (
                      <text
                        style={{
                          paddingTop: '3px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        }}
                      >
                        <span style={{ textDecoration: 'line-through', color: '#666666' }}>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'usd',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(
                            getQuoteForType(InsuranceType.Property)?.details.pricing.total || 0
                          )}
                          /year
                        </span>{' '}
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'usd',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          getQuoteForType(InsuranceType.Property)?.details.pricing.bundled || 0
                        )}
                        /year*
                      </text>
                    )}
                    {!isOptingIntoOysterBundle() && (
                      <text
                        style={{
                          paddingTop: '3px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        }}
                      >
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'usd',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          getQuoteForType(InsuranceType.Property)?.details.pricing.total || 0
                        )}
                        /year*
                      </text>
                    )}
                  </>
                }
                description={
                  <text style={{ paddingTop: '10px', fontSize: '13px', fontWeight: 'normal' }}>
                    A commercial property policy protects any valuable business assets you own.{' '}
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        setLearnMorePolicyType(LearnMorePolicyType.CP);
                      }}
                    >
                      Learn more.
                    </a>
                  </text>
                }
                selected={selectedCoverage == InsuranceType.Property}
                onClick={() => {
                  if (selectedCoverage == InsuranceType.Property) {
                    setSelectedCoverage(undefined);
                  } else {
                    setSelectedCoverage(InsuranceType.Property);
                  }
                }}
                checkItems={[
                  `Building contents up to ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(props.application.propertyCoverage?.bppLimit || 500000)}`
                ]}
              />
            )}
          </CoverageSelectionContainer>
          <FormContainer>
            <FormRowHeader
              title="Additional coverage options"
              description="You can choose additional policies to further protect your business."
            />
            <FormRow>
              <FormColumn>
                <SelectableCheckboxCard
                  key="Worker's Compensation"
                  title="Worker's Compensation"
                  subTitle={
                    <>
                      {isOptingIntoOysterBundle() && (
                        <text
                          style={{
                            paddingTop: '3px',
                            fontSize: '13px',
                            fontWeight: 'normal'
                          }}
                        >
                          <span style={{ textDecoration: 'line-through', color: '#666666' }}>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'usd',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0
                            }).format(
                              getQuoteForType(InsuranceType.WorkersCompensation)?.details.pricing
                                .total || 0
                            )}
                            /year
                          </span>{' '}
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'usd',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(
                            getQuoteForType(InsuranceType.WorkersCompensation)?.details.pricing
                              .bundled || 0
                          )}
                          /year*
                        </text>
                      )}
                      {!isOptingIntoOysterBundle() && (
                        <text
                          style={{
                            paddingTop: '3px',
                            fontSize: '13px',
                            fontWeight: 'normal'
                          }}
                        >
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'usd',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(
                            getQuoteForType(InsuranceType.WorkersCompensation)?.details.pricing
                              .total || 0
                          )}
                          /year*
                        </text>
                      )}
                    </>
                  }
                  description={
                    <text style={{ paddingTop: '10px', fontSize: '13px', fontWeight: 'normal' }}>
                      A worker's compensation policy ensures that your business is covered for any
                      illness and injury of your employees.{' '}
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          setLearnMorePolicyType(LearnMorePolicyType.WC);
                        }}
                      >
                        Learn more.
                      </a>
                    </text>
                  }
                  selected={additionalCoverages.includes(InsuranceType.WorkersCompensation)}
                  onClick={() => {
                    if (additionalCoverages.includes(InsuranceType.WorkersCompensation)) {
                      setAdditionalCoverages(
                        additionalCoverages.filter((c) => c != InsuranceType.WorkersCompensation)
                      );
                    } else {
                      setAdditionalCoverages([
                        ...additionalCoverages,
                        InsuranceType.WorkersCompensation
                      ]);
                    }
                  }}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <RequirementItemContainer
                style={{ padding: '0 0', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (additionalCoverages.includes(InsuranceType.Other)) {
                    setAdditionalCoverages(
                      additionalCoverages.filter((c) => c != InsuranceType.Other)
                    );
                  } else {
                    setAdditionalCoverages([...additionalCoverages, InsuranceType.Other]);
                  }
                }}
              >
                <RequirementCheckboxContainer>
                  <Checkbox
                    label="I need some other coverage"
                    checked={additionalCoverages.includes(InsuranceType.Other)}
                    onChange={() => {
                      if (additionalCoverages.includes(InsuranceType.Other)) {
                        setAdditionalCoverages(
                          additionalCoverages.filter((c) => c != InsuranceType.Other)
                        );
                      } else {
                        setAdditionalCoverages([...additionalCoverages, InsuranceType.Other]);
                      }
                    }}
                  />
                </RequirementCheckboxContainer>
                <RequirementContentContainer>
                  <RequirementTitle>I need some other coverage</RequirementTitle>
                  <RequirementDescription>
                    Check this box if you're looking for coverage that's not offered here. Our team
                    can help you obtain the right coverage for your business. We'll reach out to
                    schedule a free consulation.
                  </RequirementDescription>
                </RequirementContentContainer>
              </RequirementItemContainer>
            </FormRow>
          </FormContainer>
          <SlideOut
            showing={learnMorePolicyType === LearnMorePolicyType.BOP}
            onClose={() => setLearnMorePolicyType(undefined)}
            onBack={() => setLearnMorePolicyType(undefined)}
          >
            <ErrorBoundary forceMobile>
              <LearnMoreContainer
                title="Business Owner's Policy (BOP)"
                description="A BOP is a commercial insurance policy that combines essential coverages from General Liability and Commercial Property policies."
              >
                <h2>Who needs one?</h2>
                <p>
                  Both liability and property coverages are recommended or required for most
                  business owners. A BOP is a comprehensive combination of coverages from two
                  standalone policies, and can often be cheaper to purchase than the standalone
                  policies combined. Some businesses are legally required to have these coverages
                  and/or have contractual obligations to maintain them.
                </p>
                <h2>What does it cover?</h2>
                <ul>
                  <li>
                    Building and contents protected up to the declared value as well as lost
                    business income due to property damage subject to policy limits.
                  </li>
                  <li>
                    Liability up to a limit of insurance, typically $1,000,000 for bodily injury or
                    property damage. Most policies also include liability coverage resulting from
                    products sold and completed operations.
                  </li>
                </ul>
                <ButtonContainer>
                  <Button
                    primary
                    onClick={() => {
                      setSelectedCoverage(InsuranceType.BusinessOwners);
                      setLearnMorePolicyType(undefined);
                    }}
                  >
                    Choose this policy
                  </Button>
                  <ButtonLink
                    href="https://www.withoyster.com/merchant-library/retail-commerce-business-owners-policy-bop"
                    target="_blank"
                  >
                    More information
                  </ButtonLink>
                </ButtonContainer>
              </LearnMoreContainer>
            </ErrorBoundary>
          </SlideOut>

          <SlideOut
            showing={learnMorePolicyType === LearnMorePolicyType.GL}
            onClose={() => setLearnMorePolicyType(undefined)}
            onBack={() => setLearnMorePolicyType(undefined)}
          >
            <ErrorBoundary forceMobile>
              <LearnMoreContainer
                title="General Liability"
                description="A General Liability policy covers your business against legal claims arising from normal business operations."
              >
                <h2>Who needs one?</h2>
                <p>
                  If your business does not own any physical assets or you already have property
                  coverage, then a standalone General Liability policy can help bridge the coverage
                  gap at an affordable price. Lessors, creditors, and business partners often
                  require liability coverage.
                </p>
                <h2>What does it cover?</h2>
                <ul>
                  <li>
                    Liability costs up to a limit of insurance, typically $1,000,000 for bodily
                    injury or property damage. Most policies also include liability coverage
                    resulting from products sold and completed operations.
                  </li>
                </ul>
                <ButtonContainer>
                  <Button
                    primary
                    onClick={() => {
                      setSelectedCoverage(InsuranceType.GeneralLiability);
                      setLearnMorePolicyType(undefined);
                    }}
                  >
                    Choose this policy
                  </Button>
                  <ButtonLink
                    href="https://www.withoyster.com/merchant-library/retail-and-commerce-general-liability-insurance"
                    target="_blank"
                  >
                    More information
                  </ButtonLink>
                </ButtonContainer>
              </LearnMoreContainer>
            </ErrorBoundary>
          </SlideOut>

          <SlideOut
            showing={learnMorePolicyType === LearnMorePolicyType.CP}
            onClose={() => setLearnMorePolicyType(undefined)}
            onBack={() => setLearnMorePolicyType(undefined)}
          >
            <ErrorBoundary forceMobile>
              <LearnMoreContainer
                title="Commercial Property"
                description="A Commercial Property policy covers your business' buildings, contents, and business income."
              >
                <h2>Who needs one?</h2>
                <p>
                  If your business owns or leases a physical location, or has significant physical
                  assets that need to be protected from theft, damage, and disaster, a stand alone
                  property policy could be a cost-effective way to obtain such coverage. You may
                  have contractual obligations to your lessor or mortgagor to maintain property
                  coverage up to a specified limit.
                </p>
                <h2>What does it cover?</h2>
                <ul>
                  <li>
                    Building and contents covered up to the declared value as well as lost business
                    income due to property damage subject to policy limits.
                  </li>
                </ul>

                <ButtonContainer>
                  <Button
                    primary
                    onClick={() => {
                      setSelectedCoverage(InsuranceType.Property);
                      setLearnMorePolicyType(undefined);
                    }}
                  >
                    Choose this policy
                  </Button>
                  <ButtonLink
                    href="https://www.withoyster.com/merchant-library/retail-commerce-property-insurance"
                    target="_blank"
                  >
                    More information
                  </ButtonLink>
                </ButtonContainer>
              </LearnMoreContainer>
            </ErrorBoundary>
          </SlideOut>

          <SlideOut
            showing={learnMorePolicyType === LearnMorePolicyType.WC}
            onClose={() => setLearnMorePolicyType(undefined)}
            onBack={() => setLearnMorePolicyType(undefined)}
          >
            <ErrorBoundary forceMobile>
              <LearnMoreContainer
                title="Worker's Compensation"
                description="A worker's compensation policy covers your workers by providing benefits in case of illness or injury."
              >
                <h2>Who needs one?</h2>
                <p>
                  If your business has any number of employees, their health and wellness may be at
                  risk due to normal business operations. A worker's compensation policy protects
                  your business from claims and liability in the case of workplace injury and
                  illness.
                </p>
                <h2>What does it cover?</h2>
                <ul>
                  <li>Medical care and treatment expenses.</li>
                  <li>Lost wages due to injury or illness.</li>
                  <li>Disability and death benefits.</li>
                </ul>

                <ButtonContainer>
                  <Button
                    primary
                    onClick={() => {
                      if (!additionalCoverages.includes(InsuranceType.WorkersCompensation)) {
                        setAdditionalCoverages([
                          ...additionalCoverages,
                          InsuranceType.WorkersCompensation
                        ]);
                      }
                      setLearnMorePolicyType(undefined);
                    }}
                  >
                    Choose this policy
                  </Button>
                  <ButtonLink
                    href="https://www.withoyster.com/merchant-library/retail-commerce-workers-compensation"
                    target="_blank"
                  >
                    More information
                  </ButtonLink>
                </ButtonContainer>
              </LearnMoreContainer>
            </ErrorBoundary>
          </SlideOut>
          <VerticleStackButton>
            <ButtonContainer style={{ paddingTop: '20px' }} center>
              {props.onBack && (
                <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
                  Back
                </Button>
              )}
              <Button
                primary
                icon={<IoArrowForward />}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
                disabled={!selectedCoverage && additionalCoverages.length === 0}
              >
                Continue
              </Button>
            </ButtonContainer>
            <p style={{ color: '#666666', fontSize: '0.7em', paddingTop: '7px' }}>
              *These premium values and coverages are estimates and are not a guarantee that
              coverage will be offered. Our team strives to provide accurate estimates, but a
              formalized quote will differ from this value and may be higher or lower depending on
              business details and volatility of the insurance marketplace.
              {isOptingIntoOysterBundle() && (
                <span>
                  You'll pay the full premium of the policies you purchase, and any additional
                  revenue from the Oyster Bundle will offset your cost thereafter.
                </span>
              )}
            </p>
          </VerticleStackButton>
        </PageSection>
      )}
    </>
  );
};

interface LearnMoreContainerProps {
  title: string;
  description: string;
}

export const LearnMoreContainer = (props: React.PropsWithChildren<LearnMoreContainerProps>) => (
  <div style={{ padding: '0px 20px' }}>
    <h1>{props.title}</h1>
    <p style={{ margin: '-1em 0 1em 0', fontSize: '0.9em', color: '#666666' }}>
      {props.description}
    </p>
    {props.children}
    <p style={{ fontSize: '0.9em', color: '#333333' }}>
      <b style={{ fontWeight: 500 }}>Need help?</b>{' '}
      <a
        target="_blank"
        href={`https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation`}
      >
        Schedule a call
      </a>{' '}
      with us!
    </p>
  </div>
);

const CommercialInsuranceUploadPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => {
  const [policyDocuments, setPolicyDocuments] = React.useState([] as File[]);
  const [policyDocumentIDs] = React.useState(props.application?.files?.map((f) => f.id) || []);
  const [loading, setLoading] = React.useState(Boolean);

  const onSubmit = () => {
    const uploadAttachmentHandler = () => {
      const newFiles = policyDocuments;
      if (newFiles && newFiles.length > 0) {
        return uploadCommercialDocuments(
          props.application.id,
          DefaultFileRoles.commercialDocument,
          newFiles,
          props.application.files.map((f) => f.id)
        );
      }
      return Promise.resolve(new Array<AttachmentFile>());
    };

    setLoading(true);
    return uploadAttachmentHandler()
      .then((uploadedFiles) => {
        props.onUpdate({
          ...props.application,
          files: uploadedFiles.map((f) => {
            return {
              id: f.ID,
              name: f.Name,
              size: f.Size,
              type: f.Type,
              role: FileRole.Unknown,
              visibility: FileVisibility.User,
              notes: '',
              url: '',
              adminUrl: '',
              createdAt: '',
              updatedAt: ''
            };
          })
        });
      })
      .then(() => {
        props.onNext();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageSection>
      <h1 style={{ marginBottom: '0px' }}>Share your existing policies</h1>
      <p
        style={{
          marginTop: '4px',
          marginBottom: '0px',
          color: '#333333'
        }}
      >
        Upload your existing policy documents and Oyster can analyze the terms, coverage, and
        exclusions to find the best policy options and ensure your business is properly covered.
      </p>
      <h2>How it works</h2>
      {[
        `Upload your existing policies' full PDF documents`,
        'Oyster understands your existing policy and identifies gaps',
        'Oyster uses your information to produce an analysis of your coverage options'
      ].map((label) => {
        return <CheckListItem itemLabel={label} />;
      })}
      <h2>Supported Documents</h2>
      {[
        'Full policy documents for any carrier and coverage type',
        'PDF-formatted documents (up to 100 pages each)'
      ].map((label) => {
        return <CheckListItem itemLabel={label} />;
      })}
      <TextWithIcon style={{ paddingTop: '0px' }}>
        <FormatSvg>
          <IoCloseSharp style={{ color: '#c70000', fontSize: '1.5em' }} />
        </FormatSvg>
        Certificates of Insurance (COIs), handwritten documents, emails, etc.
      </TextWithIcon>
      <FormContainer>
        <FormRow>
          <AttachmentUploader
            attachments={
              policyDocumentIDs?.map((fileId, index) => ({
                ID: fileId,
                Name: `Attachment #${index + 1}`,
                Type: '',
                Size: 0
              })) || []
            }
            title={'Upload Existing Policies'}
            description={`Help us find your best coverage options by uploading any existing policies your business has.`}
            multiple={true}
            allowDeleteExisting={false}
            onAttachmentRemoved={(index, _, file) => {
              if (file) {
                if (index >= 0) {
                  setPolicyDocuments(() => {
                    const updatedFiles = policyDocuments;
                    updatedFiles?.splice(index, 1);
                    return updatedFiles;
                  });
                }
                return Promise.resolve();
              } else {
                return Promise.resolve();
              }
            }}
            onAttachmentsAdded={(files) => {
              setPolicyDocuments(files);
              return Promise.resolve();
            }}
            validationError={
              props.validationError?.field === 'Attachment' && props.validationError?.message
                ? toLegacyValidationError(props.validationError)
                : undefined
            }
          />
        </FormRow>
      </FormContainer>
      <VerticleStackButton>
        <ButtonContainer style={{ paddingTop: '20px' }} center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            primary
            icon={<IoArrowForward />}
            loading={loading || props.loading}
            onClick={(e) => {
              e.preventDefault();
              if (policyDocuments.length > 0) {
                onSubmit();
              } else {
                props.onNext();
              }
            }}
            disabled={loading}
          >
            {policyDocuments.length + policyDocumentIDs.length > 0
              ? 'Continue'
              : 'Continue without documents'}
          </Button>
        </ButtonContainer>
        {/* <LinkButton onClick={() => props.onNext()} disabled={loading}>
          I don't have existing policy documents
        </LinkButton> */}
      </VerticleStackButton>
    </PageSection>
  );
};

const CommercialInsuranceConnectPage: React.FunctionComponent<
  React.PropsWithChildren<PageProps>
> = () => {
  // const [connectedIntegrations, setConnectedIntegrations] = React.useState(
  //   props.application.Integrations?.map((int) => int.Type) || ([] as MerchantIntegrationType[])
  // );
  // const [otherPlatformType, setOtherPlatformType] = React.useState('');
  // const [onlineSalesChannelName, setOnlineSalesChannelName] = React.useState('');

  // const onClickShopify = () => {
  //   window.localStorage.setItem(
  //     'oyster-commercial-application-id',
  //     JSON.stringify({
  //       BIApplicationID: props.application.ID
  //     })
  //   );
  // };

  // return (
  //   <PageSection>
  //     <h1 style={{ marginBottom: '0px' }}>Connect your stores</h1>
  //     <p
  //       style={{
  //         marginTop: '4px',
  //         marginBottom: '0px',
  //         color: '#333333'
  //       }}
  //     >
  //       Help us learn more about your business by connecting your stores. Oyster uses this
  //       information to learn about your business and provide the best quote package that covers your
  //       needs.
  //     </p>
  //     <h2>How it works</h2>
  //     {[
  //       'Select your commerce platform',
  //       'Connect the Oyster app to your store',
  //       'Oyster assesses your business for all relevant risks and surfaces an estimated quote based on your actual exposure'
  //     ].map((label) => (
  //       <CheckListItem itemLabel={label} />
  //     ))}
  //     <p style={{ fontSize: '1.1em' }}>Choose a store to connect</p>
  //     <CardGallery style={{ padding: '0', margin: '0' }}>
  //       {[
  //         MerchantIntegrationType.shopify,
  //         MerchantIntegrationType.lsretail_rseries,
  //         MerchantIntegrationType.custom
  //       ].map((integration) => (
  //         <SelectableOptionCard
  //           style={{ maxWidth: '285px', width: '100%' }}
  //           key={getChannelLabel(integration)}
  //           title={getChannelLabel(integration)}
  //           description={getChannelDescription(integration)}
  //           selected={connectedIntegrations.includes(integration)}
  //           onClick={() => {
  //             if (integration == MerchantIntegrationType.shopify) {
  //               onClickShopify();
  //             }
  //             if (connectedIntegrations.includes(integration)) {
  //               setConnectedIntegrations(connectedIntegrations.filter((i) => i != integration));
  //             } else {
  //               const url = getConnectionURL(integration);
  //               if (url != '') {
  //                 openPageInNewTab(url);
  //               }
  //               setConnectedIntegrations(connectedIntegrations.concat(integration));
  //             }
  //           }}
  //           image={getChannelImage(integration)}
  //         />
  //       ))}
  //     </CardGallery>
  //     {connectedIntegrations.includes(MerchantIntegrationType.custom) && (
  //       <FormContainer>
  //         <FormRowHeader
  //           title="Which online sales platform do you use?"
  //           description="Choose the platform you use to sell products online."
  //         />
  //         <FormRow>
  //           <FormColumn>
  //             <Select
  //               options={[
  //                 { value: '' },
  //                 { value: OnlineSalesChannelType.WooCommerce },
  //                 { value: OnlineSalesChannelType.BigCommerce },
  //                 { value: OnlineSalesChannelType.Adobe },
  //                 { value: OnlineSalesChannelType.Custom },
  //                 { value: OnlineSalesChannelType.Other }
  //               ]}
  //               value={otherPlatformType}
  //               onChange={(v) => setOtherPlatformType(v)}
  //               style={{ maxWidth: '360px' }}
  //             />
  //           </FormColumn>
  //         </FormRow>
  //         {connectedIntegrations.includes(MerchantIntegrationType.custom) &&
  //           otherPlatformType == OnlineSalesChannelType.Other && (
  //             <FormRow>
  //               <FormColumn title="Enter the name of your online sales platform">
  //                 <TextInput
  //                   placeholder="Shopify, Lightspeed, etc."
  //                   value={onlineSalesChannelName}
  //                   onChange={(e) => {
  //                     const value = e.currentTarget.value;
  //                     setOnlineSalesChannelName(value);
  //                   }}
  //                   style={{ maxWidth: '360px' }}
  //                 />
  //               </FormColumn>
  //             </FormRow>
  //           )}
  //       </FormContainer>
  //     )}
  //     <VerticleStackButton>
  //       <ButtonContainer style={{ paddingTop: '20px' }} center>
  //         {props.onBack && (
  //           <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
  //             Back
  //           </Button>
  //         )}
  //         <Button
  //           primary
  //           loading={props.loading}
  //           icon={<IoArrowForward />}
  //           onClick={(e) => {
  //             e.preventDefault();
  //             props.onNext();
  //           }}
  //           disabled={connectedIntegrations.length == 0}
  //         >
  //           Continue
  //         </Button>
  //       </ButtonContainer>
  //       <LinkButton onClick={() => props.onNext()}>
  //         I don't have a one of these stores or I'd like to enter this information manually.
  //       </LinkButton>
  //     </VerticleStackButton>
  //   </PageSection>
  // );

  // We are not using oAuth at the moment, and the integration list is missing from the model
  return <></>;
};

const LabeledRadio = (props: RadioProps) => (
  <div style={{ display: 'flex', gap: '8px', cursor: 'pointer' }} onClick={props.onChange}>
    <Radio {...props} />
    <div>{props.label}</div>
  </div>
);

const CommercialInsuranceGetInfoPage: React.FunctionComponent<
  React.PropsWithChildren<PageProps>
> = (props) => {
  const [inputChanged, setInputChanged] = React.useState(false);

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  const updateBusinessApplication = (fn: (prev: BusinessApplication) => BusinessApplication) => {
    setInputChanged(true);
    props.onUpdate({
      ...fn(props.application)
    });
  };

  const updateBusinessProfile = (fn: (prev: BusinessProfile) => BusinessProfile) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.application,
      profile: fn(props.application.profile)
    });
  };

  const updateGeneralApplication = (fn: (prev: GeneralApplication) => GeneralApplication) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.application,
      generalApplication: fn(props.application.generalApplication!)
    });
  };

  const updateGeneralLiabilityCoverage = (
    fn: (prev: CoverageApplication) => CoverageApplication
  ) => {
    setInputChanged(true);
    const coverage = props.application.liabilityCoverage || ({} as CoverageApplication);
    props.onUpdate({
      ...props.application,
      liabilityCoverage: fn(coverage)
    });
  };

  const updatePropertyCoverage = (
    fn: (prev: PropertyCoverageApplication) => PropertyCoverageApplication
  ) => {
    setInputChanged(true);
    const coverage = props.application.propertyCoverage || ({} as PropertyCoverageApplication);
    props.onUpdate({
      ...props.application,
      propertyCoverage: fn(coverage)
    });
  };

  const onChangeAddress = (address) => {
    setInputChanged(true);
    const existingAddress = props.application?.locations[0];
    props.onUpdate({
      ...props.application,
      profile: {
        ...props.application.profile,
        address: {
          line1: address.streetAddress || '',
          line2: address.streetAddressLine2 || '',
          city: address.city || '',
          zone: address.state || '',
          postalCode: address.zipCode || ''
        }
      },
      locations: [
        {
          ...existingAddress,
          hasSprinklers: existingAddress?.hasSprinklers || false,
          address: {
            line1: address.streetAddress || '',
            line2: address.streetAddressLine2 || '',
            city: address.city || '',
            zone: address.state || '',
            postalCode: address.zipCode || ''
          }
        } as Location
      ]
    });
  };

  return (
    <PageSection>
      <h1 style={{ marginBottom: '0px' }}>Business Information</h1>
      <p
        style={{
          marginTop: '4px',
          marginBottom: '0px',
          color: '#333333'
        }}
      >
        Tell us a little about the business you'd like to insure.
      </p>
      <FormContainer onSubmit={() => false}>
        <FormRowHeader title="Business information" description="Please describe your business." />
        <FormRow breakMobile>
          <FormColumn title="Legal business name">
            <TextInput
              type="text"
              name="business-name"
              value={props.application.profile.name}
              autoComplete="business-name"
              error={
                !inputChanged &&
                props.validationError?.field === 'BusinessName' &&
                props.validationError?.message
              }
              onChange={(e) => {
                const value = e.currentTarget.value;
                updateBusinessProfile((BusinessProfile) => ({
                  ...BusinessProfile,
                  name: value
                }));
              }}
            />
          </FormColumn>
          <FormColumn title="Business website">
            <TextInput
              type="text"
              name="business-website"
              value={props.application.profile.domain}
              autoComplete="business-website"
              onChange={(e) => {
                const value = e.currentTarget.value;
                updateBusinessProfile((BusinessProfile) => ({
                  ...BusinessProfile,
                  domain: value
                }));
              }}
              error={
                !inputChanged &&
                props.validationError?.field === 'Website' &&
                props.validationError?.message
              }
            />
          </FormColumn>
        </FormRow>
        <FormRowHeader
          title="Business operations"
          description="Choose all types of operations your business engages in."
        />
        <FormRow
          error={
            !inputChanged &&
            props.validationError?.field === 'Operations' &&
            props.validationError.message
          }
        >
          <NColumnListContainer columns={2}>
            {[
              OperationType.Retail,
              OperationType.Service,
              OperationType.Wholesale,
              OperationType.ManufacturingDesign,
              OperationType.Rental,
              OperationType.Other
            ].map((operation) => (
              <LabeledCheckbox
                key={operation}
                label={GetOperationsLabel(operation)}
                checked={props.application.operations?.includes(operation)}
                onChange={() => {
                  const checked = props.application.operations?.includes(operation);
                  if (checked) {
                    updateBusinessApplication((businessApplication) => ({
                      ...businessApplication,
                      operations: (businessApplication.operations || []).filter(
                        (o) => o !== operation
                      )
                    }));
                  } else {
                    updateBusinessApplication((businessApplication) => ({
                      ...businessApplication,
                      operations: [...(businessApplication.operations || []), operation]
                    }));
                  }
                }}
              />
            ))}
          </NColumnListContainer>
        </FormRow>
        <FormRowHeader
          title="Business start date"
          description="Enter the date when your business started."
        />
        <FormRow>
          <DateOfBirthInput
            error={
              !inputChanged &&
              props.validationError?.field === 'BusinessStartDate' &&
              props.validationError?.message
            }
            initialValue={[
              props.application?.generalApplication?.businessStartDate
                ? `${new Date(props.application?.generalApplication?.businessStartDate).getDate()}`
                : '',
              props.application?.generalApplication?.businessStartDate
                ? `${
                    new Date(props.application.generalApplication?.businessStartDate).getMonth() + 1
                  }`
                : '',
              props.application?.generalApplication?.businessStartDate
                ? `${new Date(
                    props.application.generalApplication?.businessStartDate
                  ).getFullYear()}`
                : ''
            ]}
            onChange={(_day, _month, _year, date) => {
              updateGeneralApplication((generalApplication) => ({
                ...generalApplication,
                businessStartDate: date.toISOString()
              }));
            }}
          />
        </FormRow>
        {props.application.operations?.find(
          (o) => o === OperationType.ManufacturingDesign || o === OperationType.Wholesale
        ) && (
          <>
            <FormRowHeader
              title="Does your business sell any products under its own brand or label?"
              description="Some insurance products may or may not be applicable depending on the answer to this question."
            />
            <FormRow
              error={
                !inputChanged &&
                props.validationError?.field === 'ManufactureOrWholesaleOwnBrand' &&
                props.validationError.message
              }
            >
              <FormColumn>
                <NColumnListContainer columns={1}>
                  <LabeledRadio
                    name="BusinessManufactureOrWholesaleOwnBrand"
                    label="Yes, we sell products under our own brand or label."
                    checked={props.application?.generalApplication?.manufactureOrWholesaleOwnBrand}
                    onChange={() =>
                      updateGeneralApplication((GeneralApplication) => ({
                        ...GeneralApplication,
                        manufactureOrWholesaleOwnBrand: true
                      }))
                    }
                  />
                  <LabeledRadio
                    name="BusinessManufactureOrWholesaleOwnBrand"
                    label="No, we exclusively sell products under a different brand or label."
                    checked={!props.application?.generalApplication?.manufactureOrWholesaleOwnBrand}
                    onChange={() =>
                      updateGeneralApplication((GeneralApplication) => ({
                        ...GeneralApplication,
                        manufactureOrWholesaleOwnBrand: false
                      }))
                    }
                  />
                </NColumnListContainer>
              </FormColumn>
            </FormRow>
          </>
        )}
        <FormRowHeader
          title="Business address"
          description="Enter the address where your business primarily conducts its operations."
        />
        <AddressInputForm
          showSecondLine
          singleSecondLine
          initialValue={{
            streetAddress: props.application.profile?.address?.line1,
            streetAddressLine2: props.application.profile?.address?.line2 || '',
            city: props.application.profile?.address?.city,
            state: props.application.profile?.address?.zone,
            zipCode: props.application.profile?.address?.postalCode
          }}
          validationError={{
            streetAddress:
              !inputChanged &&
              props.validationError?.field === 'AddressLine1' &&
              props.validationError?.message,
            city:
              !inputChanged &&
              props.validationError?.field === 'City' &&
              props.validationError?.message,
            state:
              !inputChanged &&
              props.validationError?.field === 'Zone' &&
              props.validationError?.message,
            zipCode:
              !inputChanged &&
              props.validationError?.field === 'PostalCode' &&
              props.validationError?.message
          }}
          onChange={onChangeAddress}
        />

        <FormRowHeader title="Business location details" />
        <FormRow breakMobile>
          <FormColumn
            title="Location type"
            description="Enter the type of property your business is operating on."
          >
            <Select
              defaultValue={
                props.application.locations?.length > 0
                  ? props.application.locations[0].locationType
                  : LocationType.Unknown
              }
              options={[
                {
                  value: LocationType.Unknown,
                  displayValue: 'Select a location...'
                },
                {
                  value: LocationType.Home,
                  displayValue: 'Home'
                },
                {
                  value: LocationType.Leased,
                  displayValue: 'Building (leased)'
                },
                {
                  value: LocationType.Owned,
                  displayValue: 'Building (owned)'
                }
              ]}
              error={
                !inputChanged &&
                props.validationError?.field === 'LocationType' &&
                props.validationError?.message
              }
              onChange={(value) => {
                updateBusinessApplication((businessApplication) => {
                  const existingAddress = props.application.locations[0];
                  return {
                    ...businessApplication,
                    locations: [{ ...existingAddress, locationType: value }]
                  };
                });
              }}
            />
          </FormColumn>
          <FormColumn
            title="Square footage"
            description="Enter the total square footage area of your business property."
          >
            <TextInput
              inputMode="numeric"
              value={props.application?.locations[0]?.squareFootage}
              error={
                !inputChanged &&
                props.validationError?.field === 'SquareFootage' &&
                props.validationError?.message
              }
              onChange={(e) => {
                const value = e.currentTarget.value;
                updateBusinessApplication((businessApplication) => {
                  const existingAddress = props.application.locations[0];
                  return {
                    ...businessApplication,
                    locations: [{ ...existingAddress, squareFootage: Number(value) }]
                  };
                });
              }}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <LabeledCheckbox
              label="Check this box if the property has sprinklers."
              checked={
                props.application.locations?.length > 0
                  ? !!props.application.locations[0].hasSprinklers
                  : false
              }
              onChange={() => {
                updateBusinessApplication((businessApplication) => {
                  const existingAddress = props.application.locations[0];
                  return {
                    ...businessApplication,
                    locations: [
                      { ...existingAddress, hasSprinklers: !existingAddress.hasSprinklers }
                    ]
                  };
                });
              }}
            />
          </FormColumn>
        </FormRow>
        <>
          <FormRowHeader title="Revenue and payroll" />
          {props.application.operations?.includes(OperationType.Retail) && (
            <FormRow breakMobile>
              <FormColumn
                title="Retail revenue"
                description="Enter the retail revenue or sales in the last 12 months."
              >
                <CurrencyInput
                  style={{ maxWidth: '300px' }}
                  value={props.application.retailUnderwriting?.revenue}
                  error={
                    !inputChanged &&
                    props.validationError?.field === 'RetailRevenue' &&
                    props.validationError?.message
                  }
                  onValueChange={(e) => {
                    const value = e.value;
                    updateBusinessApplication((BusinessApplication) => ({
                      ...BusinessApplication,
                      retailUnderwriting: {
                        revenue: Number(value)
                      }
                    }));
                  }}
                />
              </FormColumn>
            </FormRow>
          )}
          {props.application.operations?.includes(OperationType.Wholesale) && (
            <FormRow breakMobile>
              <FormColumn
                title="Wholesale revenue"
                description="Enter the wholesale revenue or sales in the last 12 months."
              >
                <CurrencyInput
                  style={{ maxWidth: '300px' }}
                  value={props.application.wholesaleUnderwriting?.revenue}
                  error={
                    !inputChanged &&
                    props.validationError?.field === 'WholesaleRevenue' &&
                    props.validationError?.message
                  }
                  onValueChange={(e) => {
                    const value = e.value;
                    updateBusinessApplication((BusinessApplication) => ({
                      ...BusinessApplication,
                      wholesaleUnderwriting: {
                        revenue: Number(value)
                      }
                    }));
                  }}
                />
              </FormColumn>
            </FormRow>
          )}
          {props.application.operations?.includes(OperationType.ManufacturingDesign) && (
            <FormRow breakMobile>
              <FormColumn
                title="Manufacturing revenue"
                description="Enter the manufacturing revenue or sales in the last 12 months."
              >
                <CurrencyInput
                  style={{ maxWidth: '300px' }}
                  value={props.application.manufacturingUnderwriting?.revenue}
                  error={
                    !inputChanged &&
                    props.validationError?.field === 'ManufacturingRevenue' &&
                    props.validationError?.message
                  }
                  onValueChange={(e) => {
                    const value = e.value;
                    updateBusinessApplication((BusinessApplication) => ({
                      ...BusinessApplication,
                      manufacturingUnderwriting: {
                        revenue: Number(value)
                      }
                    }));
                  }}
                />
              </FormColumn>
            </FormRow>
          )}
          {props.application.operations?.includes(OperationType.Service) && (
            <FormRow breakMobile>
              <FormColumn
                title="Service or repair revenue"
                description="Enter the service or repair revenue or sales in the last 12 months."
              >
                <CurrencyInput
                  style={{ maxWidth: '300px' }}
                  value={props.application.serviceUnderwriting?.revenue}
                  error={
                    !inputChanged &&
                    props.validationError?.field === 'ServiceOrRepairRevenue' &&
                    props.validationError?.message
                  }
                  onValueChange={(e) => {
                    const value = e.value;
                    updateBusinessApplication((BusinessApplication) => ({
                      ...BusinessApplication,
                      serviceUnderwriting: {
                        revenue: Number(value)
                      }
                    }));
                  }}
                />
              </FormColumn>
            </FormRow>
          )}
          {props.application.operations?.includes(OperationType.Rental) && (
            <>
              <FormRow breakMobile>
                <FormColumn
                  title="Rental revenue"
                  description="Enter the rental revenue or sales in the last 12 months."
                >
                  <CurrencyInput
                    style={{ maxWidth: '300px' }}
                    value={props.application.rentalUnderwriting?.revenue}
                    error={
                      !inputChanged &&
                      props.validationError?.field === 'RentalRevenue' &&
                      props.validationError?.message
                    }
                    onValueChange={(e) => {
                      const value = e.value;
                      updateBusinessApplication((BusinessApplication) => ({
                        ...BusinessApplication,
                        rentalUnderwriting: {
                          revenue: Number(value),
                          rentalPercentGuided:
                            BusinessApplication.rentalUnderwriting?.rentalPercentGuided || 0
                        }
                      }));
                    }}
                  />
                </FormColumn>
              </FormRow>
              <FormRow breakMobile>
                <FormColumn
                  title="Guided vs. non-guided rental revenue"
                  description="Of your rental revenue, enter the approximate percentage that comes from guided rentals. Enter 0 if none of your rentals are guided."
                >
                  <TextInput
                    style={{ maxWidth: '300px' }}
                    value={props.application.rentalUnderwriting?.rentalPercentGuided}
                    error={
                      !inputChanged &&
                      props.validationError?.field === 'RentalPercentGuided' &&
                      props.validationError?.message
                    }
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      updateBusinessApplication((BusinessApplication) => ({
                        ...BusinessApplication,
                        rentalUnderwriting: {
                          revenue: BusinessApplication.rentalUnderwriting?.revenue || 0,
                          rentalPercentGuided: Number(value)
                        }
                      }));
                    }}
                  />
                </FormColumn>
              </FormRow>
            </>
          )}
          {props.application.operations?.includes(OperationType.Other) && (
            <FormRow breakMobile>
              <FormColumn
                title="Other revenue"
                description="Enter other revenue in the last 12 months."
              >
                <CurrencyInput
                  style={{ maxWidth: '300px' }}
                  value={props.application.otherUnderwriting?.revenue}
                  error={
                    !inputChanged &&
                    props.validationError?.field === 'OtherRevenue' &&
                    props.validationError?.message
                  }
                  onValueChange={(e) => {
                    const value = e.value;
                    updateBusinessApplication((BusinessApplication) => ({
                      ...BusinessApplication,
                      otherUnderwriting: {
                        ...BusinessApplication.otherUnderwriting,
                        revenue: Number(value)
                      }
                    }));
                  }}
                />
              </FormColumn>
            </FormRow>
          )}
          <FormRow>
            <FormColumn
              title="Annual payroll"
              description="Enter the total amount payroll for W-2 employees, i.e. excluding owners, subcontractors, and 1099s in the last 12 months."
            >
              <CurrencyInput
                style={{ maxWidth: '300px' }}
                value={props.application.generalApplication?.annualPayroll}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'AnnualPayroll' &&
                  props.validationError?.message
                }
                onValueChange={(e) => {
                  const value = e.value;
                  updateGeneralApplication((GeneralApplication) => ({
                    ...GeneralApplication,
                    annualPayroll: Number(value)
                  }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn
              title="Number of employees"
              description="Enter the number of W-2 employees, i.e. excluding owners, subcontractors, and 1099s."
            >
              <TextInput
                style={{ maxWidth: '300px' }}
                inputMode="numeric"
                value={props.application.generalApplication?.numEmployees}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'NumberOfEmployees' &&
                  props.validationError?.message
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updateGeneralApplication((application) => ({
                    ...application,
                    numEmployees: Number(value)
                  }));
                }}
              />
            </FormColumn>
          </FormRow>
        </>
        <>
          <FormRowHeader
            title="Limits and deductibles"
            description="Please select your insurance limits and deductibles. This will help us find the best quote for your business."
          />
          <FormRow breakMobile>
            <FormColumn
              title="General liability limit"
              description="Please enter the amount of general liability you need."
            >
              <Select
                defaultValue={String(props.application.liabilityCoverage?.limit || 0)}
                options={[
                  {
                    value: '0',
                    displayValue: '$0'
                  },
                  {
                    value: '300000',
                    displayValue: '$300,000 per occ / $600,000 agg'
                  },
                  {
                    value: '500000',
                    displayValue: '$500,000 per occ / $1,000,000 agg'
                  },
                  {
                    value: '1000000',
                    displayValue: '$1,000,000 per occ / $2,000,000 agg'
                  },
                  {
                    value: '2000000',
                    displayValue: '$2,000,000 per occ / $4,000,000 agg'
                  }
                ]}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'GLLimit' &&
                  props.validationError?.message
                }
                onChange={(value) => {
                  updateGeneralLiabilityCoverage((coverage) => ({
                    ...coverage,
                    limit: Number(value)
                  }));
                }}
              />
            </FormColumn>
            <FormColumn
              title="General liability deductible"
              description="Please enter the amount of general liability deductible."
            >
              <Select
                defaultValue={String(props.application.liabilityCoverage?.deductible || 0)}
                options={[
                  {
                    value: '0',
                    displayValue: '$0'
                  },
                  {
                    value: '250',
                    displayValue: '$250'
                  },
                  {
                    value: '500',
                    displayValue: '$500'
                  },
                  {
                    value: '1000',
                    displayValue: '$1,000'
                  },
                  {
                    value: '2500',
                    displayValue: '$2,500'
                  }
                ]}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'GLDeductible' &&
                  props.validationError?.message
                }
                onChange={(value) => {
                  updateGeneralLiabilityCoverage((coverage) => ({
                    ...coverage,
                    deductible: Number(value)
                  }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow breakMobile>
            <FormColumn
              title="Business personal property limit"
              description="Please enter the amount of business personal property you need."
            >
              <CurrencyInput
                style={{ maxWidth: '300px' }}
                value={props.application.propertyCoverage?.bppLimit}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'BPPLimit' &&
                  props.validationError?.message
                }
                onValueChange={(e) => {
                  updatePropertyCoverage((coverage) => ({
                    ...coverage,
                    bppLimit: Number(e.value) || 0
                  }));
                }}
              />
            </FormColumn>
            <FormColumn
              title="Business personal property deductible"
              description="Please enter the amount of personal property deductible."
            >
              <Select
                defaultValue={String(props.application.propertyCoverage?.bppDeductible || 0)}
                options={[
                  {
                    value: '0',
                    displayValue: '$0'
                  },
                  {
                    value: '250',
                    displayValue: '$250'
                  },
                  {
                    value: '500',
                    displayValue: '$500'
                  },
                  {
                    value: '1000',
                    displayValue: '$1,000'
                  },
                  {
                    value: '2500',
                    displayValue: '$2,500'
                  }
                ]}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'BPPDeductible' &&
                  props.validationError?.message
                }
                onChange={(value) => {
                  updatePropertyCoverage((coverage) => ({
                    ...coverage,
                    bppDeductible: Number(value)
                  }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow breakMobile>
            <FormColumn
              title="Building limit"
              description="Please enter the amount of building coverage you need."
            >
              <CurrencyInput
                style={{ maxWidth: '300px' }}
                value={props.application.propertyCoverage?.buildingLimit}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'BuildingLimit' &&
                  props.validationError?.message
                }
                onValueChange={(e) => {
                  updatePropertyCoverage((coverage) => ({
                    ...coverage,
                    buildingLimit: Number(e.value) || 0
                  }));
                }}
              />
            </FormColumn>
            <FormColumn
              title="Building deductible"
              description="Please enter the amount of building deductible."
            >
              <Select
                defaultValue={String(props.application.propertyCoverage?.buildingDeductible) || '0'}
                options={[
                  {
                    value: '0',
                    displayValue: '$0'
                  },
                  {
                    value: '250',
                    displayValue: '$250'
                  },
                  {
                    value: '500',
                    displayValue: '$500'
                  },
                  {
                    value: '1000',
                    displayValue: '$1,000'
                  },
                  {
                    value: '2500',
                    displayValue: '$2,500'
                  }
                ]}
                error={
                  !inputChanged &&
                  props.validationError?.field === 'BuildingDeductible' &&
                  props.validationError?.message
                }
                onChange={(value) => {
                  updatePropertyCoverage((coverage) => ({
                    ...coverage,
                    buildingDeductible: Number(value)
                  }));
                }}
              />
            </FormColumn>
          </FormRow>
        </>
        <>
          <FormRowHeader
            title="Coverage start date"
            description="Please choose the date you want policy coverages to start."
          />
          <FormRow singleItem>
            <FormColumn>
              <DatePicker
                loading={props.loading}
                onDayClick={(date) => {
                  updateBusinessApplication((businessApplication) => ({
                    ...businessApplication,
                    desiredCoverageStartDate: date.toISOString()
                  }));
                }}
                selected={
                  !!props.application.desiredCoverageStartDate &&
                  new Date(props.application.desiredCoverageStartDate)
                }
                disabled={(day) => {
                  const today = new Date(new Date().setHours(0, 0, 0, 0));
                  const todayPlus90 = new Date(today.getTime() + 90 * 24 * 60 * 60 * 1000);
                  return (
                    day.getTime() < today.setDate(today.getDate()) ||
                    day.getTime() > todayPlus90.getTime()
                  );
                }}
              />
            </FormColumn>
          </FormRow>
        </>
        <ButtonContainer style={{ paddingTop: '20px' }} center>
          <Button
            primary
            loading={props.loading}
            icon={<IoArrowForward />}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
          >
            Continue
          </Button>
        </ButtonContainer>
      </FormContainer>
    </PageSection>
  );
};

const pages: Array<Page> = [
  {
    path: '/app/commercial/:id/business',
    render: (props) => (
      <GetApplicationWrapper applicationId={props.match.params.id}>
        {(application) => (
          <PageWrapper
            application={application}
            pages={pages}
            component={CommercialInsuranceGetInfoPage}
          />
        )}
      </GetApplicationWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      [
        'BusinessName',
        'Website',
        'Operations',
        'NumberOfEmployees',
        'ManufactureOrWholesaleOwnBrand',
        'AddressLine1',
        'City',
        'Zone',
        'PostalCode',
        'LocationType',
        'SquareFootage',
        'GLLimit',
        'BPPLimit',
        'BuildingLimit',
        'RetailRevenue',
        'WholesaleRevenue',
        'ManufacturingRevenue',
        'ServiceOrRepairRevenue',
        'RentalRevenue',
        'OtherRevenue',
        'RentalPercentGuided',
        'AnnualPayroll',
        'BPPDeductible',
        'BuildingDeductible',
        'BPPValue',
        'BusinessStartDate'
      ].includes(validationError.field),
    icon: <IoBusinessOutline />
  },
  {
    path: '/app/commercial/:id/upload',
    render: (props) => (
      <GetApplicationWrapper applicationId={props.match.params.id}>
        {(application) => (
          <PageWrapper
            application={application}
            pages={pages}
            component={CommercialInsuranceUploadPage}
          />
        )}
      </GetApplicationWrapper>
    ),
    hasError: (validationError: ValidationError) => ['FileIDs'].includes(validationError.field),
    icon: <IoCloudUploadOutline />
  },
  {
    path: '/app/commercial/:id/coverage',
    render: (props) => (
      <GetApplicationWrapper applicationId={props.match.params.id}>
        {(application) => (
          <PageWrapper
            application={application}
            pages={pages}
            component={CommercialInsuranceCoverageSummaryPage}
          />
        )}
      </GetApplicationWrapper>
    ),
    hasError: () => false,
    icon: <IoDocumentTextOutline />
  },
  {
    path: '/app/commercial/:id/complete',
    render: (props) => (
      <GetApplicationWrapper applicationId={props.match.params.id}>
        {(application) => (
          <PageWrapper
            application={application}
            pages={pages}
            component={CommercialInsuranceCompletePage}
          />
        )}
      </GetApplicationWrapper>
    ),
    hasError: () => false,
    icon: <IoDocumentTextOutline />
  }
];

const getPages = (showOauth: boolean): Page[] => {
  // if (showDocumentExtraction) {
  //   pages.splice(1, 0, {
  //     path: '/app/commercial/:id/upload',
  //     render: (props) => (
  //       <GetApplicationWrapper applicationId={props.match.params.id}>
  //         {(application) => (
  //           <PageWrapper
  //             application={application}
  //             pages={pages}
  //             component={CommercialInsuranceUploadPage}
  //           />
  //         )}
  //       </GetApplicationWrapper>
  //     ),
  //     hasError: (validationError: ValidationError) => ['FileIDs'].includes(validationError.Field),
  //     icon: <IoCloudUploadOutline />
  //   });
  // }
  if (showOauth) {
    pages.splice(1, 0, {
      path: '/app/commercial/:id/connect',
      render: (props) => (
        <GetApplicationWrapper applicationId={props.match.params.id}>
          {(application) => (
            <PageWrapper
              application={application}
              pages={pages}
              component={CommercialInsuranceConnectPage}
            />
          )}
        </GetApplicationWrapper>
      ),
      hasError: (validationError: ValidationError) =>
        ['Integrations'].includes(validationError.field),
      icon: <IoLinkOutline />
    });
  }

  return pages;
};

export default (): JSX.Element => {
  const [commercialPages, setCommercialPages] = React.useState(pages);
  const [loading, setLoading] = React.useState(true);

  const getCommercialPages = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        // const showOauth = await enableCommercialOauth();
        // const showDocumentExtraction = await enableCommercialDocumentExtraction();
        setCommercialPages(getPages(false));
      } catch (err) {
        // ignore errors here
      } finally {
        setLoading(false);
      }
    }, 3000);
  };

  React.useEffect(() => {
    getCommercialPages();
  }, []);

  // Side effect to trigger re-renders when the page changes
  useRouteMatch();
  const history = useHistory();

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  return (
    <PageContainer
      width={commercialPages[current]?.path === '/app/commercial/:id/coverage' ? 900 : 700}
    >
      <PageSection centered>
        <OysterLogo scale={1.5} inline />
      </PageSection>
      {loading && (
        <PageSection noBorder centered>
          <Spinner color="#333333" />
        </PageSection>
      )}
      {!loading && (
        <ErrorBoundary forceMobile>
          {current >= 0 && (
            <PageSection noBorder noPadding>
              <Progress
                steps={commercialPages.map((page, i) =>
                  i < current ? (
                    <LinkComponent
                      href={(() => {
                        const match = /\/(bi_app_.+)\//.exec(history.location.pathname);
                        if (match && match[1]) {
                          return commercialPages[i].path.replace(':id', match[1]);
                        }
                        return commercialPages[i].path;
                      })()}
                    >
                      {page.icon}
                    </LinkComponent>
                  ) : (
                    page.icon
                  )
                )}
                currentStep={current}
              />
            </PageSection>
          )}
          <Switch>
            <Route
              exact
              path={`/app/commercial/ineligible`}
              render={() => (
                <PageSection>
                  <h1>Sorry!</h1>
                  <p>
                    {JSON.parse(window.localStorage.getItem('underwriting_error') || '{}')
                      .Message || null}
                  </p>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                  </div>
                </PageSection>
              )}
            ></Route>
            {commercialPages.map((page) => (
              <Route exact key={page.path} path={page.path} render={page.render} />
            ))}
            <Redirect to={`/commercial-insurance`} />
          </Switch>
        </ErrorBoundary>
      )}
    </PageContainer>
  );
};
