import { IoOpenOutline } from 'react-icons/io5';
import { Banner } from '@oysterjs/ui/Banner';
import { ButtonLink } from '@oysterjs/ui/Button';
import { ErrorDetail, ErrorDetails } from '@oysterjs/ui/ErrorBoundary';
import { Switch } from '@oysterjs/ui/Form/switch';
import { VerticalLayout } from '@oysterjs/ui/VerticalLayout';
import {
  MerchantIntegration,
  MerchantIntegrationStatus,
  ShopifyIntegrationSettings
} from '@oysterjs/types';
import { AdditionalSetup, ChannelSetting, ChannelSettingsWrapper, Step } from './settings';

const ShopifyPreLinkSetup = () => {
  return (
    <>
      <p>
        To start offering insurance for your products in your Shopify store, you need to install the{' '}
        <a href="https://apps.shopify.com/oyster" target="_blank">
          Oyster app
        </a>{' '}
        and link your store with your Oyster account.
      </p>
      <div style={{ padding: '20px 0px 0px 0px' }}>
        <VerticalLayout invertedIcon>
          <Step title="Login to your store and install the app">
            Ensure that you are logged into the Shopify store you'd like to install the Oyster app
            in. Then,{' '}
            <a href="https://apps.shopify.com/oyster" target="_blank">
              visit the Oyster app listing page
            </a>{' '}
            and click "Add app" to install the Oyster app.
          </Step>
          <Step title="Grant access to Oyster">
            Oyster will ask for specific permissions to read product and order data. We will use
            this information to determine the insurance eligibility of products and customers and
            send post-purchase follow-up emails to customers who opted to insure their purchase at
            the point-of-sale.
          </Step>
          <Step title="Finish setting up your integration" last>
            Configure the integration to display Oyster in various parts of the customer journey,
            from the product description page, to the cart page, and even after checkout.
          </Step>
        </VerticalLayout>
      </div>
    </>
  );
};

const ShopifyPostLinkSetup = (props: {
  apiKey: string;
  integration: MerchantIntegration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  return (
    <>
      {props.integration.Status === MerchantIntegrationStatus.inactive && (
        <Banner
          style={{ margin: '0px 0px 16px 0px' }}
          title="This integration doesn't seem live"
          description="Please complete the setup instructions to go live with this integration."
          bannerAction={
            <ButtonLink
              target="_blank"
              href="https://withoyster.notion.site/Shopify-Integration-1154867162484dcd898d810ae6f0a393"
              leftIcon={<IoOpenOutline />}
              primary
            >
              Continue Setup
            </ButtonLink>
          }
        />
      )}
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <ErrorDetails errorData={props.apiKey} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="API Key" description={props.apiKey} />
          </ErrorDetails>
          <ErrorDetails errorData={props.integration.ID} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="Integration ID" description={props.integration.ID} />
          </ErrorDetails>
        </div>
        <ChannelSettingsWrapper
          integration={props.integration}
          onUpdateIntegration={props.onUpdateIntegration}
        >
          {(settings: ShopifyIntegrationSettings, loading, onUpdateSettings) => (
            <>
              <ChannelSetting
                title="Enable Product Page Widget"
                description="Configures whether the Oyster widget is visible on the product description page. This requires you to have added the Oyster widget to your Shopify theme."
                rightContent={
                  <Switch
                    enabled={settings.ProductPageWidgetEnabled}
                    loading={loading}
                    onChange={() =>
                      onUpdateSettings({
                        ...settings,
                        ProductPageWidgetEnabled: !settings.ProductPageWidgetEnabled
                      })
                    }
                  />
                }
              />
              <ChannelSetting
                title="Enable Add-to-Cart Trigger"
                description="Configures whether an Oyster offer modal should show when a customer clicks the 'Add to Cart' button."
                beta
                rightContent={
                  <Switch
                    enabled={settings.ProductPageAddToCartTriggerEnabled}
                    loading={loading}
                    onChange={() =>
                      onUpdateSettings({
                        ...settings,
                        ProductPageAddToCartTriggerEnabled:
                          !settings.ProductPageAddToCartTriggerEnabled
                      })
                    }
                  />
                }
              />
              <ChannelSetting
                title="Enable Cart Widget"
                description="Configures whether the Oyster widget should appear on the cart page for each eligible item in the customer's cart."
                beta
                rightContent={
                  <Switch
                    enabled={settings.CartPageWidgetEnabled}
                    loading={loading}
                    onChange={() =>
                      onUpdateSettings({
                        ...settings,
                        CartPageWidgetEnabled: !settings.CartPageWidgetEnabled
                      })
                    }
                  />
                }
              />
              <ChannelSetting
                title="Enable Post-Purchase Offer"
                description="Configures whether an Oyster offer should appear on the order confirmation page after a customer checks out with an eligible product."
                beta
                rightContent={
                  <Switch
                    enabled={settings.OrderConfirmationPageWidgetEnabled}
                    loading={loading}
                    onChange={() =>
                      onUpdateSettings({
                        ...settings,
                        OrderConfirmationPageWidgetEnabled:
                          !settings.OrderConfirmationPageWidgetEnabled
                      })
                    }
                  />
                }
                footer={
                  !settings.OrderConfirmationPageWidgetEnabled ||
                  new Date(settings.OrderConfirmationPageWidgetFirstLoad).valueOf() >
                    0 ? undefined : (
                    <AdditionalSetup setupUrl="https://withoyster.notion.site/Shopify-Integration-1154867162484dcd898d810ae6f0a393#4056c3a0a55844d6927c25ae09ed058c" />
                  )
                }
              />
            </>
          )}
        </ChannelSettingsWrapper>
      </div>
    </>
  );
};

export const ShopifySetup = (props: {
  apiKey: string;
  integration?: MerchantIntegration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) =>
  !props.integration?.Status || props.integration?.Status === MerchantIntegrationStatus.pending ? (
    <ShopifyPreLinkSetup />
  ) : (
    <ShopifyPostLinkSetup
      apiKey={props.apiKey}
      integration={props.integration}
      onUpdateIntegration={props.onUpdateIntegration}
    />
  );
