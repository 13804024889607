import styled from 'styled-components';

import { Waypoint } from './waypoint';

import {
  InStoreSalesChannelType,
  Merchant,
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantIntegrationType,
  MerchantRentalConfiguration,
  OnlineSalesChannelType,
  PolicyTableValues,
  ProductType,
  RentalPolicyState,
  RentalWaiver,
  WaiverEntry,
  WaiverState
} from '@oysterjs/types';
import { Badge } from '@oysterjs/ui/Badge';
import {
  IoShieldCheckmarkOutline,
  IoCheckmarkSharp,
  IoShieldOutline,
  IoTimerOutline
} from 'react-icons/io5';
import { ButtonContainer, ButtonLink } from '@oysterjs/ui/Button';
import { Statistic, StatisticType } from '@oysterjs/ui/Text/statistic';
import { BusinessPolicy } from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { getDisplayInsuranceType } from '@oysterjs/types/merchant/graphql/map';

const ProductStatusCardContainer = styled.div<{ inactive?: boolean }>`
  border-radius: 12px;
  border: 1px solid #e2e2e2;
  background: ${(props) => (props.inactive ? '#fcfcfc' : 'transparent')};
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`;

const ProductStatusCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-weight: 400;
    color: #000000;
  }
`;

const ProductStatusCardContent = styled.div`
  p {
    margin: 2px 0px 8px 0px;
    font-size: 0.9em;
    color: #666666;
  }
`;

const ProductStatusCardEmptyContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    margin: 4px 0px 4px 0px;
    font-size: 0.9em;
    color: #666666;
  }
`;

const ListContainer = styled.ul`
  margin: 0px;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ListItemContainer = styled.li`
  color: #666666;
  font-size: 0.9em;
  list-style: none;
  display: flex;
  gap: 0px 8px;
`;

const ListItem = (props: { item: string }) => (
  <ListItemContainer>
    <div>
      <IoCheckmarkSharp style={{ color: '#0EA5E9' }} />
    </div>
    <div>{props.item}</div>
  </ListItemContainer>
);

const NColumnListContainer = styled.div<{ columns: number }>`
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  & > * {
    flex: 1 0
      calc(
        ${(props) => Math.floor(100 / props.columns)}% -
          ${(props) => Math.ceil(8 / props.columns)}px
      );
  }
`;

const TwoPaneContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LeftPane = styled.div`
  flex: 1 0 0;
`;

const RightPane = styled.div`
  flex: 0 0 320px;
`;

interface HomePageProps {
  merchant: Merchant;
  businessPolicies: BusinessPolicy[];
  policyReferrals?: PolicyTableValues[];
  integrations: MerchantIntegration[];
  rentalConfiguration?: MerchantRentalConfiguration;
  rentalWaivers: WaiverEntry[];
}

const BusinessInsuranceNotEnabledCard = (props: { merchant: Merchant }) => (
  <ProductStatusCardContainer inactive>
    <ProductStatusCardHeader>
      <h2>Your business</h2>
      <Badge color="#e6e6e6" label="Not Protected" icon={<IoShieldOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardEmptyContent>
      <div>
        <p>{props.merchant.BusinessProfile.Name} may not be fully protected.</p>
        <p>Oyster can save you up to 30% on business insurance.</p>
        <p>Complete your application now to get a free quote.</p>
      </div>
      <ButtonContainer>
        <ButtonLink primary href="/business-insurance">
          Get a free quote
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardEmptyContent>
  </ProductStatusCardContainer>
);

const BusinessInsurancePendingCard = (props: { merchant: Merchant }) => (
  <ProductStatusCardContainer inactive>
    <ProductStatusCardHeader>
      <h2>Your business</h2>
      <Badge color="#ffa72494" label="Pending" icon={<IoTimerOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardEmptyContent>
      {!props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted && (
        <div>
          <p>{props.merchant.BusinessProfile.Name} may not be fully protected.</p>
          <p>Oyster can save you up to 30% on business insurance.</p>
          <p>Complete your application now to get a free quote.</p>
        </div>
      )}
      {props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted && (
        <div>
          <p>
            Thank you for submitting your information! Your insurance application is being reviewed.
            Please feel free to schedule a time to talk to one our of experts in the meantime.
          </p>
        </div>
      )}
      <ButtonContainer>
        {!props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted && (
          <ButtonLink primary href="/business-insurance">
            Complete application
          </ButtonLink>
        )}
        {props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted && (
          <ButtonLink
            primary
            target="_blank"
            href="https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation"
          >
            Schedule a meeting
          </ButtonLink>
        )}
      </ButtonContainer>
    </ProductStatusCardEmptyContent>
  </ProductStatusCardContainer>
);

const BusinessInsurancePoliciesCard = (props: { merchant: Merchant; policies: string[] }) => (
  <ProductStatusCardContainer>
    <ProductStatusCardHeader>
      <h2>Your business</h2>
      <Badge color="#baf7bb" label="Protected" icon={<IoShieldCheckmarkOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardContent>
      <p>{props.merchant.BusinessProfile.Name} is protected with Oyster.</p>
      <ListContainer>
        {props.policies.map((p) => (
          <ListItem key={p} item={p} />
        ))}
      </ListContainer>
      <ButtonContainer style={{ paddingTop: '8px' }}>
        <ButtonLink primary href="/business-insurance">
          Manage your policies
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardContent>
  </ProductStatusCardContainer>
);

const ProductInsuranceNotEnabledCard = () => (
  <ProductStatusCardContainer inactive>
    <ProductStatusCardHeader>
      <h2>Your customers</h2>
      <Badge color="#e6e6e6" label="Not Protected" icon={<IoShieldOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardEmptyContent>
      <div>
        <p>Your customers may be at risk of theft, damage, and loss.</p>
        <p>Oyster can help protect them while generating additional revenue for you.</p>
        <p>Get started now to start protecting your customers.</p>
      </div>
      <ButtonContainer>
        <ButtonLink primary href="/product-insurance">
          Get started
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardEmptyContent>
  </ProductStatusCardContainer>
);

const ProductInsurancePendingCard = () => (
  <ProductStatusCardContainer inactive>
    <ProductStatusCardHeader>
      <h2>Your customers</h2>
      <Badge color="#ffa72494" label="Pending" icon={<IoTimerOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardEmptyContent>
      <div>
        <p>Your customers may be at risk of theft, damage, and loss.</p>
        <p>Finish setting up your sales channels to offer peace of mind to all your customers.</p>
      </div>
      <ButtonContainer>
        <ButtonLink primary href="/product-insurance">
          Complete setup
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardEmptyContent>
  </ProductStatusCardContainer>
);

const ProductInsuranceOverviewCard = (props: {
  merchant: Merchant;
  referrals: PolicyTableValues[];
}) => (
  <ProductStatusCardContainer>
    <ProductStatusCardHeader>
      <h2>Your customers</h2>
      <Badge color="#baf7bb" label="Protected" icon={<IoShieldCheckmarkOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardContent>
      <p>{props.merchant.BusinessProfile.Name} is offering insurance to its customers.</p>
      <NColumnListContainer columns={3}>
        <Statistic value={props.referrals.length} caption="Insurance applications this month" />
        <Statistic
          value={25 * props.referrals.length}
          caption="Referral revenue earned this month"
          type={StatisticType.currency}
        />
        <Statistic
          value={props.referrals
            .flatMap((r) => r.InsuredItems)
            .reduce((prev, curr) => prev + curr.Price.Amount, 0)}
          caption="Total value protected this month."
          type={StatisticType.currency}
        />
      </NColumnListContainer>
      <ButtonContainer style={{ paddingTop: '8px' }}>
        <ButtonLink primary href="/product-insurance">
          View more details
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardContent>
  </ProductStatusCardContainer>
);

const RentalInsuranceNotEnabledCard = () => (
  <ProductStatusCardContainer inactive>
    <ProductStatusCardHeader>
      <h2>Your rentals</h2>
      <Badge color="#e6e6e6" label="Not Protected" icon={<IoShieldOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardEmptyContent>
      <div>
        <p>Your rental assets may be at risk of theft, damage, and loss.</p>
        <p>Oyster can help protect them while offer peace-of-mind to your renters.</p>
        <p>Get started now to start protecting your rentals.</p>
      </div>
      <ButtonContainer>
        <ButtonLink primary href="/apps/rental">
          Get started
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardEmptyContent>
  </ProductStatusCardContainer>
);

const RentalInsurancePendingCard = () => (
  <ProductStatusCardContainer inactive>
    <ProductStatusCardHeader>
      <h2>Your rentals</h2>
      <Badge color="#ffa72494" label="Pending" icon={<IoTimerOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardEmptyContent>
      <div>
        <p>Your rental assets may be at risk of theft, damage, and loss.</p>
        <p>Finish setting up your rental insurance to make sure your assets stay protected.</p>
      </div>
      <ButtonContainer>
        <ButtonLink primary href="/apps/rental">
          Complete setup
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardEmptyContent>
  </ProductStatusCardContainer>
);

const RentalInsuranceOverviewCard = (props: { merchant: Merchant; waivers: RentalWaiver[] }) => (
  <ProductStatusCardContainer>
    <ProductStatusCardHeader>
      <h2>Your rentals</h2>
      <Badge color="#baf7bb" label="Protected" icon={<IoShieldCheckmarkOutline />} />
    </ProductStatusCardHeader>
    <ProductStatusCardContent>
      <p>{props.merchant.BusinessProfile.Name} is protecting its rental assets.</p>
      <NColumnListContainer columns={3}>
        <Statistic value={props.waivers.length} caption="Rentals protected" />
        <Statistic
          value={props.waivers.reduce((prev, curr) => prev + curr.Details.Premium.Total, 0)}
          caption="Total waiver premium collected"
          type={StatisticType.currency}
        />
        <Statistic
          value={props.waivers
            .flatMap((w) => w.Details.Assets)
            .reduce((prev, curr) => prev + (curr.Asset?.Value || 0), 0)}
          caption="Total value protected"
          type={StatisticType.currency}
        />
      </NColumnListContainer>
      <ButtonContainer style={{ paddingTop: '8px' }}>
        <ButtonLink primary href="/rental">
          View rental insurance
        </ButtonLink>
      </ButtonContainer>
    </ProductStatusCardContent>
  </ProductStatusCardContainer>
);

export const HomePage = (props: HomePageProps) => {
  const hasUnresolvedIntegrations =
    (props.merchant.BusinessProfile.Personalization.OnlineSalesChannelType ===
      OnlineSalesChannelType.Shopify &&
      props.integrations.find((i) => i.Type === MerchantIntegrationType.shopify)?.Status !==
        MerchantIntegrationStatus.active) ||
    (props.merchant.BusinessProfile.Personalization.OnlineSalesChannelType ===
      OnlineSalesChannelType.WooCommerce &&
      props.integrations.find((i) => i.Type === MerchantIntegrationType.woocommerce)?.Status !==
        MerchantIntegrationStatus.active) ||
    (props.merchant.BusinessProfile.Personalization.InStoreSalesChannelType ===
      InStoreSalesChannelType.LightspeedRSeries &&
      props.integrations.find((i) => i.Type === MerchantIntegrationType.lsretail_rseries)
        ?.Status !== MerchantIntegrationStatus.active);

  const merchantEligibleForRentalInsurance =
    props.merchant.BusinessProfile.ProductVerticals?.includes(ProductType.bike);

  const allPolicies = props.businessPolicies.map((p) => getDisplayInsuranceType(p.type));

  return (
    <TwoPaneContainer>
      <LeftPane>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '1.25em' }}>
          {/* Business insurance policies are available */}
          {!!allPolicies.length && (
            <BusinessInsurancePoliciesCard merchant={props.merchant} policies={allPolicies} />
          )}

          {/* Product insurance is active */}
          {props.merchant.BusinessProfile.Personalization.ProductInsuranceEnabled &&
            !hasUnresolvedIntegrations && (
              <ProductInsuranceOverviewCard
                merchant={props.merchant}
                referrals={props.policyReferrals || []}
              />
            )}

          {/* Rental insurance is active */}
          {props.rentalConfiguration?.Details.State === RentalPolicyState.approved && (
            <RentalInsuranceOverviewCard
              merchant={props.merchant}
              waivers={props.rentalWaivers
                .map((w) => w.Waiver)
                .filter(
                  (w): w is RentalWaiver =>
                    !!w && [WaiverState.active, WaiverState.expired].includes(w.State)
                )}
            />
          )}

          {/* The merchant indicated business insurance, but has no policies yet */}
          {!allPolicies.length &&
            (props.merchant.BusinessProfile.Personalization.BusinessInsuranceEnabled ||
              props.merchant.BusinessProfile.Personalization
                .BusinessInsuranceFilloutFormSubmitted) && (
              <BusinessInsurancePendingCard merchant={props.merchant} />
            )}

          {/* The merchant has some inactive product insurance integrations */}
          {props.merchant.BusinessProfile.Personalization.ProductInsuranceEnabled &&
            hasUnresolvedIntegrations && <ProductInsurancePendingCard />}

          {/* The merchant either indicated interest or has started the rental insurance app */}
          {((props.merchant.BusinessProfile.Personalization.RentalInsuranceEnabled &&
            !props.rentalConfiguration) ||
            props.rentalConfiguration?.Details.State === RentalPolicyState.submitted ||
            props.rentalConfiguration?.Details.State === RentalPolicyState.registered) && (
            <RentalInsurancePendingCard />
          )}

          {/* The merchant has no business insurance policies and did not indicate on their app */}
          {!allPolicies.length &&
            !props.merchant.BusinessProfile.Personalization.BusinessInsuranceEnabled &&
            !props.merchant.BusinessProfile.Personalization
              .BusinessInsuranceFilloutFormSubmitted && (
              <BusinessInsuranceNotEnabledCard merchant={props.merchant} />
            )}

          {/* The merchant has not enabled product insurance */}
          {!props.merchant.BusinessProfile.Personalization.ProductInsuranceEnabled && (
            <ProductInsuranceNotEnabledCard />
          )}

          {/* The merchant has not enabled rental insurance */}
          {merchantEligibleForRentalInsurance &&
            ((!props.merchant.BusinessProfile.Personalization.RentalInsuranceEnabled &&
              !props.rentalConfiguration) ||
              props.rentalConfiguration?.Details.State === RentalPolicyState.unknown) && (
              <RentalInsuranceNotEnabledCard />
            )}
        </div>
      </LeftPane>
      <RightPane>
        <Waypoint {...props} policies={allPolicies} />
      </RightPane>
    </TwoPaneContainer>
  );
};
