import React from 'react';
import { requestEmbeddablePage } from '@oysterjs/core/api/merchant';
import { Button } from '@oysterjs/ui/Button';
import { ErrorDetail, ErrorDetails } from '@oysterjs/ui/ErrorBoundary';
import { MerchantIntegration } from '@oysterjs/types';

export const EmbeddablePageSetup = (props: {
  apiKey: string;
  integration?: MerchantIntegration;
}) => {
  const [integration, setIntegration] = React.useState(props.integration);
  const [loading, setLoading] = React.useState(false);

  const onSetup = async () => {
    if (integration) {
      return;
    }

    setLoading(true);
    const empIntegration = await requestEmbeddablePage();
    setIntegration(empIntegration);
    setLoading(false);
  };

  const div = `<div id='oyster-container'></div>`;
  const script = `<script
  type='text/javascript'
  src='https://js.withoyster.com'
  defer='defer'
></script>
<script type='text/javascript' defer='defer'>
  window.addEventListener('load', function () {
    window.oyster.init({
      apiKey: '${props.apiKey}',
      integrationID: '${integration?.ID || ''}'
    });

    window.oyster.custom.marketing(
      document.getElementById('oyster-container')
    );
  });
</script>`;
  return (
    <>
      {!integration && (
        <div>
          <p>
            In just a few minutes, add a fully-managed page to your website that offers Oyster
            insurance to your customers. You'll receive credit for any referrals that originate from
            this page.
          </p>
          <Button loading={loading} primary onClick={onSetup} style={{ marginBottom: '30px' }}>
            Setup Oyster Page
          </Button>
          <div
            style={{
              margin: '20px 0px',
              padding: '20px',
              border: '2px solid #f2f2f2',
              borderRadius: '8px'
            }}
          >
            <img style={{ width: '100%' }} src="/images/oyster_emp.png" />
          </div>
        </div>
      )}
      {integration && (
        <>
          <p>
            First, create an empty page dedicated to Oyster in your store. On this page, add an HTML
            element to house the Oyster information.
          </p>
          <ErrorDetails errorData={div} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="Html" description={div} />
          </ErrorDetails>
          <p>Then, paste this script.</p>
          <ErrorDetails errorData={script} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="Javascript" description={script} />
          </ErrorDetails>
          <p>You're all set! The script takes care of everything else.</p>
        </>
      )}
    </>
  );
};
